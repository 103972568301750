import { Avatar, Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CartDrawer from "../../../cart/CartDrawer";
import { ApplicationState } from "../../../../store/types";
import theme from "../../../../components/theme/theme";



function StoreNav (props: any) {

    const { store } = useParams();
    const {cart} = useSelector((state: ApplicationState) => state.shop);

    const navigate = useNavigate();

    const handleNavigate = (url: string) => {
        navigate(url);
    }


    return(
        <Grid container spacing={3} mb={3}>
            <Grid item md={2} sm={3} xs={6}>
                <Card onClick={() => handleNavigate(`/ova-store/store/equipments`)} sx={{cursor: 'pointer', border: store === "equipments" ? `2px dotted ${theme.palette.primary.main}` : "none"}}>
                    <Box sx={{display: "flex", flexDirection: "column", padding: '10px'}}>
                        <img width={70} height={70} src="/assets/img/methods/computer-svgrepo-com.svg" alt="" style={{margin: "auto"}} />
                        <Typography color={store === "equipments" ? "primary" : "secondary"}>Equipments</Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item md={2} sm={3} xs={6}>
                <Card onClick={() => handleNavigate(`/ova-store/store/licenses`)} sx={{cursor: 'pointer', border: store === "licenses" ? `2px dotted ${theme.palette.primary.main}` : "none"}}>
                    <Box sx={{display: "flex", flexDirection: "column", padding: '10px'}}>
                        <img width={70} height={70} src="/assets/img/methods/key-svgrepo-com.svg" alt="" style={{margin: "auto"}} />
                        <Typography color={store === "licenses" ? "primary" : "secondary"}>Licenses</Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item md={2} sm={3} xs={12} sx={{marginLeft: 'auto'}}>
                <Box sx={{display: "flex", flexDirection: "column", padding: '10px', height: "100%"}}>
                    <CartDrawer isCart={true}/>
                </Box>
            </Grid>
        </Grid>
    )
}


export default StoreNav