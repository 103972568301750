import { combineReducers, Reducer } from 'redux';
import InternationalisationReducer from './reducers/InternationalisationReducers';
import { ApplicationState } from './types';
import RecruitmentsReducer from './reducers/recruitments';
import ArtiiclesReducer from './reducers/storeArticles';

const rootReducer: Reducer<ApplicationState> = combineReducers<
  ApplicationState
>({
  i18n: InternationalisationReducer,
  recruitments: RecruitmentsReducer,
  shop: ArtiiclesReducer
});

export default rootReducer;
