import AppRoutes from "../routes/Routes";
import Header from "../../components/headers/header/Header";
import Footer from "../footer/Footer";




function AppLayout () {


    return(
        <>
            <div className="app-layout">
                <Header />
                <div className="content">
                    <AppRoutes />
                </div>
                <Footer />
            </div>
        </>
    );
}


export default AppLayout;