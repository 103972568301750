// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';


// @ts-ignore
import hostingImg from '../../assets/img/hosting-image.png';
// @ts-ignore
import storeImg from '../../assets/img/store-bg.png';



export default function () {


    return (
        <>
            {/* <!--Main Slider Start--> */}
            <section className="main-slider clearfix">

            <div className="swiper-container thm-swiper__slider">
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    className={"thm-swiper__slider"}
                    autoplay ={{
                        "delay": 5000
                        }}
                    loop = {true}
                    effect = {"fade"}
                    pagination = {{
                        el: "#main-slider-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation= {{
                        nextEl: "#main-slider__swiper-button-next",
                        prevEl: "#main-slider__swiper-button-prev"
                    }}
                >
                    <SwiperSlide>
                        <div className="image-layer"
                            style={{backgroundImage: 'url(/assets/img/hosting-image.png)'}}></div>
                        {/* <!-- /.image-layer --> */}

                        <div className="main-slider-shape-1 float-bob-x">
                        
                            {/* <img src={hostingImg} alt=""/> */}
                            <img src="assets/images/shapes/main-slider-shape-1.png" alt=""/>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="main-slider__content">
                                        <h2 className="main-slider__title">Fully Managed Cloud & <br/> <span>Web Hosting</span>
                                            
                                        </h2>
                                        <p className="main-slider__text">Deidcated resources, full root access, & easy scaling. <br/> It’s the virtual private server you’ve been cravin</p>
                                        <div className="main-slider__btn-box">
                                            <Link to="/ova-store/hosting" className="thm-btn main-slider__btn">Let’s Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="image-layer"
                            style={{backgroundImage: 'url(/assets/img/store-bg.png)'}}></div>
                        {/* <!-- /.image-layer --> */}

                        <div className="main-slider-shape-1 float-bob-x">
                            <img src={"assets/images/shapes/main-slider-shape-1.png"} alt=""/>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="main-slider__content">
                                        <h2 className="main-slider__title">Equipments & Licenses <br/>
                                            <span>life.</span></h2>
                                        <p className="main-slider__text">Phasellus condimentum laoreet turpis, ut tincid
                                            sodales <br/> in. Integer leo arcu, mollis sit amet tempor.</p>
                                        <div className="main-slider__btn-box">
                                            <Link to="/ova-store/store/equipments" className="thm-btn main-slider__btn">Let’s Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                        {/* <!-- If we need navigation buttons --> */}
                        <div className="main-slider__nav">
                            <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                                <i className="icon-right-arrow"></i>
                            </div>
                            <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                                <i className="icon-right-arrow1"></i>
                            </div>
                        </div>
                    </Swiper>                        

                </div>
            </section>
            {/* <!--Main Slider End--> */}
        </>
    )
}