
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SecureLS from 'secure-ls';
import { setTranslationFunction } from '../../store/actions';

var localStorage = new SecureLS({ encodingType: 'aes' });


export default function (props: any) {

    const { children } = props;

    const { i18n } = useTranslation();
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        // //console.log("default language", localStorage.get('language'))
      i18n.changeLanguage(localStorage.get('language') || 'fr');
    

    dispatch(setTranslationFunction(t));
    
    }, []);


    return children
}