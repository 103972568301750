import About from "./components/about/About";
import FreeQuote from "./components/freeQuote/FreeQuote";
import OurServices from "./components/our-services/OurServices";
import Sliders from "./components/sliders/Sliders";
import SuccessRate from "./components/successRate/SuccessRate";
import Testimonials from "./components/testimonials/Testimonials";
import WhyChoose from "./components/whyChoose/WhyChoose";




export default function Homepage () {


    return(
        <main>
            {/* <!-- /.stricky-header --> */}
            <Sliders />
            {/* We provide the best insurance policy */}
            <About/>
            {/* Our services */}
            <OurServices />
            {/* Why choose */}
            <WhyChoose />
            {/* Free quote */}
            <FreeQuote/>
            {/* Success rate */}
            <SuccessRate />
            {/* Testimonials */}
            <Testimonials />
        </main>
    );
}