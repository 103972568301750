import axios from 'axios';

import SecureLS from 'secure-ls';

var localStorage = new SecureLS({ encodingType: 'aes' });


const curr_lang = localStorage.get('language');

export const RECRUITMENTAPI = axios.create({
    baseURL: 'https://recrutement.ova-consulting.fr/',
    // timeout: 10000,
    headers: {
        "Accept-Language": curr_lang ? `${curr_lang}-${curr_lang.toUpperCase()}` : "fr-Fr"
    }
});
