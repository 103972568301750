const en = {
    translation: {
        'locale_french': 'French',
        // Header translations
        'header_close': "Close",
        'header_our_expertise': "Our expertise",
        'header_digitalize_you': "Digitize you",
        'header_our_reference': "Our references",
        'header_recruitment': "Recruitment",

        // footer translation

        'footer_home': "Home",
        'footer_term_condition': "Terms and conditions",
        'footer_privacy_policy': "Privacy policy",

        // Home page translations
        "home_agency": "Agency",
        "home_our_services": "Our services",
        "home_our_method": "Our method",
        "home_our_methods": "Our methods",
        "home_our_clients": "Our clients",
        "swiper_slide_title_1": "Beyond the limits of digital",
        "swiper_slide_text_1": "A team of highly qualified employees working to digitize your core business.",
        "home_button_try_free": "Discover",
        "swiper_slide_title_2": "Digital workplace",
        "swiper_slide_text_2": "The change in the work environment imposed by the health crisis has steered companies into the digital age in order to revitalize work and better adapt to the new demands of digitalization of processes.",
        "swiper_slide_title_3": "Agility at scale",
        "swiper_slide_text_3": "A company that relies on agility is able to mobilize its collective intelligence to create value and evolve iteratively and continuously, saving resources and energy and creating the conditions for fulfillment.",
        "swiper_slide_title_4": "Journey to the cloud, let's reinvent the wheel",
        "swiper_slide_text_4": "We gradually support you on the journey to the cloud with virtual training on digital transformation, cloud adoption and data migration.",
        "swiper_slide_title_5": "Bitrix 24",
        "swiper_slide_text_5": "A business management platform. It is equipped with a project and task management system, a collaborative messaging system and a videoconferencing service. BITRIX offers a unified digital Workplace to organizations that wish to integrate their CRM and develop their internal and external communication.",
        "swiper_slide_title_6": "Fortinet",
        "swiper_slide_text_6": " It is a constantly evolving, high-performance network security appliance that protects users and data from various threats. It consolidates security and simplifies the security infrastructure.",
        "home_news": "New arrivals",
        "home_read_more": "Read more",
        "swiper_slide2_title_1": "Nuxeo",
        "swiper_slide2_text_1": "Nuxeo makes it easy to create intelligent content management applications that enrich the customer experience, improve decision making, and accelerate product launch to market.",
        "swiper_slide2_title_2": "Odoo",
        "swiper_slide2_text_2": "Proven, Odoo is a suite of open source applications that offers multiple applications for managing your business: CRM, e-commerce, accounting, inventory management, project management... The software is fully modular, and allows companies to build a tailor-made ERP by choosing the applications they need.",
        "swiper_slide2_title_3": "Alfresco",
        "swiper_slide2_text_3": "Developed as a document management solution, Alfresco makes it easy for companies to set up and maintain an organizational system of files, and helps automate document-centric business processes.",
        "swiper_slide2_title_4": "Docuware",
        "swiper_slide2_text_4": "DocuWare, which publishes an EDM solution, observed a significant increase in the use of its cloud solutions during the lockdown last March. And this trend is confirmed today.",
        "swiper_slide2_title_5": "Axelor",
        "swiper_slide2_text_5": " Axelor is the Open Source ERP adapted to meet the increasingly complex needs of administrations and public services, in compliance with regulatory constraints. ",
        "home_products_services": "Our Products and Services",
        "home_services_descr": "We support our clients in accelerating their digitalization process around our services.",
        "home_services_box_title_1":"IT Consulting/Strategy",
        "home_services_box_text_1":"In a changing environment, companies must adapt and evolve in a continuous process. We support companies and their managers, from the IT audit to the proposal of concrete solutions adapted to their sector of activity.",
        "home_services_box_title_2":"Project management",
        "home_services_box_text_2":"Follow your project from its start to its delivery. Whatever your project, we position ourselves as a real partner by following closely and with your teams, the different phases of the life cycle of your projects to guarantee their smooth running by emphasizing the balance between quality-cost-deadlines. We master several project management methodologies such as the V Cycle, the Cube method and Agile Methodologies.",
        "home_services_box_title_3":"Business intelligence",
        "home_services_box_text_3":"Business intelligence is computing for decision-makers and business leaders. We provide you with data analysis and information presentation technology processes to help business leaders, managers and other end users make informed business decisions.",
        "home_services_box_title_4":"Software engineering",
        "home_services_box_text_4":"Specialized in computer engineering, Ova consulting brings its expertise to companies that have difficulties and/or computer needs. Our help for SMEs who want to have a quality IT service is unmatched. Integrator of IT solutions, we intervene on the one hand, on Software engineering and on the other hand, on Assistance and technical support.",
        "home_services_box_title_5":"API management",
        "home_services_box_text_5":"To successfully open your IS, you need to know the advantages of API Management but also identify its limits. Expert in API management, we offer you API-Gateway technologies (Kong, API-MAN, Apigee, etc.) to adapt to your infrastructure and your API ecosystem in order to better manage, market and monitor your APIs",
        "home_services_box_title_6":"Infrastructure and Cloud",
        "home_services_box_text_6":"Having become a real asset for the company, data (DATA) needs to be protected more than ever. This is why we offer a range of solutions based on Cloud Computing through our various AWS and/or AZURE products allowing you to build your virtual infrastructure according to your organization, your budget and your business objectives.",
        "home_services_box_title_7":"Training and certification",
        "home_services_box_text_7":"Ova consulting offers training and services adapted to your daily needs via a 100% personalized approach to give you the means to achieve your ambitions. Learn digital through our trainings! 4 to 6 weeks of support with our trainers, the best experts still in activity! We train on topics related to Office IT (Microsoft Office Suite), Software Packages, Project Management (Agile), Front/Back Developments, Data and Storage, Home Solution, Cloud, DevOps and many other topics.",
        "home_services_box_title_8":"Assistance and technical support",
        "home_services_box_text_8":"We supply and equip the various services: from servers, workstations, printers, network equipment, individual scanners to mobility and interconnected equipment. On the lookout for technical developments, we make sure to provide customers with the latest equipment to operate with performance. We are DELL, Lenovo, Xerox, Microsoft and HP partners. We are also able to supply any type of brand and material.",
        "ovassure_description": "Do you want to raise funds for your association, an event, your financing? If you have a very specific associative project, the most logical, easy and practical for you will be to use the TCHOKO application. More than 15 000 customers around the world trust us to revolutionize their processes. Each of them has a story to tell.",
        "tchoko_description": "Beyond the claimant, an insurance company needs to declare the various interlocutors who will intervene in a traffic accident. Build your claim file from a single click.",
        "home_our_methods_audit": "IT audit",
        "home_our_methods_audit_text": "The IT audit is a diagnosis that allows you to better know the level of protection of your information system, your IT infrastructure, your network or the processing of your data. It guarantees the continuity, integrity and availability of your IT resources. Its objective will be to protect you from internal or external threats. Your risk management is better controlled.",
        "home_our_methods_rapp_recom": "Needs collection and specification",
        "home_our_methods_rapp_recom_text": "At OVA Consulting, the phase of collecting and formalizing the need is a key step: you have to be able to identify all the actors to be involved in the process, to correctly understand the need and to transcribe it effectively.",
        "home_our_methods_roadmap_budg": "Roadmap and budgeting costing",
        "home_our_methods_roadmap_budg_text": "Defining a roadmap is a decisive step; indeed it is a question of defining a list of things to do with milestones that position the realization of these elements to do in time. Since things to do require energy, they must also be budgeted and evaluated.",
        "home_our_methods_dev": "Analysis and design",
        "home_our_methods_dev_text": "The quality of an interface is based on several criteria beyond the technical requirements. It must meet a specific user need through an ergonomic design that is easy to use and handle.",
        "home_our_methods_training": "Deployment and user training",
        "home_our_methods_training_text": "In engineering with us, the analysis and design method is a process that aims to formalize the preliminary stages of the development of a system in order to make this development more faithful to the needs of the customer.",
        "home_our_methods_evolution": "MCE/TMA",
        "home_our_methods_evolution_text": "This latest milestone marks the availability of the new information system to its end users. This is a step in the transition from project mode to recurring mode (or RUN).",
        "home_our_methods_text": "Choosing OVA Consulting means mobilizing the skills of an entire company to help you transform your ideas into value for the business.",
        "home_our_clients_text": "Ova consulting is a company, whose goal is to help in their search for digital solutions adapted to their activity and digitalization issues.",
        "home_contact_name": "Name",
        "home_contact_email": "Email",
        "home_contact_phone": "Phone",
        "home_contact_subject": "Subject",
        "home_contact_message": "Write your message",
        "home_contact_write_us": "Write us",
        "home_contact_write_us_text": "OVA CONSULTING,  SARL, registered with the RCS of Bonanjo, Headquarters: Rond-point Deido, Opposite Alfred Saker College",
        "home_contact_send": "Send",
        "home_contact_greeting": "Good morning Mr.",
        "home_contact_any_question": "Do you have a question ?",
        "home_contact_hello_any_question": "Hello, do you have a question?",
        // Contact page translations
        "contact_we_are": "We are a young team",
        "contact_we_are_text": "Companies must understand a rapidly changing environment and the adoption of an agile culture becomes a necessity to remain competitive and innovative in this context.",
        "contact_us_title": "Contact us",
        "contact_us_title_text": "Our understanding of business and technological issues allows us to intervene in the strategy and operational performance of information systems.",
        "home_contact_surname": "First name",
        "home_contact_i_want_work": "I want to work with you",
        "home_contact_i_want_devis": "I want a quote",
        "home_contact_cv": "Your CV",
        // Recruitment page translations

        "recruitement_text": "Recruitment",
        "recruitement_our_profiles": "Our wanted profiles",
        "job_postulate_button": "Apply",
        "job_title_1": "Developer",
        "job_title_2": "Business engineers",
        "job_title_3": "Developer",
        "job_title_4": "Developer",
        "job_description_1": "You are looking for new challenges within a company on a human scale. Our company will give you all the means to succeed and support your development with real career opportunities. As part of our various missions in Design and Development of numerous Websites and Mobile Applications, we are looking for a developer for direct employment after testing.",
        "job_description_2": "You want to join an innovative startup, cultivating team spirit and invested in new technologies and in large-scale business. OVA Consulting is a Web company located in the heart of the city of Douala offering Web / Mobile solutions to build smart platforms.",
        "job_description_3": "You want to join an innovative startup, cultivating team spirit and investing in new technologies. OVA Consulting is a Web agency located in the heart of the city of Douala offering Web / Mobile solutions to build smart platforms. Our company has several references and brings together a team of several experts in the field, from software architecture to developments including UI / UX design.As part of our various missions Design and Development of many Websites and Applications web / Mobile, we are looking for an intern for a period of 3 to 6 months.",
        "job_description_4": "You want to join an innovative startup, cultivating team spirit and investing in new technologies. OVA Consulting is a Web agency located in the heart of the city of Douala offering Web / Mobile solutions to build smart platforms. Our company has several references and brings together a team of several experts in the field, from software architecture to developments including UI / UX design.As part of our various missions Design and Development of many Websites and Applications web / Mobile, we are looking for an intern for a period of 3 months.",
        // Digital page translations
        "digitalize_us": "Nous Digitaliser",
        "digitalize": "Digitalisation",
        "digitalize_text": "Ova consulting privilégie la qualité à la quantité. Loin d’être uniforme, tout contenu publié sur Ova consulting apporte des informations stratégiques à valeur ajoutée pour nourrir votre réflexion et accompagner votre prise de décision sur des problématiques IT et la digitalisation métier..",
        "to_us": "Chez nous",
        "to_us_text": "Digitalization is now a must for a company or any type of organization. Indeed, it applies to all areas and ensures optimization of time and money by automating increasingly complex tasks. It can even become a competitive advantage in areas where the digital shift has not been fully accomplished by companies.",
        "how_it_work": "How does it work?",
        "how_it_work_text": "",
        "our_team": "Our team",
        "how_it_work_1": "Agile Aproach",
        "how_it_work_1_text": "At OVA Consulting we practice the agile method which sets short term objectives, hence the project is divided into several sub-projects. Once the objective is reached, we move on to the next one until the final objective is achieved. It is a very flexible approach.",
        "how_it_work_2": "TDD/BDD/clean architecture",
        "how_it_work_2_text": "Behavior-driven development is a development technique that focuses more on the behavior of an application. Test-driven development is a development technique that focuses more on the implementation of an application or software product's functionality. In BDD, the participants are the developers, the customers and the users.",
        "how_it_work_3": "DevOps Approach",
        "how_it_work_3_text": "In an ideal and optimal approach, the Devops approach for OVA and its methodology must be adopted from the first phase of its development.",
        "how_it_work_4": "Cloud Deployment",
        "how_it_work_4_text": "Agile development is a continuous software production cycle. Cloud Computing provides tools and infrastructure to the teams that perform these agile developments to ensure and facilitate the integration, automation and deployment processes.",
        "how_it_work_5": "Corrective and Evolutionary Maintenance",
        "how_it_work_5_text": "Being concerned about the different projects it carries out, OVA Consulting proposes to accompany its clients in a maintenance process.  It forecasts the percentage of production devoted to maintenance. The more productive people are, the more production capacity will increase and the less disruptive maintenance interventions will be in relation to the total volume.",
        "how_it_work_6": "SAAS Solution",
        "how_it_work_6_text": "The implementation is adopted to projects for which the needs are not precisely identified nor clearly defined. We elaborate together your vision of the product to realize. We list the functionalities of the target product. Our team estimates the cost of each element and allows the estimation of the global budget. At the end of each iteration, the partial but usable product is presented to you.",
        "how_it_work_7": "Website",
        "how_it_work_7_text": "The basic objective of your website is to provide accurate and up-to-date information about your business. A huge advantage of your website, compared to other communication tools, is that it can easily be updated in real time. As a result, you gain flexibility and responsiveness. A new department? A new product? It can be added to your website instantly.",
        "how_it_work_8": "Communication Tools",
        "how_it_work_8_text": `
        A communication tool allows the transmission of information for the purpose of conveying a message and promoting services or products to customers and prospects. It is used as part of a strategy established by the communication team of a company.

        Whether it is to gain visibility, develop its turnover, increase its notoriety or attract new customers, it is essential for a company to equip itself with communication tools. Internally or externally, communicating effectively with its audiences is an important issue. It is therefore necessary to set up global marketing strategies, within which are available communication actions, accompanied by communication tools adapted to the objectives.
        `,
        "how_it_work_9": "Marketing",
        "how_it_work_9_text": "It is more like an organizational method than a communications strategy. It is a fundamental approach to marketing in which teams identify and focus on important tasks, work cooperatively on them, measure the impact of their actions, and continuously seek incremental improvements over time.",
        "how_it_work_10": "Progressive Web App",
        "how_it_work_10_text": "A website optimized for mobile and designed to look like a native application. They run from a web browser and the loaded features are kept in cache for future offline use without passing for an application store.",
        // Footer
        "home_about": "About us",
        "about_text_p_1": "Subsidiary of Ova Corporate SA France, OVA Consulting SARL is a digital company, located in the heart of the city of Douala and specialized in the realization of unique interactive digital experience.",
        "about_text_p_2": "Indeed, we support both large groups and SMEs in their digital transformation process. Thanks to our expertise in the integration and deployment of IT solutions, particularly in the sectors (Banking, Insurance, Agrifood, Logistics/Transport etc…)",
        "about_text_p_3": "Our team is representative of our collaborative approach, like our open-space digital workshop, where everyone participates and showcases their talent!",
        "about_text_p_4": "Methodology and rigor allow us to work throughout the territory and beyond its borders. We follow you and advise you at each stage of your projects, in order to obtain a result in line with your objectives. .",

        "cookies_text": "This website uses cookies to enhance the user's experience.",
        "cookies_accept": "Accept",
        "cookies_decline": "refuse",
        "home": "Home",
        "our_methods": "Our Methods",
        "def_of": "Definition of ",
        "digit": "Digitalisation",
        "home_know_more":"Know more",
        "home_our_products": "Our products",
        "home_our_partners": "Our partners",
        "our_strategy": "Our strategy",
        "PRODUCTS": "PRODUCTS",
        "SERVICES": "SERVICES",
        "let_know_our_trainings": "Find all our training courses on different subjects.",

        "home_contact_news_letter": "News letters",
        "home_contact_news_letter_text": "Enter your email address order not to miss any of our updates.",
        "SUBSCRIBE": "SUBSCRIBE",
        "home_our_trainings": "Trainings",
        "job_application_ends": "Application ends",
        "job_apply": "APPLY",
        "job_description": "Job Description",
        "job_key_responsabilities": "Key Responsibilities",
        "skills_experience": "Skills and Experience",
        "share_job": "Share this Job",
        "what_new": "What's",
        "what_is": "What's",
        "what_is_new": "New ?",
        "home_what_new": "What's new ?",
        "contact_contact_us": "Contact us",
        "contact_location": "Location",
        "contact_phone": "Phones",
        "contact_email": "Email",
        "contact_location_cameroon": "Cameroon",
        "contact_location_france": "France",
        "leave_us_message": "Leave us a message.",
        "text_footer_description": "Subsidiary of OVA Corporate SA France, OVA Consulting is a digital company, located in the heart of the city of Douala and specialized in the realization of unique interactive digital experience.",
        "job_application_title": "Job Application",
        "job_application_text": "Please complete the form below to apply for a position with us",
        "job_application_first_name": "Name",
        "job_application_middle_name": "Middle name",
        "job_application_last_name": "First name",
        "contact_current_address": "Current Address",
        "job_application_address": "Address",
        "job_application_cover_letter" : "Cover letter",
        "contact_city": "City",
        "contact_region": "Region",
        
        "training_title": "Trainings",
        "training_text": "OVA consulting supports you in improving the skills of your teams through constantly updated training in order to provide innovative responses to the constant needs for development and competitiveness of companies.",
        "training_title_1": "Agile",
        "training_desc_1": "Today, at the heart of companies, agile practices emphasize collaboration between self-organized, multidisciplinary teams and their customers.",
        "training_title_2": "Microsoft office 365",
        "training_desc_2": "In order to support its customers and IT partners, OVA Consulting offers comprehensive training to implement Microsoft 365 in order to enable the company to master fully integrated tools for collaboration, voice and professional knowledge.",
        "training_title_3": "System Administration",
        "training_desc_3": "While most businesses rely heavily on stable IT operations, they constantly need professional system administrators to manage and maintain their IT networks.",
        "training_title_4": "VMWare",
        "training_desc_4": "State-of-the-art training to ensure you get the most out of the entire VMware virtualization software solution.",
        "training_title_5": "DevOps",
        "training_desc_5": "The Devops practice emphasizes collaboration and communication between software developers and IT operations professionals.",
        "training_title_6": "ITIL 4",
        "training_desc_6": "With our ITIL v4 certification training, leverage your organization's IT investments in international IT management best practices to deliver value-added services.",
        "training_duration_6": "4 days",
        "training_title_7": "COBIT",
        "training_duration_5": "3 days ",
        "training_duration_4": "3 days ",
        "training_duration_3": "2 days ",
        "training_duration_2": "5 days ",
        "training_duration_1": "2 days ",
        "training_desc_7": "In order to ensure a good control and a durable follow-up of the IS governance by integrating the recognized good practices",
        "training_duration_7": "4 days ",
        "training_title_9": "ISO 27001",
        "training_desc_9": "To maintain and improve the information security management system (ISMS) to reduce risks, comply with standards and requirements.",
        "training_duration_9": "5 days",
        "our_clients_text": "Over 50 companies in over 10 countries. Our clients are companies of all sizes and from different sectors of activity, including transport, banking, hotels, restaurants, education or the retail sector.",
        "how_it_work_text_1": "In order to position itself as well as possible on its markets, OVA Consulting has embarked, between 2021 and 2025, on a phase of profound transformation, marked by a refocusing on specialties and new technologies, a method, a simpler, more integrated organization. and more responsive, and a substantial cost savings program.",
        
        "fetching_jobs_error": "Job not found",
        "FULLTIME": "Full time",
        "PARTIALTIME": "Part time",
        "ova_location_paris": "OVA CORPORATE,  SARL, registered with the RCS of PARIS, Head Office: 30/32 boulevard de Sébastopol 75004 Paris France",
        "ova_location_cameroon": "OVA CONSULTING,  SARL, registered with the RCS of Bonanjo, Head Office: Rond-point Deido, Facing Collège Alfred Saker",
        "contact_need_info": "Need more information ?",
        "anounces_title": "Anounces",
        "ACADEMIC_STAGE": "Academic internship",
        "PROFESSIONAL_STAGE": "Professional Internship",
        "Founder, CEO": "Founder, CEO",
        "Executive Team": "Executive Team",
        "Production Team": "Production Team",
        "Team Com": "Team Com",
        "Comunity manager": "Comunity manager",
        "Commercial": "Commercial",
        "Sales Manager, Executive Assistant": "Sales Manager, Executive Assistant",
        "Software Engineer": "Software Engineer",
        "Inra/Security Expert": "Inra/Security Expert",
        "Software Achitech": "Software Achitech",
        "Project manager": "Project manager",
        "UX/UI Designer, Graphic Designer": "UX/UI Designer, Graphic Designer",
        "Business Developer": "Business Developer",
        "Tech Lead": "Tech Lead",
        "Operations Manager": "Operations Manager",

        // Our expertise text translation.

        "expertise_design_text": "Our design team is deeply involved in our development processes and provides outstanding UX design services for mobile and web apps, as well as human-machine interface for various industries.",
        "Read More": "Read More",
        "expertise_software_engineering": "Software Engineering",
        "expertise_software_engineering_text": "We have the drive, expertise and software engineering talent to take any idea from the early concept stage to a commercial product that delivers value to your business and unlock new opportunities.",
        "expertise_infrastructure": "Infrastructure and security",
        "expertise_infrastructure_text": "The concept of infrastructure security and perimeter security refers to the products, solutions and services used to protect the infrastructures of companies.",
        "expertise_training": "Training",
        "expertise_training_text": "Training",
        "expertise_data_science": "Data science",
        "expertise_data_science_text": "We leverage advanced big data and business intelligence tools to help clients extract actionable insights from diverse data sets generated in real time and at a large scale. We help them consolidate huge volumes of data coming from different sources into a holistic environment that can be used for modelling and predicting new market opportunities.",

        "online": "Online",
        "face-to-face": "Face to face",
        "management": "Management",
        "price-on-demand": "Price on demand",
        "categories": "Categories",
        "read_more" : "Read more",
        "form_last_name": "Last Name",
        "form_society": "Society",
        "form_role": "Role",
        "form_postal_code": "Postal code",
        "form_inscriptions_number": "Number of inscriptions wanted",
        "form_compl_info": "Additional information or promotional code",
        "form_interested": "I'm interested in a particular course.",
        "form_accept": "I have read and accept the Legal Notice and the Personal Data Protection Policy.",
        "i_subscribe": "I subscribe",
        "inscript_form": "Registration Form",
        "not_found_text": "The requested page does not exist",
        "not_found_home": "Return to home",
        "starting_date": "Desired start date",
        "ending_date": "Desired end date",

        "course_objectives": "Pedagogical Objects",
        "required_level":"Required Level",
        "public_concerned": "Public concerned",
        "detailed_program": "Detailed program",
        "objectives_skills": "Objectives and skills",
        "face_to_face_session_q": "Face-to-face sessions schedule",
        "face_to_online_session_q": "Online session schedule",

        // Trainings translations en 

        "do_you_want_to_follow_this_training": "Do you want to follow this training?",
        "training_availability": "This training is available face-to-face or in a remote classroom, with an identical program and pedagogical quality.",
        "choose_modality": "Choose the desired modality to register:",
        "follow_training_in_presence": "Follow this training in person",
        "follow_training_in_online": "Follow this training remotely",
        "your_company_need_special_offer": "Your company needs a special offer?",

        // Trainings translations en 

        // Agile

        "training_agile_objectives": `<ul>
        <li>Understanding the principles of Scrum</li>
        <li>Ensuring your role as Scrum master</li>
        <li>Implementing the Scrum approach</li>
        <li>Complete your Professional Scrum Master (PSM 1) certification from Scrum.org </li>
        </ul>`,
        "training_agile_required_level": "Minimum high school level, computer proficiency.",
        "training_agile_concerned_public": "Project Manager, Digital Project Manager, Scrum Master, Developer, Managers, DevOps",
        "training_agile_program_title_1": "Day 1",
        "training_agile_program_content_title_1": "1 - Understand the SCRUM approach and identify the actors",
        "training_agile_program_content_text_1": `The benefits of the SCRUM approach. <br/>
        Identify the steps of a SCRUM approach.<br/>
        Identify the actors: Product Owner, Scrum Master, development team.`,
        "training_agile_program_content_title_2": "2 - Expressing needs",
        "training_agile_program_content_text_2": `The product requirements, the Backlog. <br/>
        The stories. <br/>
        Prioritize requirements. <br/>
        Detailed descriptions.<br/>
        The product backlog.`,
        "training_agile_program_content_title_3": "3 - Plan the work to be done and evaluate the loads",
        "training_agile_program_content_text_3": `Define tasks based on stories.<br/>
        Evaluate the charges that this represents.<br/>
        Plan releases, sprints.<br/>
        Prioritize and distribute tasks.`,
        "training_agile_program_title_2": "Day 2",
        "training_agile_program_2_content_title_1": "4 - Perform the Sprints",
        "training_agile_program_2_content_text_1": `The Sprint Planning Meeting.<br/>
        The organization of daily work, the daily meeting.<br/>
        Review progress.<br/>
        The end and review of a Sprint.<br/>
        Feedback, continuous improvement.`,
        "training_agile_program_2_content_title_2": "5 - Implement tracking and acceptance",
        "training_agile_program_2_content_text_2": `Organize the different tests.<br/>
        Acceptance testing.<br/>
        Carry out the tests.`,
        "training_agile_program_2_content_title_3": "6 - Prepare for the Professional Scrum Master PSM I certification",
        "training_agile_program_2_content_text_3": "Perform mock exams under exam conditions with commented correction",
        "training_agile_program_2_content_title_4": "7 - Exam",
        "training_agile_program_2_content_text_4": `Pass the Professional Scrum Master 1 certification from Scrum.org:<br/>
        MCQ: 80 questions in English in 1 hour.<br/>
        Identifiers will be provided to participants.<br/>
        Requires only a PC connected to the internet.<br/>
        It is advisable to take the exam within a maximum of 2 weeks to 1 month after the start of the session.`,

        // Microsoft office 365

        "training_microsoft_office_365_objectives": `
        <ul>
        <li>
        enterprise deployment, management, configuration and troubleshooting.
        </li>
        <li>
        Messaging infrastructure management, hybrid configuration, migration, etc.
        </li>
        </ul>`,
        "training_microsoft_office_365_required_level": "Minimum high school education level, computer proficiency. ",
        "training_microsoft_office_365_concerned_public": "Project Manager, Digital Project Manager, Scrum Master, Developer, Managers, DevOps",
        "training_microsoft_office_365_program_title_1": "Day 1",
        "training_microsoft_office_365_program_content_title_1": "Module 1: Transport Pipeline Management",
        "training_microsoft_office_365_program_content_text_1": `
        <ul>
        <li>
        Overview of transportation services
        </li>
        <li>
        Configuring message transport
        </li>
        <li>
        Transport rule management
        </li>
        <li>
        Lab: Configure message transport
        </li>
        <li>
        Create connectors
        </li>
        </ul>`,
        "training_microsoft_office_365_program_content_title_2": "Module 2: Managing and Troubleshooting Mail Flow",
        "training_microsoft_office_365_program_content_text_2": `
        <ul>
        <li>
        Mail flow management
        </li>
        <li>
        Troubleshoot mail flow
        </li>
        <li>
        Troubleshooting Transport Issues
        </li>
        <li>
        Troubleshooting with logs
        </li>
        <li>
        Lab: Conditional Mail Routing
        </li>
        <li>
        Create mail flow rules
        </li>
        </ul>`,
        "training_microsoft_office_365_program_content_title_3": "Module 3: Managing Message Hygiene",
        "training_microsoft_office_365_program_content_text_3": `
        <ul>
        <li>
        Message Hygiene Planning
        </li>
        <li>
        Managing anti-malware and anti-spam policies
        </li>
        <li>
        Managing Advanced Threat Protection
        </li>
        <li>
        Lab: Messaging Hygiene Management
        </li>
        <li>
        Create hygiene filters
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_2": "Day 2",
        "training_microsoft_office_365_program_2_content_title_1": "Module 4: Compliance Management",
        "training_microsoft_office_365_program_2_content_text_1": `
        <ul>
        <li>
        Messaging Compliance in CSC
        </li>
        <li>
        Mail Compliance in Exchange
        </li>
        <li>
        Managing Exchange Online Archiving and Auditing
        </li>
        <li>
        Content search management
        </li>
        </ul>`,
        "training_microsoft_office_365_program_2_content_title_2": "Module 5: Managing Organizational Settings",
        "training_microsoft_office_365_program_2_content_text_2": `
        <ul>
        <li>
        Email Authentication Management
        </li>
        <li>
        Configuring Organizational Settings
        </li>
        <li>
        Configuring Organizational Sharing
        </li>
        </ul>`,
        "training_microsoft_office_365_program_2_content_title_3": "Module 6: Mobile Device Management",
        "training_microsoft_office_365_program_2_content_text_3": `
        <ul>
        <li>
        Mobile device mailbox policies
        </li>
        <li>
        Mobile device access management
        </li>
        <li>
        Lab: Implementing ActiveSync
        </li>
        <li>
        Implement Active Sync for one or more mailboxes
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_3": "Day 3",
        "training_microsoft_office_365_program_3_content_title_1": "Module 7: Managing Role-Based Permissions",
        "training_microsoft_office_365_program_3_content_text_1": `
        <ul>
        <li>
        Managing admin roles
        </li>
        <li>
        Managing user roles
        </li>
        <li>
        Exchange Setup - RBAC and AD Split Authorization
        </li>
        <li>
        Lab: Managing Roles and Authorization Rules
        </li>
        <li>
        Manage roles and permission policies
        </li>
        </ul>`,
        "training_microsoft_office_365_program_3_content_title_2": "Module 8: Managing recipient objects and resources",
        "training_microsoft_office_365_program_3_content_text_2": `
        <ul>
        <li>
        Exchange recipients
        </li>
        <li>
        Creating and Managing Exchange Recipients
        </li>
        <li>
        Managing email addresses, lists, and resources
        </li>
        <li>
        Lab: Create Recipient Objects and Resources
        </li>
        <li>
        Create exchange recipients
        </li>
        <li>
        Create groups
        </li>
        </ul>`,
        "training_microsoft_office_365_program_3_content_title_3": "Module 9: Managing Public Folders",
        "training_microsoft_office_365_program_3_content_text_3": `
        <ul>
        <li>
        Planning the Public Folder Hierarchy
        </li>
        <li>
        Implementing and Managing Public Folders
        </li>
        <li>
        Troubleshooting Public Folders
        </li>
        <li>
        Lab: Implement Public Folders
        </li>
        <li>
        Create public folders
        </li>
        <li>
        Manage public folders
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_4": "Day 4",
        "training_microsoft_office_365_program_4_content_title_1": "Module 10: Planning a hybrid environment",
        "training_microsoft_office_365_program_4_content_text_1": `
        <ul>
        <li>
        Exchange Hybrid Deployment Requirements
        </li>
        <li>
        Scheduling to run the Hybrid Configuration wizard
        </li>
        <li>
        Lab: Prepare Azure AD for hybrid synchronization
        </li>
        <li>
        Prepare Azure AD for hybrid synchronization
        </li>
        </ul>`,
        "training_microsoft_office_365_program_4_content_text_2": `
        <ul>
        <li>
        Planning mailbox migrations
        </li>
        <li>
        Perform IMAP migrations
        </li>
        <li>
        Perform cutover and staged migrations
        </li>
        <li>
        Perform advanced migrations
        </li>
        </ul>`,
        "training_microsoft_office_365_program_4_content_title_2": "Module 11: Performing Mailbox Migrations",
        "training_microsoft_office_365_program_title_5": "Day 5",
        "training_microsoft_office_365_program_5_content_title_1": "Module 12: Deploying and Troubleshooting a Hybrid Environment",
        "training_microsoft_office_365_program_5_content_text_1": `
        <ul>
        <li>
        Deploying and Managing an Edge Transport Server
        </li>
        <li>
        Configuring a hybrid deployment using HCW
        </li>
        <li>
        Implementing advanced hybrid functionality
        </li>
        <li>
        Troubleshooting hybrid deployments
        </li>
        <li>
        Lab: Deploying a hybrid environment
        </li>
        <li>
        Configure your hybrid deployment
        </li>
        <li>
        Test your hybrid deployment
        </li>
        </ul>
    Take the certification
    Identifiers will be provided to participants.
    Only requires a PC connected to the internet.
        `,
        // vmware

        "training_vmware_objectives": `
        <ul>
        <li>
        Administer vCenter Server to manage and configure an ESXi host;
        </li>
        <li>
        Deploy VMware vCenter Server Appliance and configure it and secure it;
        </li>
        <li>
        Create virtual networks through standard vSphere switches and create policies;
        </li>
        <li>
        Administer vCenter Server to manage virtual host storages;
        </li>
        <li>
        Configure vSphere for VM deployment, templating, cloning, and snapshots;
        </li>
        <li>
        Migrate VMs with their storage via VMware vSphere® and vMotion®;
        </li>
        <li>
        Manage VMware vSphere® Distributed Resource Scheduler™ clusters;
        </li>
        <li>
        Administer VMware vSphere® Update Manager™ and perform troubleshooting or updates.
        </li>
        </ul>
        `,
        "training_vmware_required_level": "To take the training you must have significant professional experience as a Windows or Linux administrator",
        "training_vmware_concerned_public": "System Administrators, System Engineers, and Operators responsible for ESXi and vCenter Servers; Candidates seeking the 2020 VCP-DCV Professional Certification.",
        "training_vmware_program_title_1": "Day 1",
        "training_vmware_program_content_title_1": "VMware vSphere 7 Training Overview: ",
        "training_vmware_program_content_text_1": `
        <ul>
        <li>introduction and logistics; overview of training objectives; </li>
        <li>Description on the program; VMware certification system decryption;</li>
        <li>Getting Started with the VMware Education Learning Zone; </li>
        <li>Identification of additional resources. </li>
        </ul>`,
        "training_vmware_program_content_title_2": "Installing vSphere 7 and Software Datacenter:",
        "training_vmware_program_content_text_2": `
        <ul>
        <li>
        Description of vSphere integration with software data center and cloud infrastructure; a definition of DevOps and digital transformation;
        </li>
        <li>
        Explain how vSphere interacts with CPUs, memory, networks, and storage systems;
        </li>
        <li>
        Using the vSphere Client to Access and Manage the vCenter Server System and ESXi Host
        </li>
        <li>
        Comparison of virtual machine version 14 with other versions;
        </li>
        <li>
        Identification of virtual network adapters and description of the improved VMXNET3; comparison on the different types of virtual disks; identify the benefits of ESXi Quick Boot.
        </li>
        </ul>
        `,
        "training_vmware_program_title_2": "Day 2",
        "training_vmware_program_2_content_title_1":"Creating and managing virtual machines:",
        "training_vmware_program_2_content_text_1": `
        <ul>
        <li>
        Creation, provisioning and deletion of a virtual machine;
        </li>
        <li>
        Explanation of the importance of VMware Tools™;
        </li>
        <li>
        Description of importing a virtual device OVF template;
        </li>
        <li>
        VMware Tools management;
        </li>
        <li>
        Explanation of solutions to problems installing operating systems and VMware Tools.
        </li>
        </ul>
        `,
        "training_vmware_program_2_content_title_2":"The vCenter Server Centralized Management Utility:",
        "training_vmware_program_2_content_text_2": `
        <ul>
        <li>
        Description of the vCenter Server architecture;
        </li>
        <li>
        Description of communication between ESXi hosts and vCenter Server;
        </li>
        <li>
        Identification of services, components and modules; access and configure vCenter Server Appliance; use vSphere Client to manage vCenter Server inventory;
        </li>
        <li>
        Description of authorization application rules; create a custom role in vCenter Server; creation of a backup plan; restore vCenter Server Appliance from backup; vCenter Server Appliance monitoring.
        </li>
        </ul>
        `,
        "training_vmware_program_2_content_title_3": "Configuring and managing virtual networks:",
        "training_vmware_program_2_content_text_3": `
        <ul>
        <li>
        Description, creation and management of standard switches;
        </li>
        <li>
        Configuring Virtual Switch Security and Load Balancing Policies
        </li>
        <li>
        Comparison of vSphere Distributed Switches and Standard Switches; description of virtual switch connection types;
        </li>
        <li>
        Description of the new TCP/IP layered architecture;
        </li>
        <li>
        Use of VLANs with standard switches.
        </li>
        </ul>`,
        "training_vmware_program_2_content_title_4": "Configuring and managing virtual storage: ",
        "training_vmware_program_2_content_text_4": `
        <ul>
        <li>
        Identify storage protocols and types of storage devices;
        </li>
        <li>
        Description of ESXi hosts that use iSCSI, NFS, and Fiber Channel storage;
        </li>
        <li>
        Create and manage VMware vSphere® VMFS and NFS datastores;
        </li>
        <li>
        Description of new features in VMFS 7.0;
        </li>
        <li>
        Identify the benefits of VMware vSAN™; description of guest file encryption
        </li>
        </ul>
        `,
        "training_vmware_program_title_3": "Day 3",
        "training_vmware_program_3_content_title_1": "Managing a virtual machine:",
        "training_vmware_program_3_content_text_1": `
        <ul>
        <li>
        Using templates and cloning to deploy new virtual machines;
        </li>
        <li>
        Modification and management of virtual machines;
        </li>
        <li>
        Cloning a virtual machine; upgrade a virtual machine to version 14;
        </li>
        <li>
        Deleting virtual machines from vCenter Server inventory and a datastore;
        </li>
        <li>Using spec files to customize a new virtual machine;</li>
        </ul>
        `,

        // Devops

        "training_devops_objectives": `
        <ul>
        <li>
        Define the strategic and economic objectives of a DevOps approach;
        </li>
        <li>
        Discover and assimilate the glossary used in a DevOps environment;
        </li>
        <li>
        Describe the assets for the company and for its IT infrastructure;
        </li>
        <li>
        Define and discuss key concepts and principles of DevOps;
        </li>
        <li>
        Explain how DevOps relates to Lean, Agile methodologies and ITSM tools;
        </li>
        <li>
        Understand efficiency in workflows, communication and feedback;
        </li>
        <li>
        Explain the concepts of test automation, infrastructure automation, supply chain automation as well as deployment and associated DevOps tools;
        </li>
        <li>
        Understand its large-scale deployment in a company;
        </li>
        <li>
        Discover and discuss critical success factors and performance indicators for DevOps implementation;
        </li>
        <li>
        Summarize case studies of IT organizations making the transition to DevOps models;
        </li>
        <li>
        Pass and obtain the DevOps Foundation certification from DevOps Institute®.
        </li>
        </ul>
        `,
        "training_devops_required_level": "People involved in IT Development, IT Operations, or IT Service Management such as DevOps or Agile Engineers, Product Owners, or Incident and Change Managers",
        "training_devops_concerned_public": "To follow the DevOps Foundation training it is necessary to have the following prerequisite: knowledge of the ITIL framework, Lean IT or business agility is highly recommended.",
        "training_devops_program_title_1": "Day 1",
        "training_devops_program_content_title_1": "Intro to DevOps:",
        "training_devops_program_content_text_1": `
        <ul>
        <li>
        The definition of DevOps and digital transformation;
        </li>
        <li>
        The importance of a DevOps approach in business.
        </li>
        </ul>`,
        "training_devops_program_content_title_2": "Core DevOps Concepts:",
        "training_devops_program_content_text_2": `
        <ul>
        <li>
        The three paths of the DevOps approach
        </li>
        <li>
        1st way: the work flow;
        </li>
        <li>
        The theory of constraints;
        </li>
        <li>
        2nd way: feedback;
        </li>
        <li>
        3rd way: continuous experimentation and learning;
        </li>
        <li>
        The principles of Chaos Engineering;
        </li>
        <li>
        The concept of the learning organization.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_3": "DevOps Development Practice:",
        "training_devops_program_content_text_3": `
        <ul>
        <li>
        Continuous Delivery;
        </li>
        <li>
        Reliability engineering and resilience engineering;
        </li>
        <li>
        The DevSecOps approach;
        </li>
        <li>
        The ChatOps collaboration tool;
        </li>
        <li>
        The Kanban method.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_4": "Business and framework:",
        "training_devops_program_content_text_4": `
        <ul>
        <li>
        The DevOps approach and agility;
        </li>
        <li>
        IT service management (ITSM);
        </li>
        <li>
        Lean IT and DevOps;
        </li>
        <li>
        Integrating the DevOps culture into security;
        </li>
        <li>
        organizational learning;
        </li>
        <li>
        Sociocratic and holacratic governance;
        </li>
        <li>
        Funding continues.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_5": "Company Culture and Operating Models:",
        "training_devops_program_content_text_5": `
        <ul>
        <li>
        The implementation of the corporate culture;
        </li>
        <li>
        Models of good conduct;
        </li>
        <li>
        organizational models;
        </li>
        <li>
        Models of how goals work.
        </li>
        </ul>
        `,
        "training_devops_program_title_2": "Day 2",
        "training_devops_program_2_content_title_1": "Automation tools and DevOps architecture:",
        "training_devops_program_2_content_text_1": `
        <ul>
        <li>
        Continuous Integration (CI/CD);
        </li>
        <li>
        Cloud Computing;
        </li>
        <li>
        container technology;
        </li>
        <li>
        Automating deployment with Kubernetes;
        </li>
        <li>
        The DevOps tool chain.
        </li>
        </ul>
        `,
        "training_devops_program_2_content_title_2": "ROI measurement and reporting:",
        "training_devops_program_2_content_text_2": `
        <ul>
        <li>
        The importance of Devops metrics;
        </li>
        <li>
        Technical metrics;
        </li>
        <li>
        The different metrics used in business;
        </li>
        <li>
        Performance indicators and reporting.
        </li>
        </ul>
        `,
        "training_devops_program_title_3": "Day 3",
        "training_devops_program_3_content_title_1": "Collaboration, learning and development",
        "training_devops_program_3_content_text_1": `
        <ul>
        <li>
        DevOps collaborative tools;
        </li>
        <li>
        Immersive Learning;
        </li>
        <li>
        experiential learning;
        </li>
        <li>
        The DevOps leader;
        </li>
        <li>
        The development of the DevOps approach.
        </li>
        </ul>
        `,
        "training_devops_program_3_content_title_2": "DevOps Foundation Certification Exam (DOFD)",
        "training_devops_program_3_content_text_2": `
        <ul>
        <li>
        Type of exam: 40 multiple-choice questions
        </li>
        <li>
        Duration: 1 hour
        </li>
        <li>
        Available online
        </li>
        <li>
        Open book: yes
        </li>
        <li>
        Languages: French, English, Spanish, German, Chinese and Polish.
        </li>
        <li>
        Award: 60% correct answers
        </li>
        </ul>`,

        // ITIL 4

        "training_itil_objectives": `Assimilate fundamental service management definitions and concepts; <br/>
        Understand the benefits of ITIL™ for a practice adoption, adaptation and optimization approach; <br/>
        Know the value system of service activities; <br/>
        Develop the 7 major practices of ITIL™; <br/>
        Understand the other 8 ITIL™ practices and their vocabulary; <br/>
        Pass the exam and earn the ITIL™ 4 Foundation certification.
        `,
        "training_itil_required_level": "Minimum high school level, computer proficiency.",
        "training_itil_concerned_public": "Anyone using Windows 10 on their workstation and/or tablet.",
        "training_itil_program_title_1": "1st day",
        "training_itil_program_content_title_1": "ITIL TRAINING MODULE 1: Service Management as an Approach",
        "training_itil_program_content_text_1": `
            Course Overview <br/>
            Course objectives <br/>
            Expected results<br/>
            <strong>Unit 1: Service Management Concepts</strong>
             <br/>
            Effective development of new services and improvement of existing services <br/>
            Good practice<br/>
            Functions, roles and processes <br/>
            <strong>Unit 2: The Service Lifecycle</strong>
             <br/>
            The ongoing design, development and use of the Services <br/>
            Usefulness and warranty <br/>
            Service design and the business of the company <br/>
            NICTs and their integration into the profession
        `,
        "training_itil_program_content_title_2": "ITIL TRAINING MODULE 2: ITIL Service Lifecycle",
        "training_itil_program_content_text_2": `
            Course Overview <br/>
            Course objectives <br/>
            Expected results<br/>
            <strong>Unit 1: The Five Core Processes</strong> <br/>
            Service Strategy <br/>
            Service Design <br/>
            Service Transition <br/>
            Service Operation <br/>
            Continuous improvement of services <br/>
            <strong>Unit 2: The Value of the ITIL Service Lifecycle</strong> <br/>
            Integrate processes throughout the lifecycle <br/>
            Explain the objectives and scope of each phase
        `,
        "training_itil_program_title_2": "Day 2",
        "training_itil_program_2_content_title_1": "ITIL TRAINING MODULE 3: Fundamental Concepts of ITIL",
        "training_itil_program_2_content_text_1": `
            <strong>Unit 1: Identifying and Documenting Services</strong> <br/>
            Service Portfolio <br/>
            Catalog <br/>
            Business file <br/>
            Risks <br/>
            Service Provider <br/>
            Provider <br/>
            Service Level Agreement (SLA) <br/>
            Operational Levels Agreement (OLA) <br/> <br/>
            <strong>Unit 2: Infrastructure Optimization</strong> <br/>
            Service request <br/>
            Change and release <br/>
            Event, alert and incident <br/>
            Work around problems <br/>
            Known Errors and Known Error Database (KEDB) <br/>
            Knowledge Management System (SKMS) <br/>

        `,
        "training_itil_program_2_content_title_2": "ITIL TRAINING MODULE 4: Key ITIL Principles and Models",
        "training_itil_program_2_content_text_2": `
            <strong>Unit 1: Creating Value Through Services</strong> <br/>
            Balancing opposing forces <br/>
            Information management systems and tools <br/>
            <strong>Unit 2: Exploring the importance of people, processes, products and partners</strong> <br/>
            Key success factors <br/>
            Measurement methods and metrics <br/>
            3rd day <br/>
        `,
        "training_itil_program_title_3": "Day 3",
        "training_itil_program_3_content_title_1": "TRAINING MODULE 5: ITIL Process",
        "training_itil_program_3_content_text_1": `
            <strong>Unit 1: Service Strategy</strong> <br/>
            The three types of service providers <br/>
            Service Portfolio Management <br/>
            Financial Management for IT Services <br/>
            Business Relationship Management <br/> <br/>
           <strong>Unit 2: Service Design</strong> <br/>
            Service Level Management <br/>
            Design coordination <br/>
            Service catalog management <br/>
            Supplier Management <br/>
            Risk assessment and IT service continuity management <br/>
            Defining the scope of information security management <br/>
            Capacity Management<br/>
            Availability Management <br/> <br/>
            <strong>Unit 3: Service Transition</strong> <br/>
            Business Value, Asset and Configuration Management <br/>
            Explain the goals of change management <br/>
            Knowledge Management <br/>
            Transition Planning <br/>
            Management of deployments and releases <br/>
            <strong>Unit 4: Service Operation</strong> <br/>
            Define incident and problem management processes <br/>
            Executing queries <br/>
            Objectives of event and access management <br/> <br/>
            <strong>Unit 5: Continuous improvement of services</strong> <br/>
            The Seven-Step Improvement Process <br/>
            The Deming cycle (plan, do, check, act)<br/>
            Key success factors and key performance indicatorsTypes of metrics <br/> <br/>
            <strong>Unit 6: Service Management Functions and Roles</strong> <br/>
            Presentation of the different functions in the company <br/>
            Service center function <br/>
            IT operations function <br/>
            Technical management function <br/>
            Application management function <br/> <br/>
            <strong>Unit 7: Service Roles</strong> <br/>
            Responsibilities of Key Roles in Service Management <br/>
            Process owner <br/>
            Service Owner <br/>
            Process Manager <br/>
            Process Practitioner <br/>
        `,
        "training_itil_program_title_4": "Day 4",
        "training_itil_program_4_content_title_1": "",
        "training_itil_program_4_content_text_1": `
        Pass the Professional ITIL v4 certification. <br/>
        Identifiers will be provided to participants. <br/>
        Requires only a PC connected to the internet.`,

        // cobit

        "training_cobit_objectives": `
        <ul>
        <li>
        Ensure the identification and management of all IT risks
        </li>
        <li>
        Manage dependencies on external service providers,
        </li>
        <li>
        Provide IT assurance and ensure the existence of an effective and efficient internal control system.
        </li>
        <li>
        Provide advice on how best to build and structure the IT department, manage IT department performance, effectively manage IT operations, control IT costs, align IT strategy with business priorities , etc.
        </li>
        <li>
        Provide information on how to leverage the use of I&T and explains relevant board responsibilities
        </li>
        <li>
        Provide guidance on how to organize and monitor I&T performance in the business.
        </li>
        <li>
        Helps understand how to get the I&T solutions businesses need and how to best leverage new technologies to seize strategic opportunities
        </li>
        </ul>
        `,
        "training_cobit_required_level": "Minimum high school level, computer proficiency.",
        "training_cobit_concerned_public": "Project Manager, Digital Project Manager, Developer",
        "training_cobit_program_title_1": "Day 1",
        "training_cobit_program_content_title_1": "COBIT 5 Training Module 1",
        "training_cobit_program_content_text_1": `
            <strong>General introduction to COBIT®</strong>

            <ul>
            <li>
            History and Objectives of COBIT®.
            </li>
            <li>
            The scope of COBIT®.
            </li>
            <li>
            Research, development, publication and promotion of a set of IS control objectives.
            </li>
            <li>
            Persons covered by COBIT
            </li>
            <li>
            Value creation.
            </li>
            <li>
            The importance of the concept of governance
            </li>
            <li>
            The link between Sarbanes-Oxley and COBIT®.
            </li>
            <li>
            Brief presentation of COSO.
            </li>
            </ul>
        `,
        "training_cobit_program_title_2": "Day 2",
        "training_cobit_program_2_content_title_1": "COBIT 5 Training Module 2",
        "training_cobit_program_2_content_text_1": `
            <strong>Main concepts of COBIT</strong>
            <ul>
            <li>
            Process area. Process and process approach.
            </li>
            <li>
            Description of a process. Context. Flow. Control.
            </li>
            <li>
            Relationship between business objectives.
            </li>
            <li>
            Ensure that IS provides the information the business needs.
            </li>
            <li>
            Control risks and secure IT resources.
            </li>
            <li>
            Ensure the contribution of the IS to the business of the company.
            </li>
            <li>
            Key objective indicators. Key performance indicators.
            </li>
            <li>
            IT governance according to COBIT®. Maturity model.
            </li>
            </ul>
        `,
        "training_cobit_program_2_content_title_2": "COBIT 5 Training Module 3",
        "training_cobit_program_2_content_text_2": `
            <strong>Repository architecture</strong>
            <ul>
            <li>
            Study of the repository architecture.
            </li>
            <li>
            The COBIT® cube.
            </li>
            <li>
            IT processes, IT resources and business requirements.
            </li>
            <li>
            Introduction to the four domains and thirty-four processes of COBIT®.
            </li>
            <li>
            Navigate the COBIT 5 documentation.
            </li>
            </ul>

        `,
        "training_cobit_program_title_3": "Day 3",
        "training_cobit_program_3_content_title_1": "COBIT 5 Training Module 4",
        "training_cobit_program_3_content_text_1": `
            <strong>Detailed study of process areas and use of COBIT®</strong>
            <ul>
            <li>
            Process areas.
            </li>
            <li>
            Areas: planning and organization.
            </li>
            <li>
            Acquisition and installation. Delivery and support. Monitoring and evaluation.
            </li>
            <li>
            Practical use of the repository.
            </li>
            <li>
            Maturity assessment.
            </li>
            </ul>

        `,
        "training_cobit_program_title_4": "Day 4",
        "training_cobit_program_4_content_title_1": "COBIT 5 Training Module 5",
        "training_cobit_program_4_content_text_1": `
        <strong>COBIT® and other best practice frameworks</strong>
        <ul>
        <li>
        Situate COBIT® among the other good practice benchmarks.
        </li>
        <li>
        Overview of CMMi, ITIL®, Balanced ScoreCard and ISO 27001 standards
        </li>
        <li>
        Certification exam
        </li>
        </ul>
        `,

        // iso-27001

        "training_iso_27001_objectives": `
        <ul>
        <li>
        Master the concepts, practices and techniques to implement and manage an Information Security Management System;
        </li>
        <li>
        Apply the guidelines of the ISO 27001 standard in a particular case within an organization;
        </li>
        <li>
        Accompany an organization in planning, implementing, managing, monitoring and updating the ISMS;
        </li>
        <li>
        Advise an organization on ISMS practices.
        </li>
        <li>
        All the skills you learn will be applicable to organizations of all sizes and types to provide them with the following benefits:
        <ul>
        <li>
        improved information security awareness;
        </li>
        <li>
        reduced security vulnerabilities;
        </li>
        <li>
        a competitive advantage;
        </li>
        <li>
        credibility and trust;
        </li>
        <li>
        compliance with associated laws and regulations.
        </li>
        </ul>
        </li>
        </ul>
        `,
        "training_iso_27001_required_level": "Following the ISO 27001 Lead Implementer training requires the following prerequisites: being involved in information system security; knowing the fundamental principles of the ISO 27001 standard and its application.",
        "training_iso_27001_concerned_public": `
        People involved in business continuity management (risk managers, consultants, etc.); people who want to master the implementation of an ISMS; persons responsible for compliance of an ISMS; people who are part of an WSIS team.
This training is intended for the following profiles System administrator IT architect / IS System engineer Internal / external auditor Project manager / Project manager Management controller Director of Information Systems (DSI)`,
        "training_iso_27001_program_title_1": "Day 1: ",
        "training_iso_27001_program_content_title_1": `<ul>
        <li>
        Introduction to the management system and the process approach;
        </li>
        <li>
        Presentation of the ISO 27001:2013, ISO 27002:2013 and ISO 27003:2017 standards;
        </li>
        <li>
        The fundamental principles of information security;
        </li>
        <li>
        Preliminary analysis and establishment of the maturity level of an existing information security management system based on ISO 21827:2008;
        </li>
        <li>
        The drafting of the profitability and the planning of the implementation of an ISMS.
        </li>
        </ul>`,
        "training_iso_27001_program_title_2": "Day 2",
        "training_iso_27001_program_2_content_title_1": "",
        "training_iso_27001_program_2_content_text_1": `
        <ul>
        <li>
        Definition of the scope of an ISMS;
        </li>
        <li>
        Implementation of an ISMS and information security policies;
        </li>
        <li>
        Selection of risk assessment approach and methodology;
        </li>
        <li>
        Risk management: identification, analysis and treatment of risks (based on the guidelines of the ISO 27005:2011 standard);
        </li>
        <li>
        Writing the Statement of Applicability (DoA).
        </li>
        </ul>
        `,
        "training_iso_27001_program_title_3": "Day 3",
        "training_iso_27001_program_3_content_title_1": "",
        "training_iso_27001_program_3_content_text_1": `
        <ul>
        <li>
        The implementation of the document management framework;
        </li>
        <li>
        Measurement design and drafting procedures;
        </li>
        <li>
        The implementation of the measures;
        </li>
        <li>
        The development of a training, awareness and communication program on information security;
        </li>
        <li>
        Incident management (based on ISO 27035:2016);
        </li>
        <li>
        Managing the operations of an ISMS.
        </li>
        </ul>
        `,
        "training_iso_27001_program_title_4": "Days 4 and 5",
        "training_iso_27001_program_4_content_title_1": "",
        "training_iso_27001_program_4_content_text_1": `
        <ul>
        <li>
        Monitoring and follow-up of the ISMS;
        </li>
        <li>
        The development of measures, performance indicators and dashboards in accordance with ISO 27004:2016;
        </li>
        <li>
        The internal audit of the ISMS;
        </li>
        <li>
        The examination of the management of an ISMS
        </li>
        <li>
        The implementation of a continuous improvement program; preparing for an ISO 27001:2013 certification audit. Last half day:
        </li>
        <li>
        Passing the ISO 27001 Lead Implementer certification exam (duration: 3 hours).
        </li>
        </ul>
        `,

        // identity-services-microsoft-365

        "training_title_10'": "IDENTITY AND MICROSOFT 365 SERVICES",
        "training_desc_10": "The MS-203T00-A: Microsoft 365 Messaging - (Official) training course is ideal for email administrators or those aspiring to become one in a variety of cloud-based or on-premises environments. The role of those completing the certification training course revolves around enterprise deployment, management, configuration, and troubleshooting.",
        "training_duration_10": "10 days",
        "training_identity_services_microsoft_365_objectives": `
        <ul>
        <li>
        Enterprise deployment, management, configuration and troubleshooting.
        </li>
        <li>
        Manage messaging infrastructure, hybrid configuration, migration,
        </li>
        <li>
        Manage operational efficiency
        </li>
        <li>
        Configuration, design, management and security in Office 365.
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_required_level": "Minimum high school level, computer proficiency.",
        "training_identity_services_microsoft_365_concerned_public": "Server Administrators, Network Administrators",
        "training_identity_services_microsoft_365_program_title_1": "Day 1",
        "training_identity_services_microsoft_365_program_content_title_1": "Module 1: Designing your Microsoft 365 tenant",
        "training_identity_services_microsoft_365_program_content_text_1": `
        <ul>
            <li>
            Plan your tenant in Microsoft 365
            </li>
            <li>
            Plan your on-premises infrastructure for Microsoft 365
            </li>
            <li>
            Plan your identity and authentication solution for Microsoft 365
            </li>
            <li>
            Plan your support infrastructure for Microsoft 365
            </li>
            <li>
            Plan your hybrid requirements for your Microsoft 365 deployment
            </li>
            <li>
            Plan your migration to Microsoft 365
            </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_content_title_2": "Module 2: Setting up your Microsoft 365 tenant",
        "training_identity_services_microsoft_365_program_content_text_2": `
        <ul>
        <li>
        Configure your Microsoft 365 experience
        </li>
        <li>
        Gérer les comptes d'utilisateurs et les licences dans Microsoft 365
        </li>
        <li>
        Manage groups in Microsoft 365
        </li>
        <li>
        Add a custom domain in Microsoft 365
        </li>
        <li>
        Configure client connectivity to Microsoft 365
        </li>
        <li>
        Use FastTrack and partner services to support Microsoft 365
        </li>
        <li>
        Workshop: Configure your Microsoft 365 tenant
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_title_2": "Day 2",
        "training_identity_services_microsoft_365_program_2_content_title_1": "Module 3: Managing your Microsoft 365 tenant",
        "training_identity_services_microsoft_365_program_2_content_text_1": `
            <ul>
            <li>
            Configure administrative roles in Microsoft 365
            </li>
            <li>
            Manage tenant health and services in Microsoft 365
            </li>
            <li>
            Manage user-driven Microsoft 365 Apps for enterprise deployments
            </li>
            <li>
            Implement desktop telemetry
            </li>
            <li>
            Implement workplace analysis
            </li>
            <li>
            Lab: Managing Your Microsoft 365 Tenant
            </li>
            </ul>
        `,
        "training_identity_services_microsoft_365_program_title_3": "Day 3",
        "training_identity_services_microsoft_365_program_3_content_title_1": "Module 4: Exploring Microsoft 365 Platform Services",
        "training_identity_services_microsoft_365_program_3_content_text_1": `
            <ul>
            <li>
            Explore Exchange Online as a Microsoft 365 Enterprise admin
            </li>
            <li>
            Explore SharePoint Online as a Microsoft 365 Enterprise admin
            </li>
            <li>
            Explore Microsoft Teams as a Microsoft 365 Enterprise admin
            </li>
            <li>
            Explore Microsoft Power Platform as a Microsoft 365 Enterprise admin
            </li>
            <li>
            Build apps as a Microsoft 365 Enterprise admin with Power Apps
            </li>
            <li>
            Create flows as a Microsoft 365 Enterprise admin with Power Automate
            </li>
            <li>
            Create reports and dashboards as a Microsoft 365 Enterprise admin with Power BI
            </li>
            <li>
            Create chatbots as a Microsoft 365 Enterprise admin with Power Virtual Agents
            </li>
            <li>
            Lab: Office 365 Feature Review
            </li>
            </ul>
        `,
        "training_identity_services_microsoft_365_program_title_4": "Day 4",
        "training_identity_services_microsoft_365_program_4_content_title_1": "Module 5: Plan and Implement Identity Synchronization",
        "training_identity_services_microsoft_365_program_4_content_text_1": `
        <ul>
        <li>
        Explore identity synchronization
        </li>
        <li>
        Plan the implementation of Azure AD Connect
        </li>
        <li>
        Implement Azure AD Connect
        </li>
        <li>
        Manage synchronized identities
        </li>
        <li>
        Explore password management in Microsoft 365
        </li>
        <li>
        Lab: Manage Identities
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_title_5": "Day 5",
        "training_identity_services_microsoft_365_program_5_content_title_1": "Module 6: Implement apps and external access in Azure AD",
        "training_identity_services_microsoft_365_program_5_content_text_1": `
        <ul>
        <li>
        Implement applications in Azure AD
        </li>
        <li>
        Configure Azure AD Application Proxy
        </li>
        <li>
        Explore external access solutions
        </li>
        </ul>
        `,
        "please_wait": "Please wait...",
        "posted_subscriptions": "Thank you for you subscription.",
        "error_posted_subscriptions": "An error occured, please try again",
        "training_title_10": "Identity services microsoft 365",
        "products_text_descr": "In addition to developing tailor-made solutions for our customers and partners, we also set up our own solutions in order to solve certain problems encountered by the population.",
        "home_product_ovacom_descr": "OVACOM, is a commercial management solution, designed for large commercial surfaces, in order to facilitate inventory management, invoicing, customer loyalty, etc... This solution considerably facilitates the manager's day-to-day.",
        "home_product_ovaged_descr": "OVAGED, the intelligent archiving solution. Indeed, this solution allows companies to dematerialize and process their documents in an intelligent way. It is intelligent insofar as it processes all documents thanks to its module of 'integrated artificial intelligence.",
        "success_story_text_2": "Dematerialization solution development.",
        "success_story_text_3": "Implementing an API management solution.",
        "success_story_text_4": "Provision of equipment and virtualization on behalf of LBS.",
        "creative_company": `<p class="title-1 h3">
        A Creative and 
    </p>
    <p class="title-2 h3">
        Technological Company 
    </p>`,
    "what_tech_we_use": "What technologies do we use?",
    "how_receip": "Our receipe for sucessful product",
    "understand_concept": "Understand the context",
    "understand_concept_text": "First, we assess your current development process and define the business objectives you want to achieve with certain technology and then propose a solution and implementation strategy.",
    "prepare_data": "Prepare the data and Prototyping",
    "prepare_data_text": "Then we craft a simple experimental model of your proposed product so you can check how well it matches what users want through the feedback they give.",
    "scale_iterate": "Scale, iterate and test",
    "scale_iterate_text": "As soon as a minimum set of features is achieved, an MVP is released. Then each new feature is released in a fully automated deployment.",
    "Storage": "Storage",
    "Mining": "Mining",
    "Analytics": "Analytics",
    "Visualization": "Visualization",
    "Define the problem": "Define the problem",
    "Collect data": "Collect data",
    "Analyze data": "Analyze data",
    "Incorporate in business": "Incorporate in business",
    "Validate the results": "Validate the results",
    "Prepare data": "Prepare data",
    "infra_infor_support": "IT Support",
    "infra_infor_support_text": "IT support has now become essential for all companies, and in particular for SMEs, which wish to have an optimized, secure work environment, at the service of their employees but also of their customers. OVA Consulting is positioned as an external service provider of choice for all your IT concerns.",
    "infra_w_servers_text": "Get the most out of your Windows Server environment with the expertise and experience of our specialists. Evolve your datacenter infrastructure for greater efficiency and security.",
    "infra_vmware_text": "OVA Consulting specializes in virtualization with VMware and offers its customers the vSphere solution, it allows small or large companies to create powerful multi-core virtual servers and guarantees an extremely reliable virtualization layer.",
    "infra_fortinet_text": "Protect your infrastructure from end to end, without compromising performance, control and secure your internet access with FORTINET next-generation firewalls",
    "infra_material_provides": "Provision of computer equipment",
    "infra_material_fournit_text": "We accompany you from the purchase of your material, to its installation, while guaranteeing the quality.",
    "Initiations / Fundamentals": "Initiations / Fundamentals",
    "Perfectionnement / Advanced": "Further training / Advanced",
    "Days": "Days",
    "success_story_text_1": "Creation of the ESS website",
    "our_trainings": "Our trainings",
    "our_experties": "Our expertises",
    "certif_trainings": "Certification courses",
    "article_1_text": "The main advantage of technology watch is to identify or anticipate innovations by business sector. Technology watch sources constitute essential strategic information for anticipating, developing and exploiting inventions. sources are we talking about? Patent databases, scientific articles or the specialized press are all carriers of strategic information.",
    "article_1_title": "Technology Watch",
    "article_2_text": "The concept of IT governance, or IT governance, is defined as a formal framework aimed at developing information technologies in accordance with the overall objectives and strategy of the company. This process was born from the observation following: information systems generate value,",
    "article_2_title": "IT Governance",
    "article_3_text": "API management is all about centralizing control of your APIs, including analytics, access controls, monetization, development workflows, etc",
    "article_3_title": "API MANAGEMENT",
    "news_letter_submit_error": "An error occurred while submitting your data, please try again.",
    "news_letter_submit_success": "Thank you for your subscription, you will be informed of all our news",
    "filter": "Filter",
    "ask_for_a_training": "Request training",
    "korione_project_description": "The development of the internet is creating a new economy called: Digital Economy. This expression refers to information and communication technologies, the production and sale of digital products and services via the web and/or social networks. In this environment, we have found that the sale of ",
    "ess_project_description": `Our role as an investment banker is to support you throughout the realization of your projects. <br /> This is reflected in the welcome, listening, advice and expertise which are the pillars of our know-how. We have given ourselves the main missions of:`,
    "solar_project_description": "Solar Hut was founded by Emmanuel Monthe Siewe While on a tour of rural Cameroon, he saw locals struggling with energy scarcity, many children studying under the light of a cell phone or with candles. Aware of solar energy technology, he first donated solar lamps to aid the different communities he had visited. However, once he saw how rampant the energy problem was in other areas, he formed a small team and launched a pilot project. In 2019 Solar Hut was officially formed. Today Solar Hut employs over 50 people and has impacted the lives of over 5000 people. ",
    "our_methodology": "Our methodology",
    "expertise_methodology_poc": "POC",
    "expertise_methodology_poc_text": `The POC (Proof Of Concept)
    is defined through 2 to 4 workshops.
    We frame the specifications, the schedule and the budget for the sake of rigor and safety.`,
    "expertise_methodology_team": "The team",
    "expertise_methodology_team_text": "The project team is made up of a project manager and experts. On the client side, a business specialist is essential and a data manager is recommended. The availability of the business specialist is one of the success factors of a data science project.",
    "expertise_methodology_deliverables": "Deliverables",
    "expertise_methodology_deliverables_text": "Deliverables are staggered over key milestones and validated by SOWs (Statement Of Work).",
    "expertise_methodology_industrialisation": "Industrialisation",
    "expertise_methodology_industrialisation_text": "The industrialization of the model is carried out if the client wishes to scale it. Monitoring of the results of the model and maintenance allow the model to evolve in the face of data drift.",
    "expertise_software_planing": "Framing the need",
    "expertise_software_text_planing": "We plan a meet with the client and stakeholders to understand, monitor, and control the development of the software project.",
    "expertise_software_analysis": "Analysis",
    "expertise_software_analysis_text": "We define which design and tech stack decisions will lead to a software architecture that successfully addresses the desired system qualities.",
    "expertise_software_design": "Design",
    "expertise_software_design_text": "The UI designer is primarily responsible for the visual appearance of the interface, while the UI designer ensures that the navigation and actions performed by the user are consistent.",
    "expertise_software_implementation": "Implementation",
    "expertise_software_implementation_text": "We provide end-to-end solution implementation capable of addressing even the most complex issues.",
    "expertise_software_testing_integration": "Testing & integration",
    "expertise_software_testing_integration_text": "We examinine the artifacts and the behavior of the software under test by validation and verification.",
    "expertise_software_maintenance": "Maintenance",
    "expertise_software_maintenance_text": "We may be responsible for evolving, modifying and updating software to meet customer needs.",
    "expertise_design_understand": "Understand the stakeholders",
    "expertise_design_understand_text": "The objective is to anticipate and predict their reactions to your project as it develops. It is therefore important to know in depth the context from the start.",
    "expertise_design_prototype": "Prototype the product",
    "expertise_design_prototype_text": "Craft a simple experimental model of your proposed product so you can check how well it matches what users want through the feedback they give.",
    "expertise_design_design_solution": "Design the best solution",
    "expertise_design_design_solution_text": "Design the best possible solution for any given problem is not to seek perfection in our work but to create in small, iterative steps that will uncover more information for improving the design over some time.",
    "identity-services-microsoft-365": "Identity services microsoft 365",
    "expertise_method_text": "As a client of OVA consulting, you benefit from a special status within our organization.",
    "expertise_software_method_farming": "Identification of the need",
    "expertise_software_method_farming_text": "Together, let's take a moment to discuss the specifics, the context, and the needs of your project. We will talk technical, of course, but also the issues that matter to you. ",
    "expertise_software_method_planing": "Planning and Budgeting",
    "expertise_software_method_planing_text": "Secondly, we will plan the different steps to be carried out, which will provide greater visibility",
    "expertise_software_lot_realisation": "Lot Realization",
    "expertise_software_lot_realisation_text": "Depending on the size of your project, it is possible to split it into a number of separate lots. This will allow you to monitor and evaluate the work to be done.",
    "expertise_software_method_countinous_delivery": "Continuous Delivery",
    "expertise_software_method_countinous_delivery_text": "We are making continuous deliveries on a pre-production environment hosted by our servers and secure. If you already have this environment, we will do the deployment.",
    "expertise_software_preprod_delivery": "Preproduction delivery",
    "expertise_software_preprod_delivery_text": "Once the initial developments have been completed, we deliver your project and support you in its back office management if necessary.",
    "expertise_software_modifications": "Feedback and Adjustments",
    "expertise_software_modifications_text": "We process all of your feedback and deploy it to the pre-production environment so that you can validate the successful completion of the project and service delivery.",
    "expertise_software_final_delivery": "Final Delivery",
    "expertise_software_final_delivery_text": "After validation of the returns, we will deploy your application in the production environment of your choice. We can also set up your hosting server.",
    "expertise_software_support_maintainance": "Support and Maintenance",
    "expertise_software_support_maintainance_text": "During production, OVA consulting provides technical support in the event of service-related issues",
    
    // Fortinet firewall

    "training_desc_11": "Fortinet, provider of security solutions, is the world leader in unified threat management (Unified Threat Management). Its offer covers hardware and software and is aimed at companies - SMEs, large companies, organizations multisite - only to operators and communication service providers.",
    "training_duration_11": "15 days",
    "training_fortinet_firewall_objectives": `
    In this course, you will first learn how to use the basic features of FortiGate, including
    security profiles.
    In interactive labs, you'll explore firewall policies, the Security Fabric of
    Fortinet, User Authentication, SSL VPN, and How to Protect Your Network Using
    security profiles, such as IPS, antivirus, web filtering, application control, etc.
    These basics of administration will allow you to acquire a solid understanding of the implementation
    implement basic network security. <br/>
    Second, you'll learn how to use FortiGate's advanced networking and security.
    Topics covered include functionality typically applied in networks
    larger or more complex enterprise or MSSPs, such as advanced routing, mode
    transparent, redundant infrastructure, site-to-site IPsec VPN, single sign-on (SSO) and
    diagnoses.
    `,
    "training_fortinet_firewall_title_1": "FortiGate Security",
    "training_fortinet_firewall_program_content_title_1": "Course Duration",
    "training_fortinet_firewall_program_content_text_1": `
    <ul>
        <li>Course time (estimated): 20 hours</li>
        <li>Lab time (estimated): 18 hours</li>
        <li>Total course duration (estimated): 38 hours / 08 days</li>
    </ul>
    `,
    "training_fortinet_firewall_program_content_title_2": "Who should attend",
    "training_fortinet_firewall_program_content_text_2": `Network and security professionals involved in the design, implementation
    and administration of a network infrastructure using FortiGate devices should follow this
    Classes.
    This course assumes knowledge of FortiGate fundamentals. You must have
    a thorough understanding of all topics covered in the FortiGate Security course prior to
    take the FortiGate Infrastructure course`,
    "training_fortinet_firewall_level": `<ul>
    <li>Basic understanding of firewall concepts</li>
    <li>Knowledge of network protocols </li>
    </ul>`,
    "training_fortinet_firewall_concerned_public": `Network and security professionals involved in the design, implementation
    and administration of a network infrastructure using FortiGate devices should follow this
    Classes.
    This course assumes knowledge of FortiGate fundamentals. You must have
    a thorough understanding of all topics covered in the FortiGate Security course prior to
    take the FortiGate Infrastructure.`,
    "training_fortinet_firewall_program_content_title_3": `Certification`,
    "training_fortinet_firewall_program_content_text_3": `This course and the FortiGate Infrastructure course are intended to help you prepare for the exam of
    NSE 4.`,
    "training_fortinet_firewall_program_content_title_4": `Agenda`,
    "training_fortinet_firewall_program_content_text_4": `
    <ol>
    <li>Introduction and initial setup</li>
    <li>Safety fabric</li>
    <li>Firewall Rules</li>
    <li>Network Address Translation (NAT)</li>
    <li>Firewall authentication</li>
    <li>Logging and Monitoring</li>
    <li>Certificate Operations</li>
    <li>Web Filtering</li>
    <li>Application Control</li>
    <li>Antivirus</li>
    <li>Intrusion prevention and denial of service</li>
    <li>SSL VPN</li>
    </ol>
    `,
    "training_fortinet_firewall_program_title_2": `FortiGate Infrastructure`,
    "training_fortinet_firewall_program_2_content_title_1": `Course Duration`,
    "training_fortinet_firewall_program_2_content_text_1": `
    <ul>
    <li>Course time (estimated): 20 hours</li>
    <li>Lab time (estimated): 17 hours</li>
    <li>Total course duration (estimated): 37 hours / 7 days</li>
    </ul>
    `,
    "training_fortinet_firewall_program_2_content_title_2": "Certification",
    "training_fortinet_firewall_program_2_content_text_2": `This course and the FortiGate Security course are intended to help you prepare for the exam of
    NSE 4.`,
    "training_fortinet_firewall_program_2_content_title_3": `Agenda`,
    "training_fortinet_firewall_program_2_content_text_3": `
    <ol>
    <li>Routing</li>
    <li>SD-WAN Local Dispatch</li>
    <li>Virtual domains (VDOM)</li>
    <li>Layer 2 switching</li>
    <li>IPsec VPN</li>
    <li>Fortinet Single Sign-On (FSSO)</li>
    <li>High Availability (HA)</li>
    <li>Diagnostics</li>
    </ol>`,
    // cisco ccna training
    "training_desc_12": `This training prepares you for the Cisco 200-301 certification which is the reference in the
    field of computer networks.
    This course will provide you with the knowledge and skills needed to
    install, configure and operate a small to medium-sized network.`,
    "training_duration_12": "15 days",
    "training_cisco_ccna_level": `Good computer skills
    Internet skills
    Good knowledge of IP addressing and network protocols`,
    "training_cisco_ccna_objectives": `Master the installation, configuration and maintenance of a small to medium-sized network
    Master the fundamental theoretical knowledge of networks and the implementation of
    simple local networks
    Understand the fundamentals of security, networking and automation
    Know how to secure a computer network and its peripherals
    Prepare for the CCNA 200-301`,
    "training_cisco_ccna_concerned_public": `The CCNP course is ideal for network professionals such as network engineers, network operations center (NOC) technical support personnel, and help desk technicians. Additionally, this course is suitable for those involved in the execution and justification of routing protocols in enterprise networks.`,
    "training_cisco_ccna_title_1": "Module 1: Fundamental Approach to Networking",
    "training_cisco_ccna_program_content_title_1": "Basic Approach to Networking",
    "training_cisco_ccna_program_content_text_1": `
    <ul>
    <li>Exploring network functions</li>
    <li>Host-to-host communication model</li>
    <li>Cisco IOS Software Features</li>
    <li>Presentation of LANs</li>
    <li>Explanation of the TCP/IP link layer</li>
    <li>How a switch works</li>
    <li>Explanation of TCP/IP Internet Layer, IPv4 Addressing, and Subnetting</li>
    <li>TCP/IP transport layer and applications</li>
    <li>Overview of routing functions</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_2": "Module 2: Network Access",
    "training_cisco_ccna_program_2_content_title_1": "Network Access",
    "training_cisco_ccna_program_2_content_text_1": `
    <ul>
    <li>Configuring a Cisco Router</li>
    <li>Features of the packet delivery process</li>
    <li>Troubleshooting a Network</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_3": `Module 3: IP Connectivity`,
    "training_cisco_ccna_program_3_content_title_1": "IP Connectivity",
    "training_cisco_ccna_program_3_content_text_1": `
    <ul>
    <li>Explanation of IPv6</li>
    <li>Configuring static routing</li>
    <li>Setting up VLANs and trunks</li>
    <li>Explanation of routing between VLANs</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_4": "Module 4: IP Services",
    "training_cisco_ccna_program_4_content_title_1": "IP Services",
    "training_cisco_ccna_program_4_content_text_1": `
    <ul>
    <li>Overview of OSPF Dynamic Routing Protocol</li>
    <li>Creating a dial-up network with redundant links</li>
    <li>Improve redundant switched fabric with EtherChannel</li>
    <li>Configuring Layer 3 Redundancy with HSRP</li>
    <li>Exploring WAN technologies</li>
    <li>Explanation of ACL accesses</li>
    <li>Enable internet connectivity</li>
    <li>Overview of IT Quality of Service (QoS)</li>
    <li>Wireless Networking Basics</li>
    <li>Presentation of computer architectures and virtualization</li>
    <li>Explanation of the evolution of smart grids</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_5": "Module 5: Security Fundamentals",
    "training_cisco_ccna_program_5_content_title_1": "Security Fundamentals",
    "training_cisco_ccna_program_5_content_text_1": `
    <ul>
    <li>Explanation on system monitoring</li>
    <li>Cisco Device Management</li>
    <li>Identify network security threats</li>
    <li>Overview of Threat Defense Technologies</li>
    <li>Securing administrative access</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_6": "Module 6: Automations and Programmability",
    "training_cisco_ccna_program_6_content_title_1": "Automations and Programmability",
    "training_cisco_ccna_program_6_content_text_1": `
    <ul>
    <li>Installation of reinforcement devices</li>
    </ul>`,
    "are_you_company": "Are you a company?",
    "only_entr_can_subscribe": "This option is only for companies.",
    "yes": "Yes",
    "no": "No",
    "custom_training_enter_your_informations": "Enter your information to order your personalized training",
    "home_contact_training_title": "Title of the desired training",
    "subscription_not_entreprise": "For your personalized offer, please contact us at the following number: (+237) 6 96 42 77 03, or by email at the following email address: contact@ova-consulting.fr",
    "obtain_estimate": "Get a quote",
    "obtain_estimation_success": "Your information has been submitted, we will contact you as soon as possible.",
    "Network expert and Azure Specialist": "Network expert and azure specialist.",
    "OVACOM": "OVACOM",
    "TCHOKO": "TCHOKO",
    "OVASSURE": "OVASSURE",
    "OVAGED": "OVAGED",
    "Success stories": "Success stories",
    "view all": "view all",
    "EHS": "EHS",
    "SNCF": "SNCF",
    "LBS": "LBS",
    "footer_slogan": "Creativity and innovation remain our motivation",
    "footer_privacy_policy_page_title": "PRIVACY AND PERSONAL DATA PROTECTION POLICY",
    // Privacy policy
    "privacy_policy_date": "Effective date: August 2022",
    "privacy_policy_text_general_statement_title": "1. General Statement",
    "privacy_policy_text_general_statement_text": `We take your privacy very seriously and are committed to protecting it. We consider that you should be able to easily know what personal data we collect and use, and understand your rights in relation to it.
    This privacy policy (the “Privacy Policy”) explains our policies and practices regarding how we collect, use and disclose the personal data we collect on our digital platforms or during our events. We recommend that you read this Privacy Policy carefully as it contains important information about your personal data. You can print the full text of our Privacy Policy by clicking here. Do not hesitate to contact us if you have any questions or comments about our
    Privacy Policy (see “How to Contact Us” below).
    `,
    "privacy_policy_text_who_we_are_title": "2. Who are we?",
    "privacy_policy_text_who_we_are_text": `
        The terms "OVA CONSULTING SARL", "we", "us" and "our" refer to OVA CONSULTING SARL as the controller of your personal data, unless otherwise specified in our Privacy Policy.
        OVA CONSULTING SARL is a company created by OVA CORPORATE and governed by Cameroonian sponsorship law, and its successive modifications. Our head office is located at Bonamoussadi Carrefour Masson face express union.`,
    "privacy_policy_text_which_data_title": "3. What personal data do we collect and how is it collected?",
    "privacy_policy_text_which_data_text": `
        Personal data is information relating to an identified or identifiable natural person. They include, for example, a person's name, address and gender. We may collect personal data directly from you (for example when you apply for one of our programs) or indirectly (for example from your electronic devices which interact with our website (see our "Cookies Policy"). " below).
        <h3 class="ml-2">3.1 Data that you provide directly to us</h3>
        <p class="ml-4">
            You can provide us with data:
            <ul>
            <li>When you apply for one of our programs;</li>
            <li>When you subscribe to our newsletter via our website;</li>
            <li>When you attend one of our events;</li>
            <li>When you contact us via our contact module on our website;</li>
            <li>When you access the advertising space of our website;</li>
            </ul>
            Depending on what you provide to us, such information may include:
            <ul>
            <li>Your identity (including your first name, last name, gender, image);</li>
            <li>Your contact details (including your postal address(es), email(s), telephone number(s);</li>
            <li>Your personal status (including your title);</li>
            <li>Other information that you may provide by filling in forms or by corresponding with us (including your comments or other communications with us).</li>
            </ul>
            When you apply for one of our programs, this information may include:
            <ul>
            <li>Your professional and academic status (profession, title, etc.);</li>
            <li>Your financial situation (in the case of the award of scholarships);</li>
            <li>Some payment data (such as bank details for the purpose of settling benefits, scholarship or support payments);</li>
            </ul>
            We will notify you when your information is needed to process your request, to respond to your requests, or to allow you to participate in one of our programs. Failure to provide this information may delay or make it impossible to process your request.
            We strive to ensure that the personal data we hold is accurate at all times. We therefore encourage you to update your data in case changes occur. We may also ask you to update your data from time to time. We recommend that you provide only the data requested or necessary for your request, and not to communicate to us sensitive data relating to race, ethnic origin, political opinions, religious or philosophical beliefs, and data concerning health, sex life or sexual orientation.
        </p>
        <h3 class="ml-2">3.2 Data collected indirectly.</h3>
        <p class="ml-4">
        We may collect data when you use our site, including your IP address or other browsing data (including browser, operating system, device model), through cookies or technologies. similar placed on your device. Some cookies are necessary for the proper functioning of our site and others are used for analytical purposes which help us to provide you with more personalized services and a better digital experience. For more information about cookies and how to change your preferences, please read our Cookie Policy. We may also collect data about you from third parties, including a spouse who contacts us on your behalf or that of your friends who provide us with your contact details to invite you to events that may be of interest to you. If you provide us with personal data about someone else, you must ensure that you have the right to disclose this data to us and that, without us taking any other measures required by the data protection legislation data, we may collect, use and disclose such data for the purposes described in our Privacy Policy. For example, you must ensure that the data subject is aware of the various subjects set out in our Privacy Policy. The individual must also give the consents set out in our Privacy Policy regarding how we process their data at this time.`,
        "privacy_policy_text_why_do_we_title": "4. Why do we collect your personal data and how do we use it?",
    "privacy_policy_text_text_why_do_we_text": `
    We collect and use your personal data in reliance on one or more of the following legal bases:
    <ul>
    <li>We have obtained your prior consent (for example when you subscribe to our newsletter). Please note that with this particular legal basis, you have the right to revoke your consent at any time (see below “What are your rights regarding your personal data?”);</li>
    <li>Processing is necessary within the framework of a contract between OVA CONSULTING SARL and you;</li>
    <li>We have a legitimate interest in carrying out the processing and that legitimate interest is not overridden by your interests, fundamental rights or freedoms;</li>
    <li>
    We must process your personal data in order to comply with the laws and regulations in force.
        Depending on the context, we may use your personal data to:\
        <ul>
        <li>Processing your application for one of our programs;</li>
        <li>Perform checks to identify yourself and verify your identity;</li>
        <li>Esend a newsletter (with your prior consent) or invitations to one of our events;</li>
        <li>Respond to your questions, requests and suggestions;</li>
        <li>Manage the events you registered for and/or attended;</li>
        <li>Monitoring and improving our Digital Platforms;</li>
        <li>Perform statistical analyses;</li>
        <li>Provide information to regulatory agencies when required by law.</li>
        </ul>
    </li>

    </ul>
    `,
    "privacy_policy_text_how_long_title": "5. How long do we keep your personal data?",
    "privacy_policy_text_how_long_text": `Your personal data is processed for the period necessary for the purposes for which it was collected, to comply with legal and regulatory obligations and for the duration of any period necessary for the establishment, exercise or the defense of legal claims. <br/>
    In order to determine the most appropriate retention periods for your personal data, we have specifically taken into account the quantity, nature and sensitivity of your personal data, the reasons for which we collected your personal data , as well as applicable legal requirements. `,
    "privacy_policy_text_how_we_transfert_title": "6. How do we disclose and transfer your personal data?",
    "privacy_policy_text_how_we_transfert_text": `We may only disclose your personal data to the parties named below and for the following reasons:
    <ul>
    <li>We disclose your personal data to employees of OVA CONSULTING SARL who need to have access to your personal data and who are authorized to process it for the aforementioned purposes and who undertake to respect its confidentiality. .</li>
    <li>We may disclose your personal data to OVA, communications, internal audit and IT management companies for the purposes set out in our Privacy Policy with your consent, to send you communications promotions concerning events (for this purpose, you can revoke your consent at any time. See the section “What are your rights regarding your personal data?” below).</li>
    <li>We may also communicate personal data to third parties acting on behalf of OVA CONSULTING SARL.</li>
    </ul>`,
    "privacy_policy_text_how_we_protect_title": "7. How do we protect your personal data?",
    "privacy_policy_text_how_we_protect_text": `All your personal data is strictly confidential and will only be accessible if necessary, only by the staff of OVA CONSULTING SARL.
    OVA CONSULTING SARL has implemented security measures to protect your personal data against unauthorized access and use. We follow appropriate security procedures in the storage and disclosure of your personal data in order to prevent unauthorized access by third parties and to avoid the accidental loss of your data. We limit access to your personal data to people who really need to access it for professional reasons. Persons who access your data are subject to a duty of confidentiality with regard to OVA CONSULTING SARL.
    We also have procedures in place to deal with any suspected data security breaches. We will notify you and any relevant supervisory authority of a suspected data security breach where we are required by law to do so. We also require those to whom we provide your personal data to comply with the above. Unfortunately, the transmission of information via the Internet is not completely secure. We cannot therefore guarantee the security of your personal data transmitted to us via the Internet. Any such transmission is at your own risk and you acknowledge and agree that we are not responsible for any unauthorized use, distribution, damage or destruction of your data, except to the extent required by law to endorse such responsibility. Once we have received your personal data, we will apply the aforementioned security measures.`,
    "privacy_policy_text_your_right_title": `8. What are your rights over your personal data?`,
    "privacy_policy_text_your_right_text": `In accordance with applicable data protection law, you may at any time request access, rectification, erasure and portability of your personal data, or restrict or object to its processing . A summary of these rights is set out below: Your right of access: the right to receive a copy of your personal data. Your right to rectification: the right to ask us to rectify any errors in your data or to complete it.
    Your right to be forgotten: the right to ask us to delete your personal data, in certain situations.
    Your right to restriction of processing: the right to ask us to restrict the processing of your personal data, in certain circumstances, for example if you dispute the accuracy of the data. Your right to data portability: the right to receive the personal data you have provided to us, in a structured, commonly used and machine-readable format and/or to transmit this data to a third party, in certain situations.
    Your right to object to processing: the right to object:
    <ul>
    <li>at any time during the processing of your personal data;</li>
    <li>In certain other situations, to further our processing of your personal data, for example processing carried out in reliance on our legitimate interests.</li>
    </ul>
    When the processing of your personal data is based on your consent, you can at any time decide to withdraw your consent to the processing of your personal data, in particular when you no longer wish to receive the newsletter to which you have subscribed. If your consent is withdrawn, this does not prevent us from processing your personal data based on other legal bases, where applicable, the legitimate interest of OVA CONSULTING SARL.
    If you no longer wish to receive our information by email, we remind you that you can revoke your consent at any time directly from the unsubscribe link included in each electronic message that we send you. If you do so, we will promptly update our databases and take all reasonable steps to respond to your request as quickly as possible. You also have the right to lodge a complaint with your local data protection authority in the event of an alleged breach of the data protection rules applicable to you.
    To exercise any of these rights, please contact us using the contact details below (see “How to contact us”).
    Please note that if you exercise any of the rights mentioned above, you will be asked to let us know which right you wish to exercise and to provide us with certain information (copy of an identity card, passport or other 'legally recognized identity) for identification purposes to process your request and protect you against fraudulent requests from third parties.`,
    "privacy_policy_text_how_to_contact_us_title": `9. How to contact us?`,
    "privacy_policy_text_how_to_contact_us_text": `For any question relating to your data, to revoke your consent, to ask general questions or to file a complaint, please contact our teams:
    <ul>
    <li>By mail: BP 3579 </li>
    <li>On the OVA CONSULTING SARL website: via the contact form provided for this purpose
    (https://ova-consulting.fr/)</li>
    </ul>
    For questions relating specifically to e-mails, we remind you that you can unsubscribe at any time directly via the "unsubscribe" link contained in all the e-mails we send you. If you have any questions or concerns about our Privacy Policy or data processing, you can contact the group data protection officer at the following address: privacy@ova-consulting.fr.`,
    "privacy_policy_text_update_title": `10. Changes to our Privacy Policy`,
    "privacy_policy_text_update_text": `Our privacy policy reflects our current practices and may be changed and updated from time to time. When we post changes to our Privacy Policy, we modify the “August 31” at the top of this document to indicate when such changes become effective. If we change our Privacy Policy in a material way, we will notify you by means of a notice of change at the beginning of it and on the home page of the website “https://ova-consulting .fr/”.`,
    "expertise_software_user_training": "User Training",
    "expertise_software_user_training_text": "After the final delivery of your product, we take care of the training of all users, in order to allow a smooth and autonomous use."
    }
}

export default en