import CookieConsent, { Cookies } from "react-cookie-consent";import { useTranslation } from "react-i18next";


 
export default function () {

    const { t } = useTranslation();

    return(
        <CookieConsent
            location="bottom"
            buttonText={t('cookies_accept')}
            declineButtonText={t('cookies_decline')}
            enableDeclineButton={true}
            cookieName="ovaConsulting"
            style={{ background: "#161d41" }}
            buttonStyle={{ background: "#009289", fontSize: "13px", color: '#fff' }}
            expires={150}
            contentStyle={{color: '#fff'}}
        >
            {t('cookies_text')}
            
        </CookieConsent>
    )
}