import { ThunkAction } from "redux-thunk";
import { StoreArticle } from "../../types/storeCard";
import { addToCartFailed, addToCartRequest, addToCartSuccess, loadArticleFailed, loadArticleRequest, loadArticlesFailed, loadArticlesRequest, loadArticlesSuccess, loadArticleSuccess, removeFromCartFailed, removeFromCartRequest, removeFromCartSuccess } from "../actions/storeArticles";
import { ApplicationAction, ApplicationState } from "../types";



type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>



export const listArticlesEffect = (): Effect => async (
    dispatch
) => {

    dispatch(loadArticlesRequest());

    fetch('https://fakestoreapi.com/products')
            .then(res=>res.json())
            .then(json=>{
                dispatch(
                    loadArticlesSuccess(json)
                )
            }).catch(error => {
                dispatch(
                    loadArticlesFailed()
                )
            })
}


export const addToCartEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        addToCartRequest()
    )

    try {
        dispatch(
            addToCartSuccess(article)
        )
        enqueueSnackbar("Item added to cart", {variant: 'success'});
    } catch (error) {
        dispatch(
            addToCartFailed()
        )
    }
}



export const removeFromCartEffect = (article: StoreArticle, enqueueSnackbar: Function): Effect => async (dispatch) => {
    dispatch(
        removeFromCartRequest()
    )

    try {
        dispatch(
            removeFromCartSuccess(article)
        )
        enqueueSnackbar("Item Removed from cart", {variant: 'success'});
    } catch (error) {
        dispatch(
            removeFromCartFailed()
        )
    }
}

export const loadArticleEffect = (id: string | number, enqueueSnackbar: Function): Effect => async (
    dispatch
) => {

    dispatch(loadArticleRequest());

    fetch(`https://fakestoreapi.com/products/${id}`)
            .then(res=>res.json())
            .then(json=>{
                dispatch(
                    loadArticleSuccess(json)
                )
            }).catch(error => {
                dispatch(
                    loadArticleFailed()
                );
                enqueueSnackbar("Loading article Error", {variant: 'error'})
            })
}