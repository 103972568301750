
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const responsive = {
    "0": {
        "items": 1
    },
    "768": {
        "items": 2
    },
    "992": {
        "items": 2
    },
    "1200": {
        "items": 2
    }
}


export default function () {



    return (
        <>
        {/* <!--Testimonial One Start--> */}
        <section className="testimonial-one">
            <div className="testimonial-one-shape-2 float-bob-y">
                <img src="assets/images/shapes/testimonial-one-shape-2.png" alt=""/>
            </div>
            <div className="testimonial-one-shape-3 float-bob-y">
                <img src="assets/images/shapes/testimonial-one-shape-3.png" alt=""/>
            </div>
            <div className="container">
                <div className="testimonial-one__top">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="testimonial-one__top-left">
                                <div className="section-title text-left">
                                    <div className="section-sub-title-box">
                                        <p className="section-sub-title">testimonials</p>
                                        <div className="section-title-shape-1">
                                            <img src="assets/images/shapes/section-title-shape-1.png" alt=""/>
                                        </div>
                                        <div className="section-title-shape-2">
                                            <img src="assets/images/shapes/section-title-shape-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <h2 className="section-title__title">What our customers are <br/> talking about</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="testimonial-one__top-right">
                                <p className="testimonial-one__top-text">Pellentesque habitant morbi tristique senectus
                                    netus et malesuada fames ac turp egestas. Aliquam viverra arcu. Donec aliquet
                                    blandit enim feugiat mattis.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial-one__bottom">
                    <div className="row">
                        <div className="col-xl-12">
                            <OwlCarousel 
                                className='owl-theme thm-owl__carousel testimonial-one__carousel' 
                                loop 
                                margin={30} 
                                autoplay
                                smartSpeed={500}
                                autoplayTimeout={10000}
                                responsive={responsive}
                                navText={["<span className=\"fa fa-angle-left\"></span>","<span className=\"fa fa-angle-right\"></span>"]}
                            >
                                
                                <div className="item">
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-inner">
                                            <div className="testimonial-one__shape-1">
                                                <img src="assets/images/shapes/testimonial-one-shape-1.png" alt=""/>
                                            </div>
                                            <div className="testimonial-one__client-info">
                                                <div className="testimonial-one__client-img-box">
                                                    <img src="assets/images/testimonial/testimonial-1-1.jpg" alt=""/>
                                                    <div className="testimonial-one__quote">
                                                        <img src="assets/images/testimonial/testimonial-1-quote.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-one__client-content">
                                                    <div className="testimonial-one__client-review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <div className="testimonial-one__client-details">
                                                        <h3 className="testimonial-one__client-name">Smith Vectoria</h3>
                                                        <p className="testimonial-one__client-sub-title">director</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="testimonial-one__text">Pellentesque habitant morbi tristique
                                                senectus netus et malesuada fames ac turp egestas. Aliquam viverra arcu.
                                                Donec aliquet blandit enim feugiat mattis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-inner">
                                            <div className="testimonial-one__shape-1">
                                                <img src="assets/images/shapes/testimonial-one-shape-1.png" alt=""/>
                                            </div>
                                            <div className="testimonial-one__client-info">
                                                <div className="testimonial-one__client-img-box">
                                                    <img src="assets/images/testimonial/testimonial-1-2.jpg" alt=""/>
                                                    <div className="testimonial-one__quote">
                                                        <img src="assets/images/testimonial/testimonial-1-quote.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-one__client-content">
                                                    <div className="testimonial-one__client-review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <div className="testimonial-one__client-details">
                                                        <h3 className="testimonial-one__client-name">Christine Eve</h3>
                                                        <p className="testimonial-one__client-sub-title">director</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="testimonial-one__text">Pellentesque habitant morbi tristique
                                                senectus netus et malesuada fames ac turp egestas. Aliquam viverra arcu.
                                                Donec aliquet blandit enim feugiat mattis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-inner">
                                            <div className="testimonial-one__shape-1">
                                                <img src="assets/images/shapes/testimonial-one-shape-1.png" alt=""/>
                                            </div>
                                            <div className="testimonial-one__client-info">
                                                <div className="testimonial-one__client-img-box">
                                                    <img src="assets/images/testimonial/testimonial-1-3.jpg" alt=""/>
                                                    <div className="testimonial-one__quote">
                                                        <img src="assets/images/testimonial/testimonial-1-quote.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-one__client-content">
                                                    <div className="testimonial-one__client-review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <div className="testimonial-one__client-details">
                                                        <h3 className="testimonial-one__client-name">Hallen Smith</h3>
                                                        <p className="testimonial-one__client-sub-title">director</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="testimonial-one__text">Pellentesque habitant morbi tristique
                                                senectus netus et malesuada fames ac turp egestas. Aliquam viverra arcu.
                                                Donec aliquet blandit enim feugiat mattis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__single-inner">
                                            <div className="testimonial-one__shape-1">
                                                <img src="assets/images/shapes/testimonial-one-shape-1.png" alt=""/>
                                            </div>
                                            <div className="testimonial-one__client-info">
                                                <div className="testimonial-one__client-img-box">
                                                    <img src="assets/images/testimonial/testimonial-1-4.jpg" alt=""/>
                                                    <div className="testimonial-one__quote">
                                                        <img src="assets/images/testimonial/testimonial-1-quote.png"
                                                            alt=""/>
                                                    </div>
                                                </div>
                                                <div className="testimonial-one__client-content">
                                                    <div className="testimonial-one__client-review">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <div className="testimonial-one__client-details">
                                                        <h3 className="testimonial-one__client-name">Kevin Martin</h3>
                                                        <p className="testimonial-one__client-sub-title">director</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="testimonial-one__text">Pellentesque habitant morbi tristique
                                                senectus netus et malesuada fames ac turp egestas. Aliquam viverra arcu.
                                                Donec aliquet blandit enim feugiat mattis.</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Testimonial One End--> */}
        </>
    )
}