import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import SecureLS from 'secure-ls';
import persistStore from 'redux-phoenix';
import { enableES5 } from 'immer';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter,
} from "react-router-dom";
import AppRoutes from './components/routes/Routes';
import CookiesConsent from './components/cookiesConsent/CookiesConsent';
import './components/locales';
import { ApplicationState } from './store/types';
import { AnyAction, Store } from 'redux';
import store from './store';

const ls = new SecureLS({ encodingType: 'aes' });


enableES5();

// @ts-ignore
ls.setItem = ls.set;
// @ts-ignore
ls.getItem = ls.get;


persistStore(store, {
  whitelist: ['users'],
  blacklist: ['users.loading.users'],
  storage: ls,
  serialize: (data: any) => data,
  deserialize: (data: any) => data,
  throttle: 5 * 1000 // wait 5 seconds before save
}).then((store: Store<ApplicationState, AnyAction>) =>
  {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <CookiesProvider>
          <App store={store} />
          <CookiesConsent />
        </CookiesProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
