import * as React from 'react';
import { makeStyles } from '@mui/styles';
import SecureLS from 'secure-ls';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from '../../utils/utils';
import HeaderUtil from './components/utils/HeaderUtil';
import { Link, useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../store/types';
import { InternationalisationTypes } from '../../../store/types/InternationalizationTypes';
import { changeLanguage } from '../../../store/actions';
import $ from 'jquery'

var localStorage = new SecureLS({ encodingType: 'aes' });


const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
  },
  myOvButton: {
    background: 'transparent',
    width: 'auto',
    padding: 0,
    borderRadius: 28,
    '& hover': {
        border: 0,
        background: '#51b4b0'
    }
  }
});


export default function Header() {
  const classNames = useStyles();
  const { i18n, t } = useTranslation();
  const [lang, setLang] = React.useState("");

  const { recruitments: {
    loading: {
      loading_recruitment_request,
      loading_recruitment_success
    },
    recruitments
  } } = useSelector((state: ApplicationState) => state)
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeLang = (lng: InternationalisationTypes) => {
    if (typeof lng !== 'undefined') {
      i18n.changeLanguage(lng.toLowerCase());
      localStorage.set('language', lng.toLowerCase());
      setLang(lng.toLowerCase());
      dispatch(
        changeLanguage(lng)
      );
    }
  };

  React.useEffect(() => {

    const curr_lang = localStorage.get('language');
    setLang(curr_lang);
    dispatch(
      changeLanguage(curr_lang)
    )

    // detect menu click
    window.addEventListener('click', function(e){   
      // //console.log("document.querySelector('.js-open') ===>", document.querySelector('.js-open'))

      // If drawer is already visible
      // @ts-ignore
      // alert(!document.querySelector('.menu-toggle')?.contains(e.target))
      // @ts-ignore
      if(!document.querySelector('.menu-toggle')?.contains(e.target)){
        // @ts-ignore
        if (document.querySelector('.js-open')?.contains(e.target)){
          // Clicked in box
          // //console.log("Clicked in box")
        } else{
          // Clicked outside the box
          document.querySelector('.main-menu')?.classList.remove('js-open')
        }
      }
    });

    HeaderUtil();

    // Scroll event detector

    // @ts-ignore
    $(window).on('scroll',function() {
      var aTop = $('.main-header').height();
      // @ts-ignore
      console.log($(this).scrollTop(), aTop)
      // @ts-ignore
      if($(this).scrollTop()>=aTop){
          // alert('header just passed.');

        // @ts-ignore
        $( "header.main-header" ).addClass("js-scrolled");
          // instead of alert you can use to show your ad
          // something like $('#footAd').slideup();
      } else {

        // @ts-ignore
        $( "header.main-header" ).removeClass("js-scrolled");
      }
    });

    // @ts-ignore
    $(".menu-toggle").on('click', function() {
      
      $(".main-menu").toggleClass('js-open');
    })
    $(".menu-link").on('click', function() {

      $(".main-menu").toggleClass('js-open');
    })

    
  }, [])

    
  return (
    <div className='main-header clearfix'>
      {/* <div className="main-header__top">
        <div className="container">
          <div className="main-header__top-inner">
            <div className="main-header__top-address">
              <ul className="list-unstyled main-header__top-address-list">
                <li>
                  <i className="icon">
                      <span className="icon-pin"></span>
                  </i>
                  <div className="text">
                      <p>30 Commercial Road Fratton, Australia</p>
                  </div>
                </li>
                <li>
                  <i className="icon">
                      <span className="icon-email"></span>
                  </i>
                  <div className="text">
                      <p><a href="mailto:needhelp@company.com">needhelp@company.com</a></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="main-header__top-right">
                <div className="main-header__top-menu-box">
                    <ul className="list-unstyled main-header__top-menu">
                        <li><a href="about.html">Make a Claim</a></li>
                        <li><a href="faq.html"> FAQs</a></li>
                        <li><a href="about.html">About</a></li>
                    </ul>
                </div>
                <div className="main-header__top-social-box">
                    <div className="main-header__top-social">
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-facebook"></i></a>
                        <a href="#"><i className="fab fa-pinterest-p"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div> */}
      <header className="main-header">
        <div className="menu-toggle">
          <div className="toggle-bar"></div>
          <div className="toggle-bar"></div>
          <div className="toggle-bar"></div>
        </div>
        <div className="main-menu">
          <div className="main-menu-bg"></div>
          <div className="menu-toggle">
            {t('header_close')}
          </div>
          <div className="menu-links">
            <Link to="/" className='menu-link'>{t('home')}</Link>
            <Link to="/ova-store/about" className='menu-link'>{t('home_about')}</Link>
            <Link to="/ova-store/store/equipments" className='menu-link'>{"Store"}</Link>
            <Link to="/ova-store/hosting" className='menu-link'>{"Ova cloud"}</Link>
            <Link to="/ova-store/contact" className='menu-link'>Contact</Link>
          </div>
          <div className="menu-bg"></div>
          <div className="menu-footer">
            <div className="logo"></div>
            <div className="socials">
              <div className="instagram">
                <Link to="http://instagram.com"></Link>
              </div>
              <div className="twitter">
                <Link to="http://twitter.com"></Link>
              </div>
              <div className="facebook">
                <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
              </div>
              <div className="behance">
                <Link to="http://behance.com"></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="company" onClick={() => redirect("https://ova-consulting.bitrix24.fr/", navigate, true)} style={{backgroundImage: "none"}}></div>
        <div className="logo" onClick={() => redirect('/', navigate)} style={{cursor: 'pointer'}}></div>
        <div className="icon-menu">
          <Link onClick={() => redirect("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15919.06213976182!2d9.7107273!3d4.0681364!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x552f7d965911004!2sOva%20Consulting%20SARL!5e0!3m2!1sfr!2scm!4v1661790292344!5m2!1sfr!2scm", navigate, true)} to="" className="position">
            <div className="text">Maps</div>
          </Link>
          <Link to="tel:696427703" className="phone">
            <div className="text">696427703</div>
          </Link>
          {/* <div className="recruitment">
            <Link to="/recruitment" style={{textDecoration: 'none'}}> 
              <div className="text">{t('header_recruitment')}</div>
              {
                loading_recruitment_request &&
                <div className="notifications blink">
                  <span className=''></span>
                </div>
              }
              {
                loading_recruitment_success &&
                <div className="notifications">
                  <span className='text-white'>{recruitments.count}</span>
                </div>
              }
            </Link>
          </div> */}
        </div>
        <div className="language-switcher">
          {
            lang === "en" ?
              <div className={lang === "en" ? "lang is-active" : "lang"} onClick={ () => handleChangeLang(InternationalisationTypes.FR) } >FR</div>
              :

              <div className={lang === "fr" ? "lang is-active" : "lang"} onClick={ () => handleChangeLang(InternationalisationTypes.EN) }>EN</div>
          }
        </div>
      </header>
    </div>
  );
}
