import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { redirect } from "../utils/utils";

interface BackSectionProps {
    url: string
}

export default function BackSection (props: BackSectionProps) {

    const { url } = props;
    const navigate = useNavigate();

    return (
        <Grid className="d-flex" mb={3}>
            <button 
                className="thm-btn main-slider__btn mr-auto border-none"
                onClick={() => redirect(url, navigate)} 
            >
                <span>
                    <ArrowBackIosOutlinedIcon color='primary' />
                </span>
                Back
            </button>
        </Grid>
    );
}