import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { addToCartEffect, removeFromCartEffect } from "../../store/effects/storeArticles";
import { useSnackbar } from "notistack";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationState } from "../../store/types";
import { StoreArticle } from "../../types/storeCard";

interface Props {
    product: StoreArticle
}


export default function StoreCard (props: Props) {

    const { product } = props;
    const {cart} = useSelector((state: ApplicationState) => state.shop);
    const {store} = useParams();

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const handleAddToCart = (article: StoreArticle) => {
        dispatch(
            addToCartEffect(article, enqueueSnackbar)
        )
    }

    const handleRemoveFromCart = (article: StoreArticle) => {
        dispatch(
            removeFromCartEffect(article, enqueueSnackbar)
        )
    }

    const handleSelectArticle = (article: StoreArticle) => {
        navigate(`/ova-store/store/${store}/${article.id}`); 
    }

    return(
        <Grid item md={4} xs={12} key={product.id}>
            <Card sx={{ borderRadius: "1rem" }} className="product-card">
                {/* <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        R
                    </Avatar>
                    }
                    action={ 
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                    }
                    title="Shrimp and Chorizo Paella"
                    subheader="September 14, 2016"
                /> */}
                <CardContent className="article-card-content">
                    <Typography variant="body2" color="text.secondary" style={{textTransform: "uppercase"}}>
                        {product.category}
                    </Typography>
                    <Typography variant="h5" className="article-card-title">
                        {product.title}
                    </Typography>
                    <Typography variant="h5" color="text.secondary" className="article-card-title">
                        $ {product.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="cart-article-description">
                        {product.description}
                    </Typography>
                </CardContent>
                <div className="product-actions">
                    <CardActions disableSpacing>
                        <IconButton 
                            onClick={() => handleSelectArticle(product)}
                            sx={{marginLeft: 'auto'}} 
                            aria-label="remove from cart"
                        >
                            <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                        { _.filter(cart, {id: product.id}).length > 0 ?
                            <IconButton 
                                onClick={() => handleRemoveFromCart(product)}
                                sx={{marginRight: 'auto'}} 
                                aria-label="remove from cart">
                                    <ShoppingCartIcon color="primary" />
                            </IconButton> : 
                            <IconButton 
                                onClick={() => handleAddToCart(product)}
                                sx={{marginRight: 'auto'}}
                                aria-label="add to cart"><AddShoppingCartIcon />
                            </IconButton>
                        }
                        {/* <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton> */}
                    </CardActions>
                </div>
                <CardMedia
                    component="img"
                    height="250"
                    image={product.image}
                    alt="Paella dish"
                />
            </Card>
        </Grid>
    )
    
}