import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { listRecruitments } from '../../store/effects/recruitment';

 

export default function () {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            listRecruitments()
        )
    }, [])

    return (
        <footer id="footer" className="main-footer p-t-2"  style={{padding: "0px", paddingBottom: "1rem"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-12">

                        <div className="logo" style={{textAlign: "left"}}>
                            <img src="/logo_white.png" alt="logo" />
                        </div>
                        <div style={{textAlign: "justify", marginTop: "1rem"}} >
                            <p className="footer-text-description" >
                                {t('text_footer_description')}
                            </p>
                        </div>
                        
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">

                        <div style={{textAlign: "justify", marginTop: "1rem"}} >
                            <p className="footer-menu-title" >
                                {t('footer_company')}
                            </p>
                        </div>
                        <div style={{textAlign: "justify", marginTop: "1rem"}} >
                            <p className="footer-text-description" >
                                <Link to="/about" className='footer-link'>{t('home_about')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/services-produits" className='footer-link'>{t('home_our_services')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/our-methods" className='footer-link'>{t('home_our_method')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/#partners" className='footer-link'>{t('home_our_partners')}</Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">

                        <div style={{textAlign: "justify", marginTop: "1rem"}} >
                            <p className="footer-menu-title" >
                                {t("our_strategy")}
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/our-strategy#maintenance" className='footer-link'>{t('how_it_work_1')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/our-strategy#architect" className='footer-link'>{t('how_it_work_2')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/our-strategy#budget" className='footer-link'>{t('how_it_work_3')}</Link>
                            </p>
                            <p className="footer-text-description" >
                                <Link to="/our-strategy#cloud" className='footer-link'>{t('how_it_work_4')}</Link>
                            </p>
                            {/* <p cl */}
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-12">

                        <div style={{textAlign: "justify", marginTop: "1rem"}} >

                            <div style={{textAlign: "justify", marginTop: "1rem"}} >
                                <p className="footer-menu-title" >
                                    {t('home_our_products')}
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/services-produits#ovassure" className='footer-link'>OVASSURE</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/services-produits#tchoko" className='footer-link'>TCHOKO</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/services-produits#ovaged" className='footer-link'>OVAGED</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/services-produits#ovacom" className='footer-link'>OVACOM</Link>
                                </p>
                            </div>
                            
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-12">

                        <div style={{textAlign: "justify", marginTop: "1rem"}} >

                            {/* <div style={{textAlign: "justify", marginTop: "1rem"}} >
                                <p className="footer-menu-title" >
                                    {t('home_our_methods')}
                                </p>
                                
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#it-audit" className='footer-link'>{t('home_our_methods_audit')}</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#needs-collection" className='footer-link'>{t('home_our_methods_rapp_recom')}</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#road-map" className='footer-link'>{t('home_our_methods_roadmap_budg')}</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#analysis-design" className='footer-link'>{t('home_our_methods_dev')}</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#deployment-user-training" className='footer-link'>{t('home_our_methods_training')}</Link>
                                </p>
                                <p className="footer-text-description" >
                                    <Link to="/our-methods#mce-tma" className='footer-link'>{t('home_our_methods_evolution')}</Link>
                                </p>
                            </div> */}
                            
                            <div className="logo" style={{textAlign: "center", width: 'fit-content'}}>
                                    <img width={'200px'} src="/ova-corporate.png" alt="logo" />
                                    {/* <p className='text-white ova-logo-text' >OVA CORPORATE</p> */}
                                </div>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div className="footer-bottom" style={{maxWidth: "auto"}} >
                <div className="container">
                    <div className="branding">
                        <div className="socials">
                            <div className="linkedin">
                                <Link to="http://instagram.com"></Link>
                            </div>
                            <div className="instagram">
                                <Link to="http://instagram.com"></Link>
                            </div>
                            <div className="twitter">
                                <Link to="http://twitter.com"></Link>
                            </div>
                            <div className="facebook">
                                <Link to="https://www.facebook.com/Ova-consulting-sarl-102490568946434"></Link>
                            </div>
                            <div className="behance">
                                <Link to="http://behance.com"></Link>
                            </div>
                        </div>
                        <div className="corporate">
                        Ovaconsulting
                        </div>
                    </div>
                    <div className="links">
                        <div className="copyright">
                            {t("footer_slogan")}
                        </div>
                        <Link to="/privacy-policy">{t('footer_privacy_policy')}</Link>
                        {/* <Link to="#">{t('footer_term_condition')}</Link> */}
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="m-auto">
                    <p className='text-white'>
                    © Copyright 2022 – Ova consulting
                    </p>
                </div>
            </div>
        </footer>
    );
}