import * as React from 'react';
import Box from '@mui/material/Box';
import EquipementsStore from './components/EquipementsStore';
import { useNavigate, useParams } from 'react-router-dom';
import StoreNav from './components/navigation/StoreNav';
import Page from '../../components/page/Page';
import Faqs from '../../components/faqs/Faqs';
import { Button, Grid } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { redirect } from '../../components/utils/utils';
import BackSection from '../../components/backSection/Backsection';

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


function LicensesEquipements (props: Props) {

    const { store } = useParams();
    const navigate = useNavigate();


    return (
        <Page
            title="OVA consulting | Store"
        >
            <section className="store-main hosting">
                <div className="container">
                    <div className="page-titles">
                        <div className="page-title">
                            Equipments and licenses
                        </div>
                        <div className='page-title-1'>
                            <span className="text-primary">OVA consuting store.</span> The best way to buy the {store} you love.
                        </div>
                        <BackSection url={"/"} />
                    </div>
                    <Box>
                        <StoreNav/>
                    </Box>
                    <Box>
                    {/* <ShopDrawer/> */}
                        {
                            store === "equipments" ? <EquipementsStore /> : <EquipementsStore />
                        }
                    </Box>
                </div>
                {/* FAQS */}
                <Faqs />
            </section>
        </Page>
    )
}


export default LicensesEquipements