

export default function () {


    return(
        <>
        {/* <!--Get Insurance Start--> */}
        <section className="get-insurance">
            <div className="get-insurance-bg"
                style={{backgroundImage: "url(assets/images/backgrounds/get-insurance-bg.png)"}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="get-insurance__left">
                            <div className="get-insurance__img wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <img src="assets/images/resources/get-insurance-img-1.png" alt=""/>
                            </div>
                            <div className="get-insurance__author">
                                <p>Aleesha Rose</p>
                            </div>
                            <div className="get-insurance__circle"></div>
                            <div className="get-insurance__shape-1 float-bob-x">
                                <img src="favicon.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="get-insurance__right">
                            <div className="section-title text-left">
                                <div className="section-sub-title-box">
                                    <p className="section-sub-title">Free quote</p>
                                    <div className="section-title-shape-1">
                                        <img src="assets/images/shapes/section-title-shape-1.png" alt=""/>
                                    </div>
                                    <div className="section-title-shape-2">
                                        <img src="assets/images/shapes/section-title-shape-2.png" alt=""/>
                                    </div>
                                </div>
                                <h2 className="section-title__title">Get an insurance quote <br/> to get started!</h2>
                            </div>
                            <div className="get-insurance__tab">
                                <div className="get-insurance__tab-box tabs-box">
                                    <ul className="tab-buttons clearfix list-unstyled">
                                        <li data-tab="#home2" className="tab-btn active-btn"><span>Home</span></li>
                                        <li data-tab="#vehicles" className="tab-btn"><span>Vehicles</span></li>
                                        <li data-tab="#health" className="tab-btn"><span>health</span></li>
                                        <li data-tab="#life" className="tab-btn"><span>Life</span></li>
                                    </ul>
                                    <div className="tabs-content">
                                        {/* <!--tab--> */}
                                        <div className="tab active-tab" id="home2">
                                            <div className="get-insurance__content">
                                                <form className="get-insurance__form">
                                                    <div className="get-insurance__content-box">
                                                        <div className="get-insurance__input-box">
                                                            <input type="text" placeholder="Full name" name="name"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <input type="email" placeholder="Email address"
                                                                name="email"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <select className="selectpicker"
                                                                aria-label="Default select example">
                                                                <option selected>Select type of insurance</option>
                                                                <option value="1">Car insurance</option>
                                                                <option value="2">Life insurance</option>
                                                                <option value="3">Home insurance</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="get-insurance__progress">
                                                        <div className="get-insurance__progress-single">
                                                            <h4 className="get-insurance__progress-title">Limits of Balance:
                                                            </h4>
                                                            <div className="get-insurance__progress-range">
                                                                <input type="text" className="balance-range-slider"
                                                                    data-hide-min-max="true" data-step="100"
                                                                    data-from="70000" data-min="0" data-max="90000"
                                                                    value="" />
                                                                <div className="get-insurance__balance-box">
                                                                    <p className="get-insurance__balance">$<span></span></p>
                                                                </div>
                                                                <input type="hidden"
                                                                    className="get-insurance__balance__input"/>
                                                            {/* </div><!-- /.get-insurance__progress-range --> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="thm-btn get-insurance__btn">Get a Quote
                                                        Now</button>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <!--tab--> */}
                                        <div className="tab" id="vehicles">
                                            <div className="get-insurance__content">
                                                <form className="get-insurance__form">
                                                    <div className="get-insurance__content-box">
                                                        <div className="get-insurance__input-box">
                                                            <input type="text" placeholder="Full name" name="name"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <input type="email" placeholder="Email address"
                                                                name="email"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <select className="selectpicker"
                                                                aria-label="Default select example">
                                                                <option selected>Select type of insurance</option>
                                                                <option value="1">Car insurance</option>
                                                                <option value="2">Life insurance</option>
                                                                <option value="3">Home insurance</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="get-insurance__progress">
                                                        <div className="get-insurance__progress-single">
                                                            <h4 className="get-insurance__progress-title">Limits of Balance:
                                                            </h4>
                                                            <div className="get-insurance__progress-range">
                                                                <input type="text" className="balance-range-slider"
                                                                    data-hide-min-max="true" data-step="100"
                                                                    data-from="70000" data-min="0" data-max="90000"
                                                                    value="" />
                                                                <div className="get-insurance__balance-box">
                                                                    <p className="get-insurance__balance">$<span></span></p>
                                                                </div>
                                                                <input type="hidden"
                                                                    className="get-insurance__balance__input"/>
                                                            </div>
                                                            {/* <!-- /.get-insurance__progress-range --> */}
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="thm-btn get-insurance__btn">Get a Quote
                                                        Now</button>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <!--tab--> */}
                                        <div className="tab" id="health">
                                            <div className="get-insurance__content">
                                                <form className="get-insurance__form">
                                                    <div className="get-insurance__content-box">
                                                        <div className="get-insurance__input-box">
                                                            <input type="text" placeholder="Full name" name="name"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <input type="email" placeholder="Email address"
                                                                name="email"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <select className="selectpicker"
                                                                aria-label="Default select example">
                                                                <option selected>Select type of insurance</option>
                                                                <option value="1">Car insurance</option>
                                                                <option value="2">Life insurance</option>
                                                                <option value="3">Home insurance</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="get-insurance__progress">
                                                        <div className="get-insurance__progress-single">
                                                            <h4 className="get-insurance__progress-title">Limits of Balance:
                                                            </h4>
                                                            <div className="get-insurance__progress-range">
                                                                <input type="text" className="balance-range-slider"
                                                                    data-hide-min-max="true" data-step="100"
                                                                    data-from="70000" data-min="0" data-max="90000"
                                                                    value="" />
                                                                <div className="get-insurance__balance-box">
                                                                    <p className="get-insurance__balance">$<span></span></p>
                                                                </div>
                                                                <input type="hidden"
                                                                    className="get-insurance__balance__input"/>
                                                            </div>
                                                            {/* <!-- /.get-insurance__progress-range --> */}
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="thm-btn get-insurance__btn">Get a Quote
                                                        Now</button>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <!--tab--> */}
                                        <div className="tab" id="life">
                                            <div className="get-insurance__content">
                                                <form className="get-insurance__form">
                                                    <div className="get-insurance__content-box">
                                                        <div className="get-insurance__input-box">
                                                            <input type="text" placeholder="Full name" name="name"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <input type="email" placeholder="Email address"
                                                                name="email"/>
                                                        </div>
                                                        <div className="get-insurance__input-box">
                                                            <select className="selectpicker"
                                                                aria-label="Default select example">
                                                                <option selected>Select service</option>
                                                                <option value="1">service One</option>
                                                                <option value="2">service Two</option>
                                                                <option value="3">service Three</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="get-insurance__progress">
                                                        <div className="get-insurance__progress-single">
                                                            <h4 className="get-insurance__progress-title">Limits of Balance:
                                                            </h4>
                                                            <div className="get-insurance__progress-range">
                                                                <input type="text" className="balance-range-slider"
                                                                    data-hide-min-max="true" data-step="100"
                                                                    data-from="70000" data-min="0" data-max="90000"
                                                                    value="" />
                                                                <div className="get-insurance__balance-box">
                                                                    <p className="get-insurance__balance">$<span></span></p>
                                                                </div>
                                                                <input type="hidden"
                                                                    className="get-insurance__balance__input"/>
                                                            </div>
                                                            {/* <!-- /.get-insurance__progress-range --/> */}
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="thm-btn get-insurance__btn">Get a Quote
                                                        Now</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Get Insurance End--> */}
        </>
    )
}