


export default function () {


    return (
        <>
            {/* <!--Why Choose One Start--> */}
        <section className="why-choose-one">
            <div className="why-choose-one-shape-1"
                style={{backgroundImage: "url(assets/images/shapes/why-choose-one-shape-1.png)"}}></div>
            <div className="why-choose-one-shape-2 float-bob-y">
                <img src="assets/images/shapes/why-choose-one-shape-2.png" alt=""/>
            </div>
            <div className="why-choose-one-shape-3 float-bob-x">
                <img src="assets/images/shapes/why-choose-one-shape-3.png" alt=""/>
            </div>
            <div className="why-choose-one-shape-4 float-bob-y">
                <img src="assets/images/shapes/why-choose-one-shape-4.png" alt=""/>
            </div>
            <div className="why-choose-one-shape-5 float-bob-y">
                <img src="assets/images/shapes/why-choose-one-shape-5.png" alt=""/>
            </div>
            <div className="why-choose-one-shape-6 float-bob-x">
                <img src="assets/images/shapes/why-choose-one-shape-6.png" alt=""/>
            </div>
            <div className="why-choose-one-img wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                <img src="assets/images/resources/why-choose-one-img.png" alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-7">
                        <div className="why-choose-one__left">
                            <div className="section-title text-left">
                                <div className="section-sub-title-box">
                                    <p className="section-sub-title">Why choose</p>
                                    <div className="section-title-shape-1">
                                        <img src="assets/images/shapes/section-title-shape-3.png" alt=""/>
                                    </div>
                                    <div className="section-title-shape-2">
                                        <img src="assets/images/shapes/section-title-shape-4.png" alt=""/>
                                    </div>
                                </div>
                                <h2 className="section-title__title">Few reasons for people choosing insur</h2>
                            </div>
                            <p className="why-choose-one__text">Nullam eu nibh vitae est tempor molestie id sed ex. Quisque
                                dignissim maximus ipsum, sed rutrum metus tincidunt et.</p>
                            <div className="why-choose-one__list-box">
                                <ul className="list-unstyled why-choose-one__list">
                                    <li>
                                        <div className="why-choose-one__single">
                                            <div className="why-choose-one__list-icon">
                                                <span className="icon-easy-to-use"></span>
                                            </div>
                                            <div className="why-choose-one__list-title-box">
                                                <div className="why-choose-one__list-title-inner">
                                                    <h3 className="why-choose-one__list-title">Fast & easy process</h3>
                                                </div>
                                                <div className="why-choose-one__list-text-box">
                                                    <p className="why-choose-one__list-text">Lorem ipsum dolor sit amet,
                                                        sectetur adipiscing elit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="why-choose-one__single">
                                            <div className="why-choose-one__list-icon">
                                                <span className="icon-contract"></span>
                                            </div>
                                            <div className="why-choose-one__list-title-box">
                                                <div className="why-choose-one__list-title-inner">
                                                    <h3 className="why-choose-one__list-title">Fast & easy process</h3>
                                                </div>
                                                <div className="why-choose-one__list-text-box">
                                                    <p className="why-choose-one__list-text">Lorem ipsum dolor sit amet,
                                                        sectetur adipiscing elit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="why-choose-one__single">
                                            <div className="why-choose-one__list-icon">
                                                <span className="icon-policy"></span>
                                            </div>
                                            <div className="why-choose-one__list-title-box">
                                                <div className="why-choose-one__list-title-inner">
                                                    <h3 className="why-choose-one__list-title">Control over policy</h3>
                                                </div>
                                                <div className="why-choose-one__list-text-box">
                                                    <p className="why-choose-one__list-text">Lorem ipsum dolor sit amet,
                                                        sectetur adipiscing elit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="why-choose-one__single">
                                            <div className="why-choose-one__list-icon">
                                                <span className="icon-fund"></span>
                                            </div>
                                            <div className="why-choose-one__list-title-box">
                                                <div className="why-choose-one__list-title-inner">
                                                    <h3 className="why-choose-one__list-title">Save your money</h3>
                                                </div>
                                                <div className="why-choose-one__list-text-box">
                                                    <p className="why-choose-one__list-text">Lorem ipsum dolor sit amet,
                                                        sectetur adipiscing elit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Why Choose One End--> */}
        </>
    )
}