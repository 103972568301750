import produce from "immer";
import { ApplicationAction } from "../types";
import { ADD_TO_CART_FAILED, ADD_TO_CART_REQUEST, ADD_TO_CART_SUCCESS, ArticlesState, LOADING_ARTICLES_FAILED, LOADING_ARTICLES_REQUEST, LOADING_ARTICLES_SUCCESS, LOAD_ARTICLE_FAILED, LOAD_ARTICLE_REQUEST, LOAD_ARTICLE_SUCCESS, REMOVE_FROM_CART_FAILED, REMOVE_FROM_CART_REQUEST, REMOVE_FROM_CART_SUCCESS } from "../types/StoreArticles";



const initialState: ArticlesState = {
    loading: {
        loading_articles_failed: false,
        loading_articles_request: true,
        loading_articles_success: false,
        loading_article_failed: false,
        loading_article_request: false,
        loading_article_success: false,
        add_to_cart_request: false,
        add_to_cart_success: false,
        add_to_cart_failed: false,
        remove_from_cart_request: false,
        remove_from_cart_success: false,
        remove_from_cart_failed: false,
    },
    articles: [],
    cart: [],
    article: undefined
}

const reducer = (state = initialState, action: ApplicationAction) => {

    switch(action.type) {
        case LOADING_ARTICLES_REQUEST:
            return produce(state, (draft) => {
                draft.loading.loading_articles_request = true;
                draft.loading.loading_articles_success = false;
                draft.loading.loading_articles_failed = false;
            })
        case LOADING_ARTICLES_SUCCESS:
            return produce(state, (draft) => {
                draft.loading.loading_articles_request = false;
                draft.loading.loading_articles_success = true;
                draft.loading.loading_articles_failed = false;
                draft.articles = action.articles
            })
        case LOADING_ARTICLES_FAILED:
            return produce(state, (draft) => {
                draft.loading.loading_articles_request = false;
                draft.loading.loading_articles_success = false;
                draft.loading.loading_articles_failed = true;
            })
        case ADD_TO_CART_REQUEST:
            return produce(state, (draft) => {
                draft.loading.add_to_cart_request = true;
                draft.loading.add_to_cart_success = false;
                draft.loading.add_to_cart_failed = false;
            })
        case ADD_TO_CART_SUCCESS:
            return produce(state, (draft) => {
                draft.loading.add_to_cart_request = false;
                draft.loading.add_to_cart_success = true;
                draft.loading.add_to_cart_failed = false;
                draft.cart = [...draft.cart, action.article]
            })
        case ADD_TO_CART_FAILED:
            return produce(state, (draft) => {
                draft.loading.add_to_cart_request = false;
                draft.loading.add_to_cart_success = false;
                draft.loading.add_to_cart_failed = true;
            })
        case REMOVE_FROM_CART_REQUEST:
            return produce(state, (draft) => {
                draft.loading.remove_from_cart_request = true;
                draft.loading.remove_from_cart_success = false;
                draft.loading.remove_from_cart_failed = false;
            })
        case REMOVE_FROM_CART_SUCCESS:
            return produce(state, (draft) => {
                draft.loading.remove_from_cart_request = false;
                draft.loading.remove_from_cart_success = true;
                draft.loading.remove_from_cart_failed = false;
                draft.cart = draft.cart.filter(item => item.id !== action.article.id)
            })
        case REMOVE_FROM_CART_FAILED:
            return produce(state, (draft) => {
                draft.loading.remove_from_cart_request = false;
                draft.loading.remove_from_cart_success = false;
                draft.loading.remove_from_cart_failed = true;
            })
        case LOAD_ARTICLE_REQUEST:
            return produce(state, (draft) => {
                draft.loading.loading_article_request = true;
                draft.loading.loading_article_success = false;
                draft.loading.loading_article_failed = false;
            })
        case LOAD_ARTICLE_SUCCESS:
            return produce(state, (draft) => {
                draft.loading.loading_article_request = false;
                draft.loading.loading_article_success = true;
                draft.loading.loading_article_failed = false;
                draft.article = action.article;
            })
        case LOAD_ARTICLE_FAILED:
            return produce(state, (draft) => {
                draft.loading.loading_article_request = false;
                draft.loading.loading_article_success = false;
                draft.loading.loading_article_failed = true;
            })
        default:
            return state
    }
}

export default reducer;