import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { StoreArticle } from '../../../types/storeCard';


interface Props {
    product: StoreArticle
}

export default function CartItemCard(props: Props) {

    const {product} = props;

    return (
        <Card sx={{ display: 'flex', flexDirection: {sm: "row", xs: "column"} }}>
            <CardMedia
            component="img"
            sx={{ width: {sm: "200px", xs: "100%"}, maxHeight: "200px" }}
            image={product.image}
            alt={product.title}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" className="text-left product-details-title-1">
                        {product.title}
                    </Typography>
                    <Typography className="text-left product-details-details" variant="h6" color="text.primary" component="div">
                        {product.category}
                    </Typography>
                    <Typography component="div" variant="h5" className="text-left product-details-title-1">
                        $ {product.price}
                    </Typography>
                    <Box
                        sx={{
                            width: 200,
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '1rem'
                        }}
                        >
                        <Rating
                            name="text-feedback"
                            value={product?.rating.rate ?? 1}
                            readOnly
                            precision={0.1}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        <Box sx={{ ml: 2 }}>{product?.rating.rate + "/" + product?.rating.count}</Box>
                    </Box>
                    <Typography className="text-left product-details-title-1" variant="subtitle1" color="text.secondary" component="div">
                        {product.description}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <IconButton aria-label="previous">
                        <RemoveIcon color="error" />
                    </IconButton>
                    <Box>{String(1)}</Box>
                    <IconButton aria-label="next">
                        <AddIcon color='success' />
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
    
}
