import { StoreArticle } from "../../types/storeCard"
import { AddToCartFailed, AddToCartRequest, AddToCartSuccess, ADD_TO_CART_FAILED, ADD_TO_CART_REQUEST, ADD_TO_CART_SUCCESS, LoadArticleFailed, LoadArticleRequest, LoadArticlesFailed, LoadArticlesRequest, LoadArticlesSuccess, LoadArticleSuccess, LOADING_ARTICLES_FAILED, LOADING_ARTICLES_REQUEST, LOADING_ARTICLES_SUCCESS, LOAD_ARTICLE_FAILED, LOAD_ARTICLE_REQUEST, LOAD_ARTICLE_SUCCESS, RemoveFromCartFailed, RemoveFromCartRequest, RemoveFromCartSuccess, REMOVE_FROM_CART_FAILED, REMOVE_FROM_CART_REQUEST, REMOVE_FROM_CART_SUCCESS } from "../types/StoreArticles"




export const loadArticlesRequest = (): LoadArticlesRequest => ({
    type: LOADING_ARTICLES_REQUEST
})

export const loadArticlesSuccess = (articles: StoreArticle[]): LoadArticlesSuccess => ({
    type: LOADING_ARTICLES_SUCCESS,
    articles
})

export const loadArticlesFailed = (): LoadArticlesFailed => ({
    type: LOADING_ARTICLES_FAILED,
})

// Cart actions
export const addToCartRequest = (): AddToCartRequest => ({
    type: ADD_TO_CART_REQUEST
})

export const addToCartSuccess = (article: StoreArticle): AddToCartSuccess => ({
    type: ADD_TO_CART_SUCCESS,
    article
})

export const addToCartFailed = (): AddToCartFailed => ({
    type: ADD_TO_CART_FAILED
})

export const removeFromCartRequest = (): RemoveFromCartRequest => ({
    type: REMOVE_FROM_CART_REQUEST
})

export const removeFromCartSuccess = (article: StoreArticle): RemoveFromCartSuccess => ({
    type: REMOVE_FROM_CART_SUCCESS,
    article
})

export const removeFromCartFailed = (): RemoveFromCartFailed => ({
    type: REMOVE_FROM_CART_FAILED
})

export const loadArticleRequest = (): LoadArticleRequest => ({
    type: LOAD_ARTICLE_REQUEST
})

export const loadArticleSuccess = (article: StoreArticle): LoadArticleSuccess => ({
    type: LOAD_ARTICLE_SUCCESS,
    article
})

export const loadArticleFailed = (): LoadArticleFailed => ({
    type: LOAD_ARTICLE_FAILED
})