import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import _ from "lodash";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
interface Props {
    key: string
}


function LoadingCard (props: Props) {

    const { key } = props;

    return(
        <Grid item md={4} key={key}>
            <Card sx={{ borderRadius: "1rem" }} className="product-card">
                <Stack spacing={1}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="rectangular" height={200} />
                </Stack>
            </Card>
        </Grid>
    )
}

export default LoadingCard;