




export default function () {


    return(
        <>
            {/* <!--Services One Start--> */}
        <section className="services-one">
            <div className="services-one__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="services-one__top-left">
                                <div className="section-title text-left">
                                    <div className="section-sub-title-box">
                                        <p className="section-sub-title">Our services</p>
                                        <div className="section-title-shape-1">
                                            <img src="assets/images/shapes/section-title-shape-1.png" alt=""/>
                                        </div>
                                        <div className="section-title-shape-2">
                                            <img src="assets/images/shapes/section-title-shape-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <h2 className="section-title__title">We’re covering all the insurance fields</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6">
                            <div className="services-one__top-right">
                                <p className="services-one__top-text">Nullam eu nibh vitae est tempor molestie id sed ex.
                                    Quisque dignissim maximus ipsum, sed rutrum metus tincidunt et. Sed eget tincidunt
                                    ipsum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-one__bottom">
                <div className="services-one__container">
                    <div className="row">
                        {/* <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-1.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-drive"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Car insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-2.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-family"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Life insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-3.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-home"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Home insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-4.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-heart-beat"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Health insurance</a>
                                    </h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="500ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-5.jpg" alt="" />
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-briefcase"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Business
                                            insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="600ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-6.jpg" alt="" />
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-fire"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Fire insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="700ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-7.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-ring"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Marriage
                                            insurance</a></h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End-->
                        <!--Services One Single Start--> */}
                        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="800ms">
                            <div className="services-one__single">
                                <div className="service-one__img">
                                    <img src="assets/images/services/services-1-8.jpg" alt=""/>
                                </div>
                                <div className="service-one__content">
                                    <div className="services-one__icon">
                                        <span className="icon-plane"></span>
                                    </div>
                                    <h2 className="service-one__title"><a href="#">Travel insurance</a>
                                    </h2>
                                    <p className="service-one__text">Lorem ipsum dolor sit amet, sed consectetur adipiscing
                                        elit.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--Services One Single End--> */}
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Services One End--> */}
        </>
    )
}