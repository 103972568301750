import { ArrowBack } from "@mui/icons-material";
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CartItemCard from './components/CartItemCard';
import _ from 'lodash';
import Page from "../../components/page/Page";
import { ApplicationState } from "../../store/types";
import BackSection from "../../components/backSection/Backsection";


const drawerWidth = 280;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


function CartPage (props: Props) {

    const { store } = useParams();
    const {cart} = useSelector((state: ApplicationState) => state.shop);
    const navigate = useNavigate();

    const handleNavigate = (url:string) => {
        navigate(url);
    };


    return (
        <Page
            title="OVA consulting | store cart"
        >
            {/* <Header3 /> */}
            <section className="store-main">
                <div className="container">
                    <BackSection url={`/ova-store/store/${store}`}/>
                    {/* <Grid sx={{ p: 3 }} className="d-flex">
                        <Button 
                            onClick={() => handleNavigate()} 
                            startIcon={<ArrowBack color="primary"/>}
                            variant='outlined'>GO BACK
                        </Button>
                    </Grid> */}
                    {cart.length > 0 ?<Box sx={{ display: 'flex' }}>
                        {/* <ShopDrawer isCart={true}/> */}
                        <Box
                            component="main"
                            sx={{ p: 3 }}
                        >
                            <Grid container spacing={5}>
                                <Grid item md={8} sm={7} xs={12}>
                                    <Grid>
                                        <Grid container spacing={4}>
                                            {
                                                cart.map((article) => {
                                                    return(
                                                        <Grid item md={12} xs={12} key={article.id + "cart"}>
                                                            <CartItemCard product={article}/>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={5} xs={12}>
                                    <Grid>
                                        <Card sx={{p: 3}}>
                                            <Box>
                                                <Typography variant="h6" >Total: $ {_.sumBy(cart, (item) => item.price)}</Typography>
                                                <br />
                                                <Divider/>
                                                <Box sx={{marginTop:"1rem"}}>
                                                    <Button onClick={() => handleNavigate(`/ova-store/store/${store}/checkout`)} variant='outlined'>CHECKOUT</Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box> : <div>
                        <p className="text">Your cart is empty</p>
                    </div> }
                </div>
            </section>
        </Page>
    )
}



export default CartPage;