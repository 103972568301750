import CountUp from 'react-countup';


interface Props {
    value: number
}

const Counter = (props: Props) => {
    return <CountUp
    start={0}
    end={props.value}
    duration={30}
    redraw
    useEasing
    separator=". "
    decimal=","
    onEnd={() => console.log('Ended! 👏')}
    onStart={() => console.log('Started! 💨')}
   />;
}

export default function () {


    return (
        <>
        {/* <!--Counter One Start--> */}
        <section className="counter-one">
            <div className="counter-one-shape-1 float-bob-y">
                <img src="assets/images/shapes/counter-one-shape-1.png" alt=""/>
            </div>
            <div className="counter-one-shape-2 float-bob-y">
                <img src="assets/images/shapes/counter-one-shape-2.png" alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    {/* <!--Counter One Single Start--> */}
                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                        <div className="counter-one__single">
                            <div className="counter-one__top">
                                <div className="counter-one__icon">
                                    <span className="icon-insurance-1"></span>
                                </div>
                                <div className="counter-one__count-box">
                                    <div className="counter-one__count-box-inner">
                                        <h3 className="">
                                            <Counter value={3.4}/>
                                        </h3>
                                        <span className="counter-one__plus">k</span>
                                    </div>
                                </div>
                            </div>
                            <p className="counter-one__text">Gave insurances</p>
                        </div>
                    </div>
                    {/* <!--Counter One Single End-->
                    <!--Counter One Single Start--> */}
                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                        <div className="counter-one__single">
                            <div className="counter-one__top">
                                <div className="counter-one__icon">
                                    <span className="icon-group"></span>
                                </div>
                                <div className="counter-one__count-box">
                                    <div className="counter-one__count-box-inner">
                                        <h3 className="odometer" data-count="89">00</h3>
                                        <span className="counter-one__plus">+</span>
                                    </div>
                                </div>
                            </div>
                            <p className="counter-one__text">Professional team</p>
                        </div>
                    </div>
                    {/* <!--Counter One Single End-->
                    <!--Counter One Single Start--> */}
                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                        <div className="counter-one__single">
                            <div className="counter-one__top">
                                <div className="counter-one__icon">
                                    <span className="icon-life-insurance"></span>
                                </div>
                                <div className="counter-one__count-box">
                                    <div className="counter-one__count-box-inner">
                                        <h3 className="odometer" data-count="2.8">00</h3>
                                        <span className="counter-one__plus">k</span>
                                    </div>
                                </div>
                            </div>
                            <p className="counter-one__text">Satisfied customers</p>
                        </div>
                    </div>
                    {/* <!--Counter One Single End-->
                    <!--Counter One Single Start--> */}
                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                        <div className="counter-one__single">
                            <div className="counter-one__top">
                                <div className="counter-one__icon">
                                    <span className="icon-success"></span>
                                </div>
                                <div className="counter-one__count-box">
                                    <div className="counter-one__count-box-inner">
                                        <h3 className="odometer" data-count="99">00</h3>
                                        <span className="counter-one__plus">%</span>
                                    </div>
                                </div>
                            </div>
                            <p className="counter-one__text">Our success rate</p>
                        </div>
                    </div>
                    {/* <!--Counter One Single End--> */}
                </div>
            </div>
        </section>
        {/* <!--Counter One End--> */}
        </>
    )
}