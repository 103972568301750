import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

import { useSnackbar } from 'notistack';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./styles.css"

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Button, Divider, Grid } from '@mui/material';
import Page from '../../../../components/page/Page';
import { ApplicationState } from '../../../../store/types';
import { loadArticleEffect } from '../../../../store/effects/storeArticles';
import BackSection from '../../../../components/backSection/Backsection';


const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


function StoreArticleDetails (props: Props) {

    const { store, articleId } = useParams();
    const [thumbsSwiper, setThumbsSwiper] = React.useState<any>(null);
    const {article} = useSelector((state: ApplicationState) => state.shop);


    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/ova-store/store/${store}`);
    }

    React.useEffect(() => {
        if(articleId){
            dispatch(
                loadArticleEffect(articleId, enqueueSnackbar)
            );
        }
    }, [])

    return (
        <Page
            title="OVA consulting | Store"
        >
            {<section className="store-main">
                <div className="containers">
                    <Box sx={{ display: 'flex' }}>
                        {/* <ShopDrawer /> */}
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}
                        >
                            <div className="conainer">
                                <Grid container className="back-btn-container">
                                    <div className="page-titles w-100">
                                        <div className="page-title">
                                            Product details
                                        </div>
                                        <div className='page-title-1'>
                                            <span className="text-primary">OVA consuting Store.</span> The best way to find...
                                        </div>
                                    </div>
                                </Grid>
                                <Grid container className="back-btn-container">
                                    <BackSection url={`/ova-store/store/${store}`}/>
                                    {/* <Button onClick={handleBack} variant="outlined" startIcon={<ArrowBackIosOutlinedIcon color='primary' />}>
                                        Back
                                    </Button> */}
                                </Grid>
                                {article && <Grid container spacing={4}>
                                    <Grid item md={5} sm={4}>
                                        <Grid>
                                            <div className="swipers-container">
                                                <Swiper
                                                    spaceBetween={10}
                                                    thumbs={{ swiper: thumbsSwiper }}
                                                    modules={[FreeMode, Thumbs]}
                                                    className="mySwiper2-1"
                                                >
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
                                                    </SwiperSlide>
                                                </Swiper>
                                                <Swiper
                                                    onSwiper={(swiper) => setThumbsSwiper(swiper)}
                                                    spaceBetween={10}
                                                    slidesPerView={4}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    modules={[FreeMode, Thumbs]}
                                                    className="mySwiper-1"
                                                >
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                    <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={7} sm={8}>
                                        <Grid>
                                            <p className="product-details-title"> {article?.title} </p>
                                            <p className="product-details-category">{article?.category}</p>
                                            <p className="product-details-price">$ {article?.price}</p>
                                            <Box
                                                sx={{
                                                    width: 200,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '1rem'
                                                }}
                                                >
                                                <Rating
                                                    name="text-feedback"
                                                    value={article?.rating.rate ?? 1}
                                                    readOnly
                                                    precision={0.1}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                <Box sx={{ ml: 2 }}>{article?.rating.rate + "/" + article?.rating.count}</Box>
                                            </Box>
                                            <p className="product-details-details">{article?.description}</p>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="swipers-container">
                                            <Divider/>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="swipers-container">
                                            <h5 className='text-left'>Descrption</h5>
                                            <p className="product-details-details">
                                                {article?.description}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>}
                            </div>
                        </Box>
                    </Box>
                </div>
            </section>}
        </Page>
    )
}



export default StoreArticleDetails;