



export default function About () {



    return (
        <>
            {/* <!--Feature One Start--> */}
            <section className="feature-one">
                <div className="container">
                    <div className="feature-one__inner">
                        <div className="row">
                            {/* <!--Feature One Single Start--> */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                <div className="feature-one__single">
                                    <div className="feature-one__single-inner">
                                        <div className="feature-one__icon">
                                            <span className="icon-insurance"></span>
                                        </div>
                                        <div className="feature-one__count"></div>
                                        <div className="feature-one__shape">
                                            <img src="assets/images/shapes/feature-one-shape-1.png" alt=""/>
                                        </div>
                                        <h3 className="feature-one__title"><a href="#">Safe your money</a></h3>
                                        <p className="feature-one__text">Lorem ipsum dolor amet consectetur adipiscing elit do
                                            eiusmod tempor incid idunt ut labore.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--Feature One Single End-->
                            <!--Feature One Single Start--> */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                <div className="feature-one__single">
                                    <div className="feature-one__single-inner">
                                        <div className="feature-one__icon">
                                            <span className="icon-cashback"></span>
                                        </div>
                                        <div className="feature-one__count"></div>
                                        <div className="feature-one__shape">
                                            <img src="assets/images/shapes/feature-one-shape-1.png" alt=""/>
                                        </div>
                                        <h3 className="feature-one__title"><a href="#">Get free quote</a></h3>
                                        <p className="feature-one__text">Lorem ipsum dolor amet consectetur adipiscing elit do
                                            eiusmod tempor incid idunt ut labore.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--Feature One Single End-->
                            <!--Feature One Single Start--> */}
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                                <div className="feature-one__single">
                                    <div className="feature-one__single-inner">
                                        <div className="feature-one__icon">
                                            <span className="icon-house"></span>
                                        </div>
                                        <div className="feature-one__count"></div>
                                        <div className="feature-one__shape">
                                            <img src="assets/images/shapes/feature-one-shape-1.png" alt=""/>
                                        </div>
                                        <h3 className="feature-one__title"><a href="#">Fast & reliable</a></h3>
                                        <p className="feature-one__text">Lorem ipsum dolor amet consectetur adipiscing elit do
                                            eiusmod tempor incid idunt ut labore.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--Feature One Single End--> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Feature One End--> */}

            {/* <!--About One Start--> */}
            <section className="about-one">
                <div className="about-one-bg wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"
                    style={{backgroundImage: "url(assets/images/backgrounds/about-one-bg.png)"}}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="about-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                    data-wow-duration="2500ms">
                                    <div className="about-one__img bg-primary">
                                        <img src="/assets/img/hosting-image.png" alt=""/>
                                    </div>
                                    <div className="about-one__img-two">
                                        <img src="assets/images/resources/about-one-img-2.jpg" alt=""/>
                                    </div>
                                    <div className="about-one__experience">
                                        <h2 className="about-one__experience-year">30</h2>
                                        <p className="about-one__experience-text">Years of <br/> Experience</p>
                                    </div>
                                    <div className="about-one__shape-1">
                                        <img src="assets/images/shapes/about-one-shape-1.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right">
                                <div className="section-title text-left">
                                    <div className="section-sub-title-box">
                                        <p className="section-sub-title">About our Hosting services</p>
                                        <div className="section-title-shape-1">
                                            <img src="assets/images/shapes/section-title-shape-1.png" alt=""/>
                                        </div>
                                        <div className="section-title-shape-2">
                                            <img src="assets/images/shapes/section-title-shape-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <h2 className="section-title__title">We provide the best insurance policy</h2>
                                </div>
                                <p className="about-one__text-1">Duis aute irure dolor in reprehenderit in velit esse cillum
                                    dolore eu nulla pariatur.</p>
                                <ul className="list-unstyled about-one__points">
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>Refresing to get such a personal touch.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>Duis aute irure dolor in reprehenderit in voluptate.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>Velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        </div>
                                    </li>
                                </ul>
                                <p className="about-one__text-2">Nullam eu nibh vitae est tempor molestie id sed ex. Quisque
                                    dignissim maximus ipsum, sed rutrum metus tincidunt et. Sed eget tincidunt ipsum.</p>
                                <div className="about-one__btn-call">
                                    <div className="about-one__btn-box">
                                        <a href="#" className="thm-btn about-one__btn">Discover More</a>
                                    </div>
                                    <div className="about-one__call">
                                        <div className="about-one__call-icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="about-one__call-content">
                                            <a href="tel:9200368090">+92 (003) 68-090</a>
                                            <p>Call to Our Experts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--About One End--> */}
        </>
    )
}