const fr = {
    translation: {
        'locale_french': 'Francais',
        'locale_english': 'A',
        // Header translations
        'header_close': "Fermer",
        'header_our_expertise': "Notre expertise",
        'header_digitalize_you': "Vous digitaliser",
        'header_our_reference': "Nos références",
        'header_recruitment': "Recrutement",

        // footer translation

        'footer_home': "Acceuil",
        'footer_term_condition': "Acceuil",
        'footer_privacy_policy': "Politique de confidentialité",
        "footer_privacy_policy_page_title": "POLITIQUE DE CONFIDENTIALITÉ ET DE PROTECTION DES DONNÉES PERSONNELLES",

        // Home page translations
        "home_agency": "Agence",
        "home_our_services": "Nos services",
        "home_our_method": "Notre methode",
        "home_our_methods": "Nos méthodes",
        "home_our_clients": "Nos clients",
        "home_our_partners": "Nos parténaires",
        "swiper_slide_title_1": " Au-delà des limites du digitale ",
        "swiper_slide_text_1": " Une équipe de collaborateurs hautement qualifiés au service de la digitalisation de votre cœur de métier. ",
        "home_button_try_free": "Découvrir",
        "swiper_slide_title_2": "  Digital workplace ",
        "swiper_slide_text_2": " La mutation de l’environnement de travail imposé par la crise sanitaire a orienté les entreprises dans l’ère du digital afin de redynamiser le travail et s’adapter au mieux aux nouvelles exigences de digitalisation des processus. ",
        "swiper_slide_title_3": " L’agilité à l’échelle  ",
        "swiper_slide_text_3": " Une entreprise qui mise sur l’agilité est capable de mobiliser son intelligence collective pour créer de la valeur et évoluer de façon itérative et en continu avec une économie de moyens et d’énergie et en créant les conditions d’épanouissement. ",
        "swiper_slide_title_4": " Voyage vers le cloud, réinventons la roue ",
        "swiper_slide_text_4": " Nous vous accompagnons progressivement dans le voyage vers le cloud avec une formation virtuelle sur la transformation numérique, l’adoption du cloud et la migration des données. ",
        "swiper_slide_title_5": "Bitrix 24",
        "swiper_slide_text_5": "Une plateforme de gestion de l’entreprise. Taillée d’une gestion de projets, de tâches, et équipée d’une messagerie collaborative, elle a également un service de visioconférence. BITRIX propose une digital Workplace unifiée aux organisations qui souhaitent y voir intégré leur CRM, développer leur communication interne et externe.",
        "swiper_slide_title_6": "Fortinet",
        "swiper_slide_text_6": "C’est un équipement de sécurité réseau en constante évolution, hautement performante qui protège les utilisateurs et les données contre les menaces diverses. Elle permet de consolider la sécurité et simplifier l’infrastructure de sécurité.",
        "home_news": "Nouveautés",
        "home_read_more": "Lire plus",
        "swiper_slide2_title_1": " Nuxeo ",
        "swiper_slide2_text_1": " Nuxeo facilite la création d'applications de gestion de contenu intelligentes, qui enrichissent l'expérience client, améliorent la prise de décision, et accélèrent le lancement de produits sur le marché.",
        "swiper_slide2_title_2": " Odoo ",
        "swiper_slide2_text_2": " Ayant fait ses preuves, Odoo est une suite d’applications open source qui propose de multiples applications pour la gestion de votre entreprise : CRM, e-commerce, comptabilité, gestion des stocks, gestion de projet… Le logiciel est entièrement modulable, et permet aux entreprises de construire un ERP sur mesure en choisissant les applications dont ils ont besoin.",
        "swiper_slide2_title_3": " Alfresco ",
        "swiper_slide2_text_3": " Élaboré comme solution de gestion de documents, Alfresco permet aux entreprises de mettre en place et de maintenir simplement un système organisationnel de fichiers, et permet d’automatiser les processus métier axés sur les documents. ",
        "swiper_slide2_title_4": " Docuware ",
        "swiper_slide2_text_4": " DocuWare, qui édite une solution de GED, a observé une progression importante de l'utilisatalfresco.pngion de ses solutions cloud lors du confinement au mois de mars dernier. Et cette tendance se confirme aujourd'hui. ",
        "swiper_slide2_title_5": " Axelor ",
        "swiper_slide2_text_5": "  Axelor est l’ERP Open Source adapté pour répondre aux besoins de plus en plus complexes des administrations et services publics, dans le respect des contraintes réglementaires. ",
        "home_products_services": "Nos Produits et Services",
        "home_services_descr": "Nous accompagnons nos clients dans l’accélération de leur processus de digitalisation autour de nos services.",
        "home_services_box_title_1":"Conseil/Strategie IT",
        "home_services_box_text_1":"Dans un environnement en pleine mutation, les entreprises doivent s’adapter et évoluer selon un processus continu. Nous accompagnons les entreprises et leurs dirigeants, de l’audit IT à la proposition de solutions concrètes et adaptées à leur secteur d’activité. ",
        "home_services_box_title_2":"Conduite de projet",
        "home_services_box_text_2":"Suivre votre projet depuis son démarrage jusqu'à sa livraison. Quel que soit votre projet, nous nous positionnons comme un réel partenaire en suivant étroitement et avec vos équipes, les différentes phases du cycle de vie de vos projets pour garantir leur bon déroulement en mettant un accent sur l’équilibre qualité-coût-délais.  Nous maitrisons plusieurs méthodologies de gestion de projet telles que le Cycle en V, la méthode Cube et les Méthodologies Agile. ",
        "home_services_box_title_3":"Business intelligence",
        "home_services_box_text_3":"L’informatique décisionnelle étant l'informatique à l'usage des décideurs et des dirigeants d'entreprise. Nous vous proposons des processus technologiques d'analyse des données et de présentation d'informations pour aider les dirigeants, managers et autres utilisateurs finaux de l'entreprise à prendre des décisions business éclairées.",
        "home_services_box_title_4":"Ingénierie logicielle",
        "home_services_box_text_4":"Spécialisée dans l’ingénierie informatique, Ova consulting apporte son expertise aux sociétés qui ont des difficultés et/ou des besoins en informatique. Notre aide pour les PME qui désirent avoir un service informatique de qualité est inégalable. Intégrateur de solutions informatiques, nous intervenons d'une part, sur l'ingénierie Logiciel et d'autre part, sur l'Assistance et support technique.",
        "home_services_box_title_5":"API management",
        "home_services_box_text_5":"Pour réussir l’ouverture de son SI, il faut connaître les atouts de l’API Management mais aussi identifier ses limites. Expert dans la gestion des API, nous vous proposons des technologies d’API-Gateway (Kong, API-MAN, Apigee, etc..) adapter à votre infrastructure et votre écosystème d’API afin de mieux manager, commercialiser, suivre vos API",
        "home_services_box_title_6":"Infrastructure et Cloud",
        "home_services_box_text_6":"Devenues un véritable patrimoine pour l'entreprise, les données (DATA) sont plus que jamais à protéger. C’est pourquoi, nous proposons une gamme de solutions basées sur Cloud Computing à travers nos différents produits AWS et/ou AZURE vous permettant de construire votre infrastructure virtuelle en fonction de votre organisation, de votre budget et de vos objectifs commerciaux.",
        "home_services_box_title_7":"Formation et certification",
        "home_services_box_text_7":"Ova consulting propose des formations et prestations adaptés à vos besoins au quotidien via une approche 100% personnalisée pour vous donner les moyens de vos ambitions. Apprenez le digital au travers de nos trainings ! 4 à 6 semaines d'accompagnement avec nos trainers, les meilleurs experts encore en activité ! Nous formons sur les sujets liés à L'IT Bureautique (Suite Microsoft Office), Progiciels, Gestion des Projets (Agile), Développements Front/Back, Data et stockage, Solution maison, cloud, DevOps et bien autres sujets.",
        "home_services_box_title_8":"Assistance et support technique",
        "home_services_box_text_8":"Nous fournissons et équipons les différents services : des serveurs, aux postes de travail, des imprimantes, aux équipements réseau, des scanners individuels aux équipements de mobilité et Interconnecté. A l'affût des évolutions techniques, nous veillons à fournir aux clients les derniers équipements permettant d'exploiter avec performance. Nous sommes partenaires DELL, Lenovo, Xerox, Microsoft et HP. Nous sommes également dans la capacité de fournir tout type de marque et de matériel.",
        "tchoko_description": "Vous souhaitez récolter des fonds pour votre association, un évènement, votre financement ? Si vous avez un projet associatif bien précis, le plus logique, facile et pratique pour vous sera d’utiliser l’application TCHOKO. Plus de 15 000 clients nous font confiance à travers le monde pour révolutionner leurs processus. Chacun d'entre-eux a une histoire à raconter.",
        "ovassure_description": "Au-delà du sinistré, une assurance a besoin de déclarer les différents interlocuteurs qui interviendrons dans un accident de circulation. Constituer votre dossier de sinistre partir d’un seul clic.",
        "home_our_methods_audit": "Audit IT",
        "home_our_methods_audit_text": "L’audit informatique est un diagnostic qui vous permet de mieux connaitre le niveau de protection de votre système d’information, de votre infrastructure informatique, de votre réseau ou du traitement de vos données. Il garantit la continuité, l’intégrité et la disponibilité de vos moyens informatiques. Son objectif sera de vous protéger des menaces internes ou externes. Votre gestion du risque est mieux contrôlée.",
        "home_our_methods_rapp_recom": "Recueil du besoin et spécification",
        "home_our_methods_rapp_recom_text": "Chez OVA Consulting, la phase de recueil et de formalisation du besoin est une étape clé : il faut être capable d'identifier tous les acteurs à impliquer dans la démarche, de comprendre correctement le besoin et de le retranscrire efficacement.",
        "home_our_methods_roadmap_budg": "Chiffrage roadmap et budgétisation",
        "home_our_methods_roadmap_budg_text": "La définition d’une roadmap est une étape déterminante ; en effet il s’agit de définir une liste de choses à faire avec des jalons qui positionnent la réalisation de ces éléments à faire dans le temps. Comme les choses à faire nécessitent de l’énergie, il faut également les budgéter et les évaluer.",
        "home_our_methods_dev": "Analyse et conception",
        "home_our_methods_dev_text": "La qualité d’une interface repose sur plusieurs critères au-delà des exigences techniques. Elle doit répondre à un besoin utilisateur précis par le biais d’un design ergonomique, facilement utilisable et manipulable.",
        "home_our_methods_training": "Déploiement et formation des utilisateurs",
        "home_our_methods_training_text": "En ingénierie chez nous, la méthode d'analyse et de conception est un procédé qui a pour objectif de permettre de formaliser les étapes préliminaires du développement d'un système afin de rendre ce développement plus fidèle aux besoins du client.",
        "home_our_methods_evolution": "MCE/TMA",
        "home_our_methods_evolution_text": "Ce dernier jalon marque la mise à disposition du nouveau système d’information auprès de ses utilisateurs finaux. Il s’agit d’une étape dans la transition entre le mode projet et le mode récurrent (ou RUN).",
        "home_our_methods_text": "Choisir OVA Consulting, c’est mobiliser les compétences de toute une entreprise pour vous aider à transformer vos idées en valeur pour le métier. ",
        "home_our_clients_text": "Ova consulting est une entreprises, dont le but est d’aider dans leur recherche de solutions digitales adaptées à leur activité et problématiques de digitalisation. ",
        "home_contact_name": "Nom",
        "home_contact_email": "Email",
        "home_contact_phone": "Téléphone",
        "home_contact_subject": "Sujet",
        "home_contact_message": "Ecrivez votre message",
        "home_contact_write_us": "Ecrivez nous",
        "home_contact_write_us_text": "OVA CONSULTING,  SARL, immatriculée au RCS de Bonanjo, Siège Sociale: Rond-point Deido, Face Collège Alfred Saker",
        "home_contact_send": "Envoyer",
        "home_contact_greeting": "Bonjour M.",
        "home_contact_any_question": "Avez vous une question ?",
        "home_contact_hello_any_question": "Hello, as-tu une question ?",
        // Contact page translations
        "contact_we_are": "Nous sommes une jeune equipe",
        "contact_we_are_text": "Les entreprises doivent appréhender un environnement qui évolue fortement et l’adoption d’une culture agile devient une nécessité pour rester compétitif et innovant dans ce contexte.",
        "contact_us_title": "Contactez nous",
        "contact_us_title_text": "Notre compréhension des enjeux métiers et technologiques nous permet d’intervenir sur la stratégie et la performance opérationnelle des systèmes d’information.",
        "home_contact_surname": "Prénom",
        "home_contact_i_want_work": "Je veux travailler avec vous",
        "home_contact_i_want_devis": "Je veux un devis",
        "home_contact_cv": "Votre CV",
        // Recruitment page translations

        "recruitement_text": "Recrutement",
        "recruitement_our_profiles": "Nos profils recherchés",
        "job_postulate_button": "Postuler",
        "job_title_1": "Développeur (se)",
        "job_title_2": "Ingénieurs d'affaires",
        "job_title_3": "Développeur (se)",
        "job_title_4": "Développeur (se)",
        "job_description_1": "Vous êtes en quête de nouveaux défis au sein d’une entreprise à taille humaine. Notre société saura vous donner tous les moyens pour réussir et accompagner votre développement avec de réelles opportunités de carrière. Dans le cadre de nos différentes missions en Conception et Développement de nombreux Site Web et Application Mobile, nous recherchons un(e) développeur pour emploi direct après test. ",
        "job_description_2": "Vous souhaitez intégrer une startup innovante, cultivant l’esprit d’équipe et investie dans les nouvelles technologies et dans de business a grande échelle. OVA Consulting est une entreprise Web Située au cœur de la ville de Douala proposant des solutions Web/Mobile pour construire des plateformes intelligentes. ",
        "job_description_3": "Vous souhaitez intégrer une startup innovante, cultivant l’esprit d’équipe et investie dans les nouvelles technologies. OVA Consulting est une agence Web Située au cœur de la ville de Douala proposant des solutions Web/Mobile pour construire des plateformes intelligentes. Notre société cumule plusieurs références et rassemble une équipe de plusieurs d’expert dans le domaine, de l’architecture logicielle aux développements en passant par le design UI/UX.Dans le cadre de nos différentes missions Conception et Développement de nombreux Site Web et Application web/Mobile, nous recherchons un(e) stagiaire pour une durée de 3 à 6 mois. ",
        "job_description_4": "Vous souhaitez intégrer une startup innovante, cultivant l’esprit d’équipe et investie dans les nouvelles technologies. OVA Consulting est une agence Web située au cœur de la ville de Douala proposant des solutions Web/Mobile pour construire des plateformes intelligentes. Notre société cumule plusieurs références et rassemble une équipe de plusieurs d’expert dans le domaine, de l’architecture logicielle aux développements en passant par le design UI/UX.Dans le cadre de nos différentes missions Conception et Développement de nombreux Site Web et Application web/Mobile, nous recherchons un(e) stagiaire pour une durée de 3 mois. ",
        // Digital page translations
        "digitalize_us": "Nous Digitaliser",
        "digitalize": "Digitalisation",
        "digitalize_text": "Ova consulting privilégie la qualité à la quantité. Loin d’être uniforme, tout contenu publié sur Ova consulting apporte des informations stratégiques à valeur ajoutée pour nourrir votre réflexion et accompagner votre prise de décision sur des problématiques IT et la digitalisation métier..",
        "to_us": "Chez nous",
        "to_us_text": "La digitalisation est aujourd’hui un incontournable pour une entreprise ou n’importe quel type d’organisation. En effet, elle s’applique à tous les domaines et assure une optimisation de temps et d’argent en automatisant des tâches de plus en plus complexes. Elle peut même devenir un avantage concurrentiel dans les domaines où le virage digital n’a pas totalement été accompli par les entreprises.",
        "how_it_work": "Notre stratégie",
        "how_it_work_text": "",
        "our_team": "Notre équipe",
        "how_it_work_1": " Lapproche Agiles ",
        "how_it_work_1_text": " A OVA Consulting nous pratiquons la méthode agile qui fixer des objectifs à court terme, d’où le projet est divisé en plusieurs sous projets. Une fois l’objectif atteint, on passe au suivant et jusqu’à l’accomplissement de l’objectif final. Elle est très flexible comme approche.",
        "how_it_work_2": " TDD/BDD/clean architecture ",
        "how_it_work_2_text": " Le développement piloté par le comportement est une technique de développement qui se concentre davantage sur le comportement d'une application. Le développement piloté par les tests est une technique de développement qui se concentre davantage sur la mise en œuvre d'une fonctionnalité d'une application ou d'un produit logiciel. Dans le BDD, les participants sont les développeurs, les clients et les utilisateurs.",
        "how_it_work_3": "Approche DevOPs ",
        "how_it_work_3_text": "Dans une démarche idéale et optimale, l’approche Devops pour OVA et sa méthodologie doit être adoptée dès la première phase de son développement.",
        "how_it_work_4": " Déploiment Cloud ",
        "how_it_work_4_text": "Le mode agile permet le développement dans un cycle continu de production logicielle. Le cloud Computing fournit des outils et une infrastructure aux équipes qui réalisent ces développements agiles pour garantir et faciliter les processus d’intégration, d’automatisation et de déploiement.",
        "how_it_work_5": " Maintenance Corrective et Evolutive ",
        "how_it_work_5_text": "Etant soucieux des différents projets qu’elle réalise OVA Consulting se propose d’accompagner ses clients dans un processus de maintenance.  Elle prévoit le pourcentage de production consacré à la maintenance. Plus les personnes sont productives, plus la capacité de production s’accroitre et moins les interventions de maintenance sont perturbantes au regard du volume total.",
        "how_it_work_6": " Solution SAAS",
        "how_it_work_6_text": " La mise en application est adoptée aux projets pour lesquels les besoins ne sont pas précisément identifiés ni clairement définis. Nous élaborons ensemble votre vision du produit à réaliser. Nous listons les fonctionnalités du produit cible. Notre équipe estime le coût de chaque élément et permet l’estimation du budget global. A la fin de chaque itération, le produit partiel mais utilisable vous est présenté.",
        "how_it_work_7": "Site web",
        "how_it_work_7_text": "L’objectif de base de votre site internet est de donner une information précise et à jour au sujet de votre entreprise. Un énorme avantage de votre site web, par rapport à d’autres outils de communication, est qu’il peut aisément être mis à jour en temps réel. De ce fait, vous gagnez en souplesse et en réactivité. Un nouveau service ? Un nouveau produit ? Il peut être ajouté à votre site web instantanément.",
        "how_it_work_8": "Outils de communication",
        "how_it_work_8_text": `
        Un outil de communication permet la transmission d’informations dans le but de véhiculer un message et de promouvoir des services ou des produits auprès de clients et de prospects. Il est utilisé dans le cadre d’une stratégie établie par l’équipe de communication d’une entreprise.

        Qu’il s’agisse de gagner en visibilité, de développer son chiffre d’affaires, d’accroître sa notoriété ou d’attirer de nouveaux clients, il est indispensable pour une entreprise de s’équiper d’outils de communication. En interne ou en externe, communiquer efficacement auprès de ses publics est un enjeu important. Il faut donc mettre en place des stratégies marketing globales, au sein desquelles se déclinent des actions de communication, accompagnées des outils de la communication adaptés aux objectifs.
        `,
        "how_it_work_9": "Marketing",
        "how_it_work_9_text": " Il s’apparente davantage à une méthode d’organisation qu’à une stratégie de communications. C’est une approche fondamentale de marketing dans laquelle les équipes identifient les tâches importantes et se concentre sur elles, y travaillent de manière coopérative, mesurent l’impact de leurs actions et cherchent en continu de manière incrémentale, à améliorer les résultats au fil du temps.",
        "how_it_work_10": "Progressive Web Application",
        "how_it_work_10_text": " Un site web optimisé pour mobile et pensé pour ressembler à une application native. Elles s’exécutent depuis un navigateur web et les fonctionnalités chargées sont gardées en cache pour utilisation future hors-ligne sans passer pour un store d’applications.",
        // Footer

        "footer_company": "Compagnie",
        "footer_service_1": "Conseil stratégie SI",
        "footer_service_2": "Conduite et gestion de projet",
        "footer_service_3": "Intégration des Solutions",
        "footer_service_4": "Business Intelligence",

        //  About us page

        "home_about": "A propos de nous",
        "about_text_p_1": "Filiale de Ova Corporate SA France, OVA Consulting SARL est une entreprise digitale, située au cœur de la ville de Douala et spécialisée dans la réalisation d'expérience digitale interactive unique.",
        "about_text_p_2": "En effet, nous accompagnons aussi bien les Grands groupes que les PMEs dans leur processus de transformation digitale. Grâce à notre expertise dans l’intégration et déploiement de solution IT, notamment dans les secteurs (Bancaire, Assurance, Agroalimentaire, Logistique/Transport etc…)",
        "about_text_p_3": "Notre équipe est représentative de notre approche collaborative, à l’image de notre atelier digital en open-space, ou chacun participe et met son talent en valeur !",
        "about_text_p_4": "Méthodologie et rigueur nous permettent de travailler sur l’ensemble du territoire et au-delà de ses frontières. Nous vous suivons et vous conseillons à chaque étape de vos projets, afin d’obtenir un résultat en adéquation avec vos objectifs.",
        "our": "Nos",
        "products": "Produits",
        "and": "Et",
        "services": "Services",

        "cookies_text": "Ce site utilise des cookies pour améliorer l'expérience utilisateur.",
        "cookies_accept": "Accepter",
        "cookies_decline": "Refuser",
        "home": "Acceuil",
        "our_methods": "Nos Méthodes",
        "def_of": "Définition de la ",
        "digit": "Digitalisation",
        "contact_add_pdf_file": "Ajouter un fichier pdf",
        "home_know_more":"En savoir plus",
        "home_our_services_products": "Nos services et produits",
        "send_canditature": "Envoyer ma candidature",
        "home_our_products": "Nos produits",
        "our_strategy": "Notre stratégie",
        "PRODUCTS": "PRODUITS",
        "SERVICES": "SERVICES",

        "home_contact_news_letter": "News letters",
        "home_contact_news_letter_text": "Entrer votre addresse email pour ne rater aucune de notre actualité.",
        "SUBSCRIBE": "SOUSCRIRE ",
        "home_our_trainings": "Formations",
        "job_application_ends": "Delais",
        "job_apply": "POSTULER",
        "job_description": "Description",
        "job_key_responsabilities": "Responsibilités clés",
        "skills_experience": "Compétences et expérience",
        "share_job": "Partager cet offre d'emploie",
        "what_is": "",
        "what_is_new": "Actualité",
        "home_what_new": "Actualité",
        "contact_need_info": "Besoin de plus d'informations ?",
        "contact_contact_us": "Contactez-nous",
        "contact_location": "Lieu",
        "contact_phone": "Téléphone",
        "contact_email": "Email",
        "contact_location_cameroon": "Cameroun",
        "contact_location_france": "France",
        "leave_us_message": "Laissez nous un message",
        
        "text_footer_description": "Filiale de OVA Corporate SA France, OVA Consulting est une entreprise digitale, située au cœur de la ville de douala et spécialisé dans la réalisation d'expérience digitale interactive unique.",

        "training_title": "Formations",
        "training_text": "OVA consulting vous accompagne dans la montée en compétences de vos équipes à travers des formations actualisées en permanence afin d’apporter des réponses innovantes aux besoins constants d'évolution et de compétitivité des entreprises.",
        "training_title_1": "Agile",
        "identity-services-microsoft-365": "Services d'identité Microsoft 365",
        "training_desc_1": "Aujourd’hui au cœur des entreprises, les pratiques agiles mettent en avant la collaboration entre des équipes autoorganisées et pluridisciplinaires et leurs clients.",
        "training_title_2": "Microsoft office 365",
        "training_desc_2": "Afin d'accompagner ses clients et partenaires IT, OVA Consulting propose une formation complète pour mettre en œuvre Microsoft 365 dans le but de permettre à l’entreprise de maîtriser des outils entièrement intégrés de collaboration, de voix et de connaissances professionnelles",
        "training_title_3": "Administration système",
        "training_desc_3": "Alors que la plupart des entreprises dépendent fortement d’opérations informatiques stables, elles ont constamment besoin d’administrateurs système professionnels pour gérer leurs réseaux informatiques et assurer leur maintenance",
        "training_title_4": "VMWare",
        "training_desc_4": "Une formation de pointe pour vous assurer une bonne prise en main de toute la gamme de la solution logiciel de virtualisation VMware.",
        "training_title_5": "DevOps",
        "training_desc_5": "La pratique Devops met l’accent sur la collaboration et la communication entre les développeurs de logiciels et les professionnels des opérations informatiques.",
        "training_title_6": "ITIL 4",
        "training_desc_6": "Grace à notre formation certifiantes ITIL v4, tirer profit de l’ensemble des investissements informatiques de votre organisation sur les bonnes pratiques internationales de gestion des SI afin de pouvoir fournir des services à valeur ajoutée.",
        "training_duration_6": "4 jours ",
        "training_duration_5": "3 jours ",
        "training_duration_4": "3 jours ",
        "training_duration_3": "2 jours ",
        "training_duration_2": "5 jours ",
        "training_duration_1": "2 jours ",
        "training_title_7": "COBIT",
        "training_desc_7": "Dans le but d’assurer une bonne maitrise et un suivi durable de la gouvernance du SI en intégrant les bonnes pratiques reconnues.",
        "training_duration_7": "4 jours ",
        "training_title_9": "ISO 27001",
        "training_desc_9": "Pour maintenir et améliorer le système de management de sécurité de l’information (SMSI) pour réduire les risques, se conformer aux normes et exigences. ",
        "training_duration_9": "5 jours, ",
        "our_clients_text": "Plus de 50 entreprises réparties dans plus de 10 pays. Nos clients sont des entreprises de toutes tailles et de différents secteurs d’activités, incluant le transport, la banque, l’hôtellerie, la restauration, l’éducation ou le secteur de la grande distribution.",
        "how_it_work_text_1": "Afin de se positionner au mieux sur ses marchés, OVA Consulting s’est engagé, entre 2021 et 2025, dans une phase de transformation profonde, marquée par un recentrage sur spécialités et les nouvelles technologies, une méthode, une organisation plus simple, plus intégrée et plus réactive, et un programme substantiel d’économies de coûts.",

        "fetching_jobs_error": "Une erreur est survenue lors du chargement des offres d'emplois",
        "FULLTIME": "Temps plein",
        "PARTIALTIME": "Temps partiel",

        "job_application_title": "Candidature",
        "job_application_text": "Veuillez remplir le formulaire ci-dessous pour postuler à un poste chez nous",
        "job_application_first_name": "Nom",
        "job_application_middle_name": "Deuxieme nom",
        "job_application_last_name": "Prenom",
        "contact_current_address": "Adresse actuelle",
        "job_application_address": "Adresse",
        "job_application_cover_letter" : "Lettre de motivation",
        "contact_city": "Ville",
        "contact_region": "Region",

        "input_files": "Veuiller charger votre CV et une lettre de motivation",
        "job_apply_error": "Une erreur est suvenue lors de la soumission de votre cadidature.",
        "job_apply_success": "Votre candidature a bien ete prise en compte",
        "contact_message_success": "Merci de nous avoir contacté!",
        "contact_message_error": "Une erreur est survenue lors de la soumission de votre vormulaire, veuiller rééssayer",
        "article_1_text": "L’atout principal de la veille technologique est d’identifier ou d’anticiper des innovations par secteurs d’activité. Les sources de veille technologique constituent une information stratégique incontournable pour devancer, développer et exploiter des inventions.De quelles sources parlons-nous ? Les bases de brevets, les articles scientifiques ou la presse spécialisée sont tous porteurs d’informations stratégiques.",
        "article_1_title": "La veille technologique",
        "article_2_text": "Le concept de gouvernance IT, ou gouvernance informatique, se définit comme un cadre formel visant à développer les technologies de l’information en accord avec les objectifs globaux et la stratégie de l’entreprise. Ce processus est né du constat suivant : les systèmes d’information sont générateurs de valeur,",
        "article_2_title": "Gouvernance IT",
        "article_3_text": "La gestion des API vise essentiellement à centraliser le contrôle de vos API, notamment au niveau des analyses, des contrôles d'accès, de la monétisation, des workflows de développement, etc",
        "article_3_title": "API MANAGEMENT",
        "news_letter_submit_error": "Une erreur est survenue lors de la soumission de vos données veuiller rééssayer.",
        "news_letter_submit_success": "Merci pour votre soubscription, vous serez informé (e) de toute notre actualité",

        "ova_location_paris": "OVA CORPORATE,  SARL, immatriculée au RCS de PARIS, Siège Social: 30/32 boulevard de Sébastopol 75004 Paris France",
        "ova_location_cameroon": "OVA CONSULTING,  SARL, immatriculée au RCS de Bonanjo, Siège Sociale: Rond-point Deido, Face Collège Alfred Saker",

        "anounces_title": "Actualités",
        "ACADEMIC_STAGE": "Stage académique",
        "PROFESSIONAL_STAGE": "Stage Professionel",
        "Founder, CEO": "Fondateur, CEO",
        "Executive Team": "Equipe exécutive",
        "Production Team": "Equipe de production",
        "Team Com": "Equipe Commerciale",
        "Comunity manager": "Gestionnaire de communauté",
        "Commercial": "Commerciale",
        "Sales Manager, Executive Assistant": "Responsable des ventes, assistante de direction",
        "Software Engineer": "Ingénieur logiciel",
        "Inra/Security Expert": "Expert Inra/Sécurité",
        "Software Achitech": "Architecte logiciel",
        "Project manager": "Chef de projet",
        "UX/UI Designer, Graphic Designer": "Designer UX/UI, Graphiste",
        "Business Developer": "Développeur d'affaires",
        "Tech Lead": "Responsable technique",
        "Operations Manager": "Responsable des opérations",


        // Our expertise text translation.

        "expertise_design_text": "Notre équipe de conception est profondément impliquée dans nos processus de développement et fournit des services de conception UX exceptionnels pour les applications mobiles et Web, ainsi qu'une interface homme-machine pour diverses industries.",
        "Read More": "Lire plus",
        "expertise_software_engineering": "Génie logiciel",
        "expertise_software_engineering_text": "Nous avons la motivation, l'expertise et le talent en ingénierie logicielle pour faire passer n'importe quelle idée de la phase de conception initiale à un produit commercial qui apporte de la valeur à votre entreprise et ouvre de nouvelles opportunités.",
        "expertise_infrastructure": "Infrastructures et sécurité",
        "expertise_infrastructure_text": "Le concept de sécurité de l'infrastructure et de sécurité périmétrique désigne les produits, les solutions et les services utilisés pour protéger les infrastructures des entreprises.",
        "expertise_training": "Formations",
        "expertise_training_text": "Formations",
        "expertise_data_science": "Science des données",
        "expertise_data_science_text": "Nous exploitons des outils avancés de mégadonnées et de veille économique pour aider les clients à extraire des informations exploitables à partir de divers ensembles de données générés en temps réel et à grande échelle. Nous les aidons à consolider d'énormes volumes de données provenant de différentes sources dans un environnement holistique qui peut être utilisé pour modéliser et prévoir de nouvelles opportunités de marché.",

        "online": "En ligne",
        "face-to-face": "En Présentiel",
        "management": "Gestion",
        "price-on-demand": "Prix sur demande",
        "categories": "Catégories",
        "read_more" : "Lire plus",
        "form_last_name": "Prénom",
        "form_society": "Société",
        "form_role": "Fonction",
        "form_postal_code": "Code postal",
        "form_inscriptions_number": "Nombre d'inscriptions souhaitées",
        "form_compl_info": "Informations complémentaires ou code promotionnel",
        "form_interested": "Je suis intéressé par un cours particulier.",
        "form_accept": "J'ai lu et j'accepte les Mentions Légales et la Politique de protection de données personnelles.",
        "i_subscribe": "Je m'inscris",
        "inscript_form": "Formulaire d'inscription",
        "not_found_text": "La page demandée n'existe pas",
        "not_found_home": "Revenir a l'acceuil",
        "success_story_1": "Emrald Securities Services",
        "success_story_text_1": "Réalisation du site web de ESS",
        "success_stories": "",

        "course_objectives": "Objectifs pedagogiques",
        "required_level":"Niveau requis",
        "public_concerned": "Public concerné",
        "detailed_program": "Programme détaillé",
        "objectives_competences": "Objectifs pédagogiques / Compétences visées",
        "design_tool": "Outil de design",
        "face_to_face_session_q": "Calendrier des sessions en présentiel",
        "face_to_online_session_q": "Calendrier des sessions en ligne",

        // Trainings translations en 

        // Agile

        "training_agile_objectives": `<ul>
        <li>Comprendre les principes de Scrum</li>
        <li>Assurer son rôle de Scrum master</li>
        <li>Mettre en œuvre la démarche Scrum</li>
        <li>Réussir sa certification Professional Scrum Master (PSM 1) de Scrum.org </li>
        </ul>`,
        "training_agile_required_level": "Niveau d’étude minimum du secondaire, maitrise l’outil informatique. ",
        "training_agile_concerned_public": "Chef de projets, Chef de projets digitaux, Scrum Master, Développeur, Managers, DevOps",
        "training_agile_program_title_1": "1ère Journée",
        "training_agile_program_content_title_1": "1 - Comprendre la démarche SCRUM et identifier les acteurs",
        "training_agile_program_content_text_1": `Les bénéfices de la démarche SCRUM. <br/>
        Identifier les étapes d'une démarche SCRUM.<br/>
        Identifier les acteurs : Product Owner, Scrum Master, équipe de développement.`,
        "training_agile_program_content_title_2": "2 - Exprimer les besoins",
        "training_agile_program_content_text_2": `Les exigences produit, le Backlog. <br/>
        Les stories. <br/>
        Prioriser les exigences. <br/>
        Les descriptions détaillées.<br/>
        Le product backlog.`,
        "training_agile_program_content_title_3": "3 - Planifier le travail à faire et évaluer les charges",
        "training_agile_program_content_text_3": `Définir les tâches en fonction des stories.<br/>
        Évaluer les charges que cela représente.<br/>
        Planifier les releases, les sprints.<br/>
        Prioriser et répartir les tâches.`,
        "training_agile_program_title_2": "2ème Journée",
        "training_agile_program_2_content_title_1": "4 - Réaliser les Sprint",
        "training_agile_program_2_content_text_1": `Le Sprint Planning Meeting.<br/>
        L'organisation du travail au quotidien, le meeting quotidien.<br/>
        Faire une revue de l'avancement.<br/>
        La fin et la revue d'un Sprint.<br/>
        Le feedback, l'amélioration continue.`,
        "training_agile_program_2_content_title_2": "5 - Mettre en œuvre le suivi et l'acceptation",
        "training_agile_program_2_content_text_2": `Organiser les différents tests.<br/>
        Les tests d'acceptation.<br/>
        Réaliser les tests.`,
        "training_agile_program_2_content_title_3": "6 - Préparer la certification Professional Scrum Master PSM I",
        "training_agile_program_2_content_text_3": "Effectuer des examens blancs dans les conditions d’examen avec correction commentée",
        "training_agile_program_2_content_title_4": "7 - Examen",
        "training_agile_program_2_content_text_4": `Passer la certification Professional Scrum Master 1 de Scrum.org :<br/>
        QCM : 80 questions en anglais en 1h.<br/>
        Des identifiants seront fournis aux participants.<br/>
        Nécessite uniquement un PC connecté à internet.<br/>
        Il est conseillé de passer l'examen dans un délai maximum de 2 semaines à 1 mois après le début de la session.`,

        // Microsoft office 365

        "training_microsoft_office_365_objectives": `
        <ul>
        <li>
        déploiement, gestion, configuration et dépannage des entreprises. 
        </li>
        <li>
        Gestion de l'infrastructure de messagerie, la configuration hybride, la migration, etc.  
        </li>
        </ul>`,
        "training_microsoft_office_365_required_level": "Niveau d’étude minimum du secondaire, maitrise l’outil informatique. ",
        "training_microsoft_office_365_concerned_public": "Chef de projets, Chef de projets digitaux, Scrum Master, Développeur, Managers, DevOps",
        "training_microsoft_office_365_program_title_1": "1ère Journée",
        "training_microsoft_office_365_program_content_title_1": " Module 1 : Gestion du pipeline de transport",
        "training_microsoft_office_365_program_content_text_1": `
        <ul>
        <li>
        Aperçu des services de transport
        </li>
        <li>
        Configuration du transport des messages
        </li>
        <li>
        Gestion des règles de transport
        </li>
        <li>
        Atelier : Configurer le transport des messages
        </li>
        <li>
        Créer des connecteurs
        </li>
        </ul>`,
        "training_microsoft_office_365_program_content_title_2": " Module 2 : Gestion et dépannage du flux de messagerie",
        "training_microsoft_office_365_program_content_text_2": `
        <ul>
        <li>
        Gestion du flux de messagerie
        </li>
        <li>
        Dépannage du flux de messagerie
        </li>
        <li>
        Dépannage des problèmes de transport
        </li>
        <li>
        Dépannage avec les journaux
        </li>
        <li>
        Laboratoire : Routage conditionnel du courrier
        </li>
        <li>
        Créer des règles de flux de messagerie
        </li>
        </ul>`,
        "training_microsoft_office_365_program_content_title_3": "Module 3 : Gestion de l'hygiène des messages",
        "training_microsoft_office_365_program_content_text_3": `
        <ul>
        <li>
        Planification de l'hygiène des messages
        </li>
        <li>
        Gestion des politiques anti-malware et anti-spam
        </li>
        <li>
        Gestion de la protection avancée contre les menaces
        </li>
        <li>
        Lab : Gestion de l'hygiène de la messagerie
        </li>
        <li>
        Créer des filtres d'hygiène
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_2": "2ème Journée",
        "training_microsoft_office_365_program_2_content_title_1": "Module 4 : Gestion de la conformité",
        "training_microsoft_office_365_program_2_content_text_1": `
        <ul>
        <li>
        Conformité de la messagerie dans le SCC
        </li>
        <li>
        Conformité de la messagerie dans Exchange
        </li>
        <li>
        Gestion de l'archivage et de l'audit d'Exchange Online
        </li>
        <li>
        Gestion de la recherche de contenu
        </li>
        </ul>`,
        "training_microsoft_office_365_program_2_content_title_2": "Module 5 : Gestion des paramètres organisationnels",
        "training_microsoft_office_365_program_2_content_text_2": `
        <ul>
        <li>
        Gestion de l'authentification pour la messagerie
        </li>
        <li>
        Configuration des paramètres organisationnels
        </li>
        <li>
        Configuration du partage organisationnel
        </li>
        </ul>`,
        "training_microsoft_office_365_program_2_content_title_3": " Module 6 : Gestion des appareils mobiles",
        "training_microsoft_office_365_program_2_content_text_3": `
        <ul>
        <li>
        Politiques de boîte aux lettres des appareils mobiles
        </li>
        <li>
        Gestion de l'accès aux appareils mobiles
        </li>
        <li>
        Laboratoire : Implémenter ActiveSync
        </li>
        <li>
        Implémenter Active Sync pour une ou plusieurs boîtes aux lettres
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_3": "3ème Journée",
        "training_microsoft_office_365_program_3_content_title_1": "Module 7 : Gestion des autorisations basées sur les rôles",
        "training_microsoft_office_365_program_3_content_text_1": `
        <ul>
        <li>
        Gestion des rôles d'administrateur
        </li>
        <li>
        Gestion des rôles d'utilisateur
        </li>
        <li>
        Configuration d'Exchange - Autorisation fractionnée RBAC et AD
        </li>
        <li>
        Atelier : Gérer les rôles et les règles d'autorisation
        </li>
        <li>
        Gérer les rôles et les politiques d'autorisation
        </li>
        </ul>`,
        "training_microsoft_office_365_program_3_content_title_2": "Module 8 : Gestion des objets et ressources destinataires",
        "training_microsoft_office_365_program_3_content_text_2": `
        <ul>
        <li>
        Destinataires de l'échange
        </li>
        <li>
        Création et gestion des destinataires Exchange
        </li>
        <li>
        Gestion des adresses e-mail, des listes et des ressources
        </li>
        <li>
        Laboratoire : Créer des objets et des ressources destinataires
        </li>
        <li>
        Créer des destinataires d'échange
        </li>
        <li>
        Créer des groupes
        </li>
        </ul>`,
        "training_microsoft_office_365_program_3_content_title_3": "Module 9 : Gestion des dossiers publics",
        "training_microsoft_office_365_program_3_content_text_3": `
        <ul>
        <li>
        Planification de la hiérarchie des dossiers publics
        </li>
        <li>
        Implémentation et gestion des dossiers publics
        </li>
        <li>
        Dépannage des dossiers publics
        </li>
        <li>
        Laboratoire : Implémenter des dossiers publics
        </li>
        <li>
        Créer des dossiers publics
        </li>
        <li>
        Gérer les dossiers publics
        </li>
        </ul>`,
        "training_microsoft_office_365_program_title_4": "4ème Journée",
        "training_microsoft_office_365_program_4_content_title_1": " Module 10 : Planification d'un environnement hybride",
        "training_microsoft_office_365_program_4_content_text_1": `
        <ul>
        <li>
        Conditions requises pour le déploiement hybride d'Exchange
        </li>
        <li>
        Planification de l'exécution de l'assistant de configuration hybride
        </li>
        <li>
        Laboratoire : Préparer Azure AD pour la synchronisation hybride
        </li>
        <li>
        Préparer Azure AD pour la synchronisation hybride
        </li>
        </ul>`,
        "training_microsoft_office_365_program_4_content_text_2": `
        <ul>
        <li>
        Planification des migrations de boîtes aux lettres
        </li>
        <li>
        Effectuer des migrations IMAP
        </li>
        <li>
        Effectuer des migrations de basculement et par étapes
        </li>
        <li>
        Effectuer des migrations avancée
        </li>
        </ul>`,
        "training_microsoft_office_365_program_4_content_title_2": "Module 11 : Effectuer des migrations de boîtes aux lettres",
        "training_microsoft_office_365_program_title_5": "5ème Journée",
        "training_microsoft_office_365_program_5_content_title_1": " Module 12 : Déploiement et dépannage d'un environnement hybride",
        "training_microsoft_office_365_program_5_content_text_1": `
        <ul>
        <li>
        Déploiement et gestion d'un serveur de transport Edge
        </li>
        <li>
        Configuration d'un déploiement hybride à l'aide de HCW
        </li>
        <li>
        Implémentation de la fonctionnalité hybride avancée
        </li>
        <li>
        Dépannage des déploiements hybrides
        </li>
        <li>
        Lab : Déployer un environnement hybride
        </li>
        <li>
        Configurer votre déploiement hybride
        </li>
        <li>
        Testez votre déploiement hybride
        </li>
        </ul>
    Passer la certification 
    Des identifiants seront fournis aux participants.
    Nécessite uniquement un PC connecté à internet.
        `,

        // vmware

        "training_vmware_objectives": `
        <ul>
        <li>
        Administrer vCenter Server pour gérer et configurer un hôte ESXi ;
        </li>
        <li>
        Réaliser un déploiement de VMware vCenter Server Appliance et effectuer son paramétrage ainsi que sa sécurisation ;
        </li>
        <li>
        Créer des réseaux virtuels via des commutateurs standards vSphere et créer des politiques ;
        </li>
        <li>
        Administrer vCenter Server pour gérer les stockages hôtes virtuels  ;
        </li>
        <li>
        Configurer vSphere pour le déploiement de machines virtuelles, la création de modèles, le clonage et les instantanés ;
        </li>
        <li>
        Migrer des VM avec leurs stockages via VMware vSphere® et vMotion® ;
        </li>
        <li>
        Gérer les clusters Distributed Resource Scheduler™ de VMware vSphere® ;
        </li>
        <li>
        Administrer VMware vSphere® Update Manager™ et procéder à un dépannage ou des updates.
        </li>
        </ul>
        `,
        "training_vmware_required_level": "Pour suivre la formation il faut avoir une expérience professionnelle significative en tant qu’administrateur Windows ou Linux",
        "training_vmware_concerned_public": "Les administrateurs système, ingénieurs système et opérateurs responsables des serveurs ESXi et vCenter ; les candidats qui souhaitent obtenir la certification professionnelle VCP-DCV 2020. ",
        "training_vmware_program_title_1": "1ère Journée",
        "training_vmware_program_content_title_1": "Présentation de la formation VMware vSphere 7 : ",
        "training_vmware_program_content_text_1": `
        <ul>
        <li>introduction et logistique ; vue d’ensemble des objectifs de formation ; </li>
        <li>Description sur le programme ; décryptage du système de certification VMware ;</li>
        <li>Prise en main de la zone d’apprentissage VMware Education ; </li>
        <li>Identification des ressources supplémentaires. </li>
        </ul>`,
        "training_vmware_program_content_title_2": "L'installation de vSphere 7 et du centre de données logiciel :",
        "training_vmware_program_content_text_2": `
        <ul>
        <li>
        Description de l’intégration de vSphere au centre de données logiciel et à l’infrastructure de cloud ; a définition du DevOps et la transformation numérique ;
        </li>
        <li>
        Explication de l’interaction entre vSphere et les CPU, la mémoire, les réseaux et les systèmes de stockage ; 
        </li>
        <li>
        Utilisation de vSphere Client pour accéder et gérer le système vCenter Server et l’hôte ESXi
        </li>
        <li>
        Comparaison de la machine virtuelle version 14 avec les autres versions ; 
        </li>
        <li>
        Identification des adaptateurs de réseaux virtuels et description du VMXNET3 amélioré ; comparaison sur les différents types de disques virtuels ; identification des avantages de ESXi Quick Boot. 
        </li>
        </ul>
        `,
        "training_vmware_program_title_2": "2ème Journée",
        "training_vmware_program_2_content_title_1":"Création et gestion des machines virtuelles :",
        "training_vmware_program_2_content_text_1":  `
        <ul>
        <li>
        Création, alimentation et suppression d’une machine virtuelle ; 
        </li>
        <li>
        Explication de l’importance de VMware Tools™ ; 
        </li>
        <li>
        Description de l’importation d’un modèle OVF d’appareil virtuel ; 
        </li>
        <li>
        Gestion de VMware Tools ; 
        </li>
        <li>
        Explication des solutions aux problèmes d’installation des systèmes d’exploitation et de VMware Tools. 
        </li>
        </ul>
        `,
        "training_vmware_program_2_content_title_2":"L'utilitaire de gestion centralisée vCenter Server :",
        "training_vmware_program_2_content_text_2": `
        <ul>
        <li>
        Description de l’architecture vCenter Server ; 
        </li>
        <li>
        Description de la communication entre les hôtes ESXi et vCenter Server ; 
        </li>
        <li>
        Identification des services, des composants et des modules ; accès et configuration de vCenter Server Appliance ; utilisation de vSphere Client pour gérer l’inventaire de serveurs vCenter Server ; 
        </li>
        <li>
        Description des règles d’application des autorisations ; création d’un rôle personnalisé dans vCenter Server ; création d’un plan de sauvegarde ; restauration de vCenter Server Appliance à partir d’une sauvegarde ; surveillance de vCenter Server Appliance.
        </li>
        </ul>
        `,
        "training_vmware_program_2_content_title_3": "Configuration et gestion des réseaux virtuels :",
        "training_vmware_program_2_content_text_3": `
        <ul>
        <li>
        Description, création et gestion de commutateurs standards ; 
        </li>
        <li>
        Configuration des politiques de sécurité et d’équilibrage de charge des commutateurs virtuels
        </li>
        <li>
        Comparaison des commutateurs distribués vSphere et des commutateurs standards ; description des types de connexion des commutateurs virtuels ; 
        </li>
        <li>
        Description de la nouvelle architecture à couches TCP/IP ; 
        </li>
        <li>
        Utilisation de réseaux locaux virtuels avec des commutateurs standards. 
        </li>
        </ul>`,
        "training_vmware_program_2_content_title_4": "Configuration et gestion d’un stockage virtuel : ",
        "training_vmware_program_2_content_text_4": `
        <ul>
        <li>
        Identification des protocoles de stockage et des types de dispositifs de stockage ; 
        </li>
        <li>
        Description des hôtes ESXi qui utilisent iSCSI, NFS et le stockage Fibre Channel ; 
        </li>
        <li>
        Création et gestion de magasins de données VMware vSphere® VMFS et NFS ; 
        </li>
        <li>
        Description des nouvelles fonctionnalités de VMFS 7.0 ;
        </li>
        <li>
        Identification des avantages de VMware vSAN™ ; description du cryptage de fichiers invités
        </li>
        </ul>
        `,
        "training_vmware_program_title_3": "3ème Journée",
        "training_vmware_program_3_content_title_1": "Gestion d’une machine virtuelle :",
        "training_vmware_program_3_content_text_1": `
        <ul>
        <li>
        Utilisation de modèles et du clonage pour déployer de nouvelles machines virtuelles ;
        </li>
        <li>
        Modification et gestion des machines virtuelles ; 
        </li>
        <li>
        Clonage d’une machine virtuelle ; mise à niveau d’une machine virtuelle vers la version 14 ; 
        </li>
        <li>
        Suppression de machines virtuelles de l’inventaire vCenter Server et d’un magasin de données ; 
        </li>
        <li>Utilisation de fichiers de spécifications pour personnaliser une nouvelle machine virtuelle ;</li>
        </ul>
        `,

        // Devops

        "training_devops_objectives": `
        <ul>
        <li>
        Définir les objectifs stratégiques et économiques d’une démarche DevOps ;
        </li>
        <li>
        Découvrir et assimiler le glossaire employé dans un environnement DevOps;
        </li>
        <li>
        Décrire les atouts pour l'entreprise et pour son infrastructure informatique;
        </li>
        <li>
        Définir et discuter des concepts clés et des principes de DevOps ;
        </li>
        <li>
        Expliquer comment DevOps se rapporte aux méthodologies Lean, Agile et aux outils ITSM ;
        </li>
        <li>
        Comprendre l'efficacité dans les flux de travail, dans la communication et dans les feedbacks ;
        </li>
        <li>
        Expliquer les concepts de l’automatisation des tests, de l’automatisation d’infrastructure, de l’automatisation de la chaîne d’approvisionnement ainsi que le déploiement et les outils DevOps associés ;
        </li>
        <li>
        Appréhender son déploiement à grande échelle dans une entreprise ;
        </li>
        <li>
        Découvrir et discuter des facteurs critiques de succès et des indicateurs de performance pour la mise en œuvre de DevOps ;
        </li>
        <li>
        Résumer des études de cas d’organisations informatiques qui effectuent la transition vers les modèles DevOps ;
        </li>
        <li>
        Passer et obtenir la certification DevOps Foundation de DevOps Institute®. 
        </li>
        </ul>
        `,
        "training_devops_required_level": "Personnes impliquées dans le développement informatique, les opérations informatiques ou la gestion des services informatiques comme les ingénieurs DevOps ou Agile, les Products Owners ou les gestionnaires d’incidents et de changements",
        "training_devops_concerned_public": "Pour suivre la formation DevOps Fondation il est nécessaire d’avoir le prérequis suivant : avoir une connaissance du framework ITIL, du Lean IT ou de l’agilité en entreprise est vivement conseillé.",
        "training_devops_program_title_1": "1ère Journée",
        "training_devops_program_content_title_1": "Introduction à DevOps :",
        "training_devops_program_content_text_1": `
        <ul>
        <li>
        La définition du DevOps et la transformation numérique ;
        </li>
        <li>
        L’importance d’une démarche DevOps en entreprise.
        </li>
        </ul>`,
        "training_devops_program_content_title_2": "Concepts fondamentaux de DevOps :",
        "training_devops_program_content_text_2": `
        <ul>
        <li>
        Les trois voies de l’approche DevOps 
        </li>
        <li>
        1re voie : le flux de travail ;
        </li>
        <li>
        La théorie des contraintes ;
        </li>
        <li>
        2e voie : les feedbacks ;
        </li>
        <li>
        3e voie : l’expérimentation continue et l'apprentissage ;
        </li>
        <li>
        Les principes de l’Ingénierie du Chaos ;
        </li>
        <li>
        Le concept de l’organisation apprenante.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_3": "Pratique de développement DevOps :",
        "training_devops_program_content_text_3": `
        <ul>
        <li>
        La livraison continue (Continuous Delivery) ;
        </li>
        <li>
        L’Ingénierie de fiabilité et l’ingénierie de la résilience ;
        </li>
        <li>
        L'approche DevSecOps ;
        </li>
        <li>
        L’outil de collaboration ChatOps ;
        </li>
        <li>
        La méthode Kanban.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_4": "Business et framework :",
        "training_devops_program_content_text_4": `
        <ul>
        <li>
        La démarche DevOps et l’agilité ;
        </li>
        <li>
        La gestion des services informatiques (ITSM) ;
        </li>
        <li>
        Le Lean IT et le DevOps ;
        </li>
        <li>
        L’intégration de la culture DevOps à la sécurité ;
        </li>
        <li>
        L'apprentissage organisationnel ;
        </li>
        <li>
        La gouvernance sociocratique et holacratique ;
        </li>
        <li>
        Le financement continu.
        </li>
        </ul>
        `,
        "training_devops_program_content_title_5": "Culture d’entreprise et modèles opérationnels :",
        "training_devops_program_content_text_5": `
        <ul>
        <li>
        La mise en œuvre de la culture d’entreprise ;
        </li>
        <li>
        Les modèles de bonne conduite ;
        </li>
        <li>
        Les modèles organisationnels ;
        </li>
        <li>
        Les modèles de fonctionnement des objectifs.
        </li>
        </ul>
        `,
        "training_devops_program_title_2": "2ème Journée",
        "training_devops_program_2_content_title_1": "Outils d’automatisation et architecture DevOps :",
        "training_devops_program_2_content_text_1": `
        <ul>
        <li>
        L'Intégration continue (CI/CD) ;
        </li>
        <li>
        Le Cloud Computing ;
        </li>
        <li>
        La technologie du conteneur ;
        </li>
        <li>
        L'automatisation du déploiement avec Kubernetes ;
        </li>
        <li>
        La chaîne d'outils du DevOps.
        </li>
        </ul>
        `,
        "training_devops_program_2_content_title_2": "Mesure du ROI et compte rendu :",
        "training_devops_program_2_content_text_2": `
        <ul>
        <li>
        L’importance des métriques Devops ;
        </li>
        <li>
        Les métriques techniques ;
        </li>
        <li>
        Les différentes métriques utilisées en entreprise ;
        </li>
        <li>
        Les indicateurs de performance et le compte rendu.
        </li>
        </ul>
        `,
        "training_devops_program_title_3": "3ème Journée",
        "training_devops_program_3_content_title_1": "Collaboration, apprentissage et développement",
        "training_devops_program_3_content_text_1": `
        <ul>
        <li>
        Les outils collaboratifs DevOps ;
        </li>
        <li>
        L'immersive Learning (apprentissage immersif) ;
        </li>
        <li>
        L’apprentissage expérientiel ;
        </li>
        <li>
        Le leader DevOps ;
        </li>
        <li>
        Le développement de la démarche DevOps.
        </li>
        </ul>
        `,
        "training_devops_program_3_content_title_2": "Examen de certification DevOps foundation (DOFD)",
        "training_devops_program_3_content_text_2": `
        <ul>
        <li>
        Type d’examen : 40 questions à choix multiples
        </li>
        <li>
        Durée : 1 heure
        </li>
        <li>
        Accessible en ligne 
        </li>
        <li>
        Livre ouvert : oui
        </li>
        <li>
        Langues : français, anglais, espagnol, allemand, chinois et polonais.
        </li>
        <li>
        Attribution : 60 % de bonnes réponses
        </li>

        </ul>`,

        // ITIL 4

        "training_itil_objectives": `Assimiler les définitions et concepts fondamentaux de la gestion de service; <br/>
        Comprendre les avantages d'ITIL™ pour une approche d'adoption, d'adaptation et d'optimisation des pratiques ; <br/>
        Connaître le système de valeur des activités de service ; <br/>
        Développer les 7 pratiques majeures d'ITIL™ ;  <br/>
        Comprendre les 8 autres pratiques ITIL™ et leur vocabulaire ; <br/>
        Réussir l'examen et obtenir la certification ITIL™ 4 Foundation.
        `,
        "training_itil_required_level": "Niveau d’étude minimum du secondaire, maitrise l’outil informatique.",
        "training_itil_concerned_public": "Toute personne utilisant Windows 10 sur son poste de travail et/ou une tablette.",
        "training_itil_program_title_1": "1ère journée",
        "training_itil_program_content_title_1": "MODULE 1 DE LA FORMATION ITIL : La gestion des services en tant qu'approche",
        "training_itil_program_content_text_1": `
            Aperçu Du Cours <br/>
            Objectifs du cours <br/>
            Résultats attendus <br/>
            <strong>Unité 1: Concepts de la gestion des services</strong>
             <br/>
            Le développement efficace de nouveaux services et l'amélioration des services existants <br/>
            Bonne pratique <br/>
            Fonctions, rôles et processus <br/>
            <strong>Unité 2: Le cycle de vie des services</strong>
             <br/>
            La conception, le développement et l'utilisation permanents des services <br/>
            Utilité et garantie <br/>
            La conception de services et le métier de l'entreprise <br/>
            Les NTIC et leur intégration au métier
        `,
        "training_itil_program_content_title_2": "MODULE 2 DE LA FORMATION ITIL : Le cycle de vie des services ITIL",
        "training_itil_program_content_text_2": `
            Aperçu Du Cours <br/>
            Objectifs du cours <br/>
            Résultats attendus <br/>
            <strong>Unité 1: Les cinq processus principaux</strong> <br/>
            Stratégie de services <br/>
            Conception de services <br/>
            Transition de services <br/>
            Exploitation de services <br/>
            Amélioration continue de services <br/>
            <strong>Unité 2: La valeur du cycle de vie des services ITIL</strong> <br/>
            Intégrer les processus tout au long du cycle de vie <br/>
            Expliquer les objectifs et le périmètre de chaque phase
        `,
        "training_itil_program_title_2": "2eme journée",
        "training_itil_program_2_content_title_1": "MODULE 3 DE LA FORMATION ITIL : Concepts fondamentaux d'ITIL",
        "training_itil_program_2_content_text_1": `
            <strong>Unité 1: Identifier et documenter les services</strong> <br/>
            Portefeuille de services <br/>
            Catalogue <br/>
            Dossier métier <br/>
            Risques <br/>
            Fournisseur de service <br/>
            Prestataire <br/>
            Accord sur les niveaux de service (SLA) <br/>
            Accord sur les niveaux opérationnels (OLA) <br/> <br/>
            <strong>Unité 2: Optimisation de l'infrastructure</strong> <br/>
            Demande de service <br/>
            Changement et mise en production <br/>
            Événement, alerte et incident <br/>
            Contourner les problèmes <br/>
            Erreurs connues et base de données des erreurs connues (KEDB) <br/>
            Système de gestion des connaissances (SKMS) <br/>

        `,
        "training_itil_program_2_content_title_2": "MODULE 4 DE LA FORMATION ITIL : Principes et modèles clés d'ITIL",
        "training_itil_program_2_content_text_2": `
            <strong>Unité 1 : Création de valeur à travers les services</strong> <br/>
            Équilibrer les forces contraires <br/>
            Systèmes et outils de gestion d'informations <br/>
            <strong>Unité 2 : Explorer l'importance des personnes, des processus, des produits et des partenaires</strong> <br/>
            Facteurs clés de succès <br/>
            Méthodes de mesure et métriques <br/>
            3ème journée <br/>
        `,
        "training_itil_program_title_3": "3ème journée",
        "training_itil_program_3_content_title_1": "MODULE 5 DE LA FORMATION : Processus ITIL",
        "training_itil_program_3_content_text_1": `
            <strong>Unité 1: Stratégie de services</strong> <br/>
            Les trois types de fournisseurs de services <br/>
            Gestion de portefeuille de services <br/>
            Gestion financière pour les services informatiques <br/>
            Gestion des relations métier <br/> <br/>
           <strong> Unité 2 : Conception de services</strong> <br/>
            Gestion des niveaux de service <br/>
            Coordination de la conception <br/>
            Gestion du catalogue de services <br/>
            Gestion des fournisseurs <br/>
            Évaluation des risques et gestion de la continuité des services informatiques <br/>
            Définir le périmètre de la gestion de la sécurité de l'information <br/>
            Gestion de la capacité <br/>
            Gestion de la disponibilité <br/> <br/>
            <strong>Unité 3 : Transition de services</strong> <br/>
            Gestion de la valeur métier, des actifs et des configurations <br/>
            Expliquer les objectifs de la gestion des changements <br/>
            Gestion des connaissances <br/>
            Planification de la transition <br/>
            Gestion des déploiements et des mises en production <br/>
            <strong>Unité 4 : Exploitation de services</strong> <br/>
            Définir les processus de la gestion des incidents et des problèmes <br/>
            Exécution de requêtes <br/>
            Objectifs de la gestion des événements et des accès <br/> <br/>
            <strong>Unité 5 : Amélioration continue des services</strong> <br/>
            Le processus d'amélioration en sept étapes <br/>
            Le cycle de Deming (planifier, faire, vérifier, agir) <br/>
            Facteurs clés de succès et indicateurs clé de performanceTypes de métrique <br/> <br/>
            <strong>Unité 6: Fonctions et rôles de la gestion des services</strong> <br/>
            Présentation des différentes fonctions dans l'entreprise <br/>
            Fonction centre de services <br/>
            Fonction opérations IT <br/>
            Fonction gestion technique <br/>
            Fonction gestion des applications <br/> <br/>
            <strong>Unité 7: des rôles de services</strong> <br/>
            Les responsabilités des rôles clés dans la gestion des services <br/>
            Propriétaire de processus <br/>
            Propriétaire de services <br/>
            Gestionnaire des processus <br/>
            Praticien des processus <br/>
        `,
        "training_itil_program_title_4": "4ème journée",
        "training_itil_program_4_content_title_1": "",
        "training_itil_program_4_content_text_1": `
        Passer la certification Professional ITIL v4. <br/>
        Des identifiants seront fournis aux participants. <br/>
        Nécessite uniquement un PC connecté à internet.`,

        // cobit

        "training_cobit_objectives": `
        <ul>
        <li>
        Assurer l’identification et la gestion de tous les risques informatiques
        </li>
        <li>
        Gérer les dépendances vis-à-vis de fournisseurs de services externes, 
        </li>
        <li>
        Offrir une assurance informatique et assure l’existence d’un système de contrôle interne efficace et efficient.
        </li>
        <li>
        Fournir des conseils sur la meilleure manière de construire et de structurer le service informatique, de gérer les performances du service informatique, de gérer efficacement les opérations informatiques, de contrôler les coûts informatiques, d'aligner la stratégie informatique sur les priorités de l'entreprise, etc.
        </li>
        <li>
        Fournir des informations sur la façon de tirer parti de l'utilisation d'I&T et explique les responsabilités pertinentes du conseil d'administration
        </li>
        <li>
        Fournir des conseils sur la manière d’organiser et de surveiller les performances d’I&T dans l’entreprise.
        </li>
        <li>
        Aide à comprendre comment obtenir les solutions I&T dont les entreprises ont besoin et comment exploiter au mieux les nouvelles technologies pour saisir des opportunités stratégiques
        </li>
        </ul>
        `,
        "training_cobit_required_level": "Niveau d’étude minimum du secondaire, maitrise l’outil informatique.",
        "training_cobit_concerned_public": "Chef de projets, Chef de projets digitaux, Développeur",
        "training_cobit_program_title_1": "1ère Journée",
        "training_cobit_program_content_title_1": "Module 1 de la formation COBIT 5",
        "training_cobit_program_content_text_1": `
            <strong>Introduction générale à COBIT®</strong>

            <ul>
            <li>
            Histoire et Objectifs de COBIT®.
            </li>
            <li>
            Le Champ d’application de COBIT®.
            </li>
            <li>
            Recherche, développement, publication et promotion d’un ensemble d’objectifs de contrôles du SI.
            </li>
            <li>
            Personnes visées par COBIT
            </li>
            <li>
            La création de valeur.
            </li>
            <li>
            L’importance du concept de la gouvernance
            </li>
            <li>
            Le lien entre la loi Sarbanes-Oxley et COBIT®.
            </li>
            <li>
            Brève présentation de COSO.
            </li>
            </ul>
        `,
        "training_cobit_program_title_2": "2ème Journée",
        "training_cobit_program_2_content_title_1": "Module 2 de la formation COBIT 5",
        "training_cobit_program_2_content_text_1": `
            <strong>Principaux concepts de COBIT</strong>
            <ul>
            <li>
            Domaine de processus. Processus et approche processus.
            </li>
            <li>
            Description d’un processus. Contexte. Flux. Contrôle.
            </li>
            <li>
            Relations entre les objectifs de l’entreprise.
            </li>
            <li>
            Veiller à ce que les SI fournissent les informations dont l’entreprise a besoin.
            </li>
            <li>
            Maitriser les risques et sécuriser les ressources informatiques.
            </li>
            <li>
            Assurer la contribution du SI au business de l’entreprise.
            </li>
            <li>
            Indicateurs clés d’objectifs. Indicateurs clés de performance.
            </li>
            <li>
            Gouvernance IT selon COBIT®. Modèle de maturité.
            </li>
            </ul>
        `,
        "training_cobit_program_2_content_title_2": "Module 3 de la formation COBIT 5",
        "training_cobit_program_2_content_text_2": `
            <strong>Architecture du référentiel</strong>
            <ul>
            <li>
            Etude de l’architecture du référentiel.
            </li>
            <li>
            Le cube COBIT®.
            </li>
            <li>
            Les processus IT, les ressources IT et les exigences business.
            </li>
            <li>
            Introduction aux quatre domaines et aux trente-quatre processus de COBIT®.
            </li>
            <li>
            Naviguer dans la documentation COBIT 5.
            </li>
            </ul>

        `,
        "training_cobit_program_title_3": "3ème Journée",
        "training_cobit_program_3_content_title_1": "Module 4 de la formation COBIT 5",
        "training_cobit_program_3_content_text_1": `
            <strong>Etude détaillée des domaines de processus et utilisation de COBIT®</strong>
            <ul>
            <li>
            Les domaines de processus.
            </li>
            <li>
            Domaines : planification et organisation.
            </li>
            <li>
            Acquisition et mise en place. Livraison et support. Surveillance et évaluation.
            </li>
            <li>
            Utilisation pratique du référentiel.
            </li>
            <li>
            L’évaluation de la maturité.
            </li>
            </ul>

        `,
        "training_cobit_program_title_4": "4ème Journée",
        "training_cobit_program_4_content_title_1": "Module 5 de la formation COBIT 5",
        "training_cobit_program_4_content_text_1": `
        <strong>COBIT® et les autres référentiels de bonnes pratiques</strong>
        <ul>
        <li>
        Situer COBIT® parmi les autres référentiels de bonnes pratiques.
        </li>
        <li>
        Aperçu sur les référentiels CMMi, ITIL®, Balanced ScoreCard et la norme ISO 27001
        </li>
        <li>
        Examen de certification 
        </li>
        </ul>
        `,

        // iso-27001

        "training_iso_27001_objectives": `
        <ul>
        <li>
        Maîtriser les concepts, pratiques et techniques pour implémenter et gérer un Système de Management de la Sécurité de l’Information ;
        </li>
        <li>
        Appliquer les directives de la norme ISO 27001 dans un cas particulier au sein d'une organisation ;
        </li>
        <li>
        Accompagner une organisation dans la planification, la mise en œuvre, la gestion, la surveillance et la mise à jour du SMSI ;
        </li>
        <li>
        Conseiller une organisation sur les pratiques relatives au SMSI. 
        </li>
        <li>
        Toutes les compétences que vous acquerrez pourront s’appliquer aux organisations de toute taille et de toute nature afin de leur offrir les avantages suivants :
        <ul>
        <li>
        une sensibilisation en matière de sécurité de l'information améliorée ;
        </li>
        <li>
        une réduction des failles de sécurité ;
        </li>
        <li>
        un avantage concurrentiel ;
        </li>
        <li>
        une crédibilité et une confiance ;
        </li>
        <li>
        une conformité aux lois et aux règlements associés.
        </li>
        </ul>
        </li>
        </ul>
        `,
        "training_iso_27001_required_level": "Suivre la formation ISO 27001 Lead Implementer nécessitent les prérequis suivants : être impliqué dans la sécurité de système d'information ; connaitre les principes fondamentaux de la norme ISO 27001 et de son application. ",
        "training_iso_27001_concerned_public": `
        Les personnes amenées à travailler dans le management de la continuité d’activité (gestionnaires de risques, consultants, etc.) ; les personnes qui souhaitent maîtriser la mise en place d'un SMSI ; les personnes responsables de mise en conformité d'un SMSI ; les personnes qui font parties d’une équipe SMSI.
Cette formation s'adresse aux profils suivants Administrateur système Architecte informatique / SI Ingénieur système Auditeur interne / externe Chef de projet / Responsable de projet Contrôleur de gestion Directeur des Systèmes d'Information (DSI)`,
        "training_iso_27001_program_title_1": "Jour 1 :  ",
        "training_iso_27001_program_content_title_1": "",
        "training_iso_27001_program_content_text_1": `
        <ul>
        <li>
        Introduction au système de management et de l'approche processus ; 
        </li>
        <li>
        Présentation des normes ISO 27001:2013, ISO 27002:2013 et ISO 27003:2017 ; 
        </li>
        <li>
        Les principes fondamentaux de la sécurité de l'information ; 
        </li>
        <li>
        L'analyse préliminaire et l'établissement du niveau de maturité d'un système de management de la sécurité de l'information existant basée sur la norme ISO 21827:2008 ; 
        </li>
        <li>
        La rédaction de la rentabilité et la planification de la mise en œuvre d'un SMSI.
        </li>
        </ul>
        `,
        "training_iso_27001_program_title_2": "Jour 2 ",
        "training_iso_27001_program_2_content_title_1": "",
        "training_iso_27001_program_2_content_text_1": `
        <ul>
        <li>
        Définition de la portée d'un SMSI ; 
        </li>
        <li>
        Implémentation d'un SMSI et politiques de sécurité de l'information ; 
        </li>
        <li>
        Sélection de l'approche et de la méthodologie d'évaluation des risques ; 
        </li>
        <li>
        La gestion des risques : l'identification, l'analyse et le traitement des risques (en s'inspirant des orientations de la norme ISO 27005:2011) ; 
        </li>
        <li>
        La rédaction de la Déclaration de l'Applicabilité (DdA).
        </li>
        </ul>
        `,
        "training_iso_27001_program_title_3": "Jour 3 ",
        "training_iso_27001_program_3_content_title_1": "",
        "training_iso_27001_program_3_content_text_1": `
        <ul>
        <li>
        La mise en œuvre du cadre de gestion documentaire ; 
        </li>
        <li>
        La conception des mesures et les procédures de rédaction ; 
        </li>
        <li>
        La mise en œuvre des mesures ; 
        </li>
        <li>
        Le développement d'un programme de formation, de sensibilisation et de communication sur la sécurité de l'information ; 
        </li>
        <li>
        La gestion des incidents (fondée sur la norme ISO 27035:2016) ; 
        </li>
        <li>
        La gestion des opérations d'un SMSI.
        </li>
        </ul>
        `,
        "training_iso_27001_program_title_4": "Jours 4 et 5",
        "training_iso_27001_program_4_content_title_1": "",
        "training_iso_27001_program_4_content_text_1": `
        <ul>
        <li>
        Le contrôle et le suivi du SMSI ; 
        </li>
        <li>
        L'élaboration de mesures, les indicateurs de performance et les tableaux de bord en conformité avec l'ISO 27004:2016 ; 
        </li>
        <li>
        L'audit interne du SMSI ;
        </li>
        <li>
        L'examen de la gestion d'un SMSI 
        </li>
        <li>
        La mise en œuvre d'un programme d'amélioration continue ; la préparation pour un audit de certification ISO 27001:2013. Dernière demi-journée : 
        </li>
        <li>
        Passage de l'examen de certification ISO 27001 Lead Implementer (durée : 3 heures).
        </li>
        </ul>
        `,

        // Fortinet firewall

        "training_desc_11": "Fortinet, fournisseur de solutions de sécurité, est le leader mondial de la gestion unifiée des menaces (Unified Threat Management). Son offre couvre les matériels et logiciels et s'adresse tant aux entreprises - PME, grandes entreprises, organisations multisites - qu'aux opérateurs et fournisseurs de services de communication.",
        "training_duration_11": "15 jours",
        "training_fortinet_firewall_objectives": `
        Dans ce cours, vous apprendrez dans un premier temps à utiliser les fonctionnalités de base de FortiGate, notamment les
        profils de sécurité.
        Dans des laboratoires interactifs, vous explorerez les politiques de pare-feu, la Security Fabric de
        Fortinet, l'authentification des utilisateurs, le VPN SSL, et comment protéger votre réseau à l'aide
        de profils de sécurité, tels que l'IPS, l'antivirus, le filtrage Web, le contrôle des applications, etc.
        Ces bases d'administration vous permettront d'acquérir une solide compréhension de la mise en
        œuvre d'une sécurité réseau de base. <br/> 
        Dans un second temps, vous apprendrez à utiliser la mise en réseau et la sécurité avancées de FortiGate.
        Les sujets abordés incluent des fonctionnalités généralement appliquées dans des réseaux
        d'entreprise ou MSSP complexes ou plus importants, telles que le routage avancé, le mode
        transparent, l'infrastructure redondante, le VPN IPsec site à site, l'authentification unique (SSO) et
        les diagnostics.
        `,
        "training_fortinet_firewall_title_1": "FortiGate Security",
        "training_fortinet_firewall_program_content_title_1": "Durée du cours",
        "training_fortinet_firewall_program_content_text_1": `
        <ul>
            <li>Temps de cours (estimé) : 20 heures</li>
            <li>Temps de laboratoire (estimé) : 18 heures</li>
            <li>Durée totale du cours (estimée) : 38 heures / 08 jours</li>
        </ul>
        `,
        "training_fortinet_firewall_program_content_title_2": "Qui devrait participer",
        "training_fortinet_firewall_program_content_text_2": `Les professionnels des réseaux et de la sécurité impliqués dans la conception, la mise en œuvre
        et l'administration d'une infrastructure réseau utilisant des dispositifs FortiGate devraient suivre ce
        cours.
        Ce cours suppose une connaissance des principes fondamentaux de FortiGate. Vous devez avoir
        une compréhension approfondie de tous les sujets abordés dans le cours FortiGate Security avant
        de suivre le cours FortiGate Infrastructure`,
        "training_fortinet_firewall_level": `<ul>
        <li>Compréhension de base des concepts de pare-feu</li>
        <li>Connaissance des protocoles de réseau </li>
        </ul>`,
        "training_fortinet_firewall_concerned_public": `Les professionnels des réseaux et de la sécurité impliqués dans la conception, la mise en œuvre
        et l'administration d'une infrastructure réseau utilisant des dispositifs FortiGate devraient suivre ce
        cours.
        Ce cours suppose une connaissance des principes fondamentaux de FortiGate. Vous devez avoir
        une compréhension approfondie de tous les sujets abordés dans le cours FortiGate Security avant
        de suivre le cours FortiGate Infrastructure.`,
        "training_fortinet_firewall_program_content_title_3": `Certification`,
        "training_fortinet_firewall_program_content_text_3": `Ce cours et le cours FortiGate Infrastructure sont destinés à vous aider à préparer l'examen de
        certification NSE 4.`,
        "training_fortinet_firewall_program_content_title_4": `Agenda`,
        "training_fortinet_firewall_program_content_text_4": `
        <ol>
        <li>Introduction et configuration initiale</li>
        <li>Tissu de sécurité</li>
        <li>Règles de pare-feu</li>
        <li>Traduction d'adresses réseau (NAT)</li>
        <li>Authentification par pare-feu</li>
        <li>Journalisation et surveillance</li>
        <li>Opérations de certificat</li>
        <li>Filtrage Web</li>
        <li>Contrôle des applications</li>
        <li>Antivirus</li>
        <li>Prévention des intrusions et déni de service</li>
        <li>VPN SSL</li>
        </ol>
        `,
        "training_fortinet_firewall_program_title_2": `FortiGate Infrastructure`,
        "training_fortinet_firewall_program_2_content_title_1": `Durée du cours`,
        "training_fortinet_firewall_program_2_content_text_1": `
        <ul>
        <li>Temps de cours (estimé) : 20 heures</li>
        <li>Temps de laboratoire (estimé) : 17 heures</li>
        <li>Durée totale du cours (estimée) : 37 heures / 7 jours</li>
        </ul>
        `,
        "training_fortinet_firewall_program_2_content_title_2": "Certification",
        "training_fortinet_firewall_program_2_content_text_2": `Ce cours et le cours FortiGate Security sont destinés à vous aider à préparer l'examen de
        certification NSE 4.`,
        "training_fortinet_firewall_program_2_content_title_3": `Agenda`,
        "training_fortinet_firewall_program_2_content_text_3": `
        <ol>
        <li>Routage</li>
        <li>Répartition locale SD-WAN</li>
        <li>Domaines virtuels (VDOM)</li>
        <li>Commutation de couche 2</li>
        <li>VPN IPsec</li>
        <li>Authentification unique Fortinet (FSSO)</li>
        <li>Haute disponibilité (HA)</li>
        <li>Diagnostics</li>
        </ol>`,
        // identity-services-microsoft-365
        "training_title_10'": "IDENTITE ET SERVICES MICROSOFT 365",
        "training_desc_10": "Le cours de formation MS-203T00-A : Microsoft 365 Messaging - (Officiel) est idéal pour les administrateurs de messagerie ou ceux qui aspirent à en devenir un dans différents environnements basés sur le cloud ou sur site. Le rôle de ceux qui terminent le cours de formation à la certification s'articule autour du déploiement, de la gestion, de la configuration et du dépannage des entreprises. ",
        "training_duration_10": "10 jours",
        "training_identity_services_microsoft_365_objectives": `
        <ul>
        <li>
        Déploiement, de la gestion, de la configuration et du dépannage des entreprises. 
        </li>
        <li>
        Gérer l'infrastructure de messagerie, la configuration hybride, la migration,
        </li>
        <li>
        Gérer l'efficacité opérationnelle
        </li>
        <li>
        Configuration, la conception, la gestion et la sécurité dans Office 365.
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_required_level": "Niveau d’étude minimum du secondaire, maitrise l’outil informatique.",
        "training_identity_services_microsoft_365_concerned_public": "Administrateurs de serveur, Administrateurs réseau",
        "training_identity_services_microsoft_365_program_title_1": "1ère Journée",
        "training_identity_services_microsoft_365_program_content_title_1": "Module 1 : Concevoir votre locataire Microsoft 365",
        "training_identity_services_microsoft_365_program_content_text_1": `
        <ul>
            <li>
            Planifiez votre locataire dans Microsoft 365
            </li>
            <li>
            Planifiez votre infrastructure locale pour Microsoft 365
            </li>
            <li>
            Planifiez votre solution d'identité et d'authentification pour Microsoft 365
            </li>
            <li>
            Planifiez votre infrastructure de prise en charge pour Microsoft 365
            </li>
            <li>
            Planifiez vos exigences hybrides pour votre déploiement Microsoft 365
            </li>
            <li>
            Planifiez votre migration vers Microsoft 365
            </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_content_title_2": " Module 2 : Configurer votre locataire Microsoft 365",
        "training_identity_services_microsoft_365_program_content_text_2": `
        <ul>
        <li>
        Configurez votre expérience Microsoft 365
        </li>

        <li>
        Gérer les comptes d'utilisateurs et les licences dans Microsoft 365
        </li>
        <li>
        Gérer les groupes dans Microsoft 365
        </li>
        <li>
        Ajouter un domaine personnalisé dans Microsoft 365
        </li>
        <li>
        Configurer la connectivité client à Microsoft 365
        </li>
        <li>
        Utiliser FastTrack et les services partenaires pour prendre en charge Microsoft 365
        </li>
        <li>
        Atelier : Configurez votre locataire Microsoft 365
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_title_2": "2ème Journée",
        "training_identity_services_microsoft_365_program_2_content_title_1": "Module 3 : Gérer votre locataire Microsoft 365",
        "training_identity_services_microsoft_365_program_2_content_text_1": `
            <ul>
            <li>
            Configurer les rôles administratifs dans Microsoft 365
            </li>
            <li>
            Gérer la santé et les services des locataires dans Microsoft 365
            </li>
            <li>
            Gérer les déploiements d'applications Microsoft 365 pour les entreprises pilotés par l'utilisateur
            </li>
            <li>
            Mettre en œuvre la télémétrie de bureau
            </li>
            <li>
            Mettre en œuvre l'analyse du lieu de travail
            </li>
            <li>
            Atelier : Gérer votre locataire Microsoft 365
            </li>
            </ul>
        `,
        "training_identity_services_microsoft_365_program_title_3": "3ème Journée",
        "training_identity_services_microsoft_365_program_3_content_title_1": "Module 4 : Explorer les services de la plateforme Microsoft 365",
        "training_identity_services_microsoft_365_program_3_content_text_1": `
            <ul>
            <li>
            Explorez Exchange Online en tant qu'administrateur Microsoft 365 Enterprise
            </li>
            <li>
            Explorez SharePoint Online en tant qu'administrateur Microsoft 365 Enterprise
            </li>
            <li>
            Explorez Microsoft Teams en tant qu'administrateur Microsoft 365 Enterprise
            </li>
            <li>
            Explorez Microsoft Power Platform en tant qu'administrateur Microsoft 365 Enterprise
            </li>
            <li>
            Créer des applications en tant qu'administrateur Microsoft 365 Enterprise avec Power Apps
            </li>
            <li>
            Créer des flux en tant qu'administrateur Microsoft 365 Enterprise avec Power Automate
            </li>
            <li>
            Créer des rapports et des tableaux de bord en tant qu'administrateur Microsoft 365 Enterprise avec Power BI
            </li>
            <li>
            Créer des chatbots en tant qu'administrateur Microsoft 365 Enterprise avec Power Virtual Agents
            </li>
            <li>
            Atelier : Examen des fonctionnalités d'Office 365
            </li>
            </ul>
        `,
        "training_identity_services_microsoft_365_program_title_4": "4ème Journée",
        "training_identity_services_microsoft_365_program_4_content_title_1": "Module 5 : Planifier et mettre en œuvre la synchronisation des identités",
        "training_identity_services_microsoft_365_program_4_content_text_1": `
        <ul>
        <li>
        Explorer la synchronisation des identités
        </li>
        <li>
        Planifier la mise en œuvre d'Azure AD Connect
        </li>
        <li>
        Implement Azure AD Connect
        </li>
        <li>
        Manage synchronized identities
        </li>
        <li>
        Explore password management in Microsoft 365
        </li>
        <li>
        Lab : Manage Identities
        </li>
        </ul>
        `,
        "training_identity_services_microsoft_365_program_title_5": "5ème Journée",
        "training_identity_services_microsoft_365_program_5_content_title_1": "Module 6 : Implémenter des applications et un accès externe dans Azure AD",
        "training_identity_services_microsoft_365_program_5_content_text_1": `
        <ul>
        <li>
        Implémenter des applications dans Azure AD
        </li>
        <li>
        Configurer le proxy d'application Azure AD
        </li>
        <li>
        Explorer les solutions d'accès externe
        </li>
        </ul>
        `,
        "do_you_want_to_follow_this_training": "Vous souhaitez suivre cette formation ?",
        "training_availability": "Cette formation est disponible en présentiel ou en classe à distance, avec un programme et une qualité pédagogique identiques.",
        "choose_modality": "Choisissez la modalité souhaitée pour vous inscrire :",
        "follow_training_in_presence": "Suivre cette formation en presentiel",
        "follow_training_in_online": "Suivre cette formation a distance",
        "your_company_need_special_offer": "Votre société a besoin d'une offre personnalisée ? ",
        "contact_us": "Contactez nous",
        "please_wait": "Patienter...",
        "posted_subscriptions": "Merci pour votre abonnement.",
        "error_posted_subscriptions": "Votre abonnement a echoué, veuiller réessayer.",
        "training_title_10": "Services d'identité Microsoft 365",
        "ask_for_a_training": "Demander une formation",
        "are_you_entreprise": "Etes vous une entreprise ?",
        "only_entr_can_subscribe": "Cette option n'est reservée qu'aux entreprises.",
        "yes": "Oui",
        "no": "No",
        "custom_training_enter_your_informations": "Entrer vos informations pour commander votre formation personnalisée",
        "home_contact_training_title": "Titre de la formation souhaitée",
        "let_know_our_trainings": "Retrouvez toutes nos formations sur differents sujets",
        "certif_trainings": "Formations certifiantes",
        "filter": "Filtrer",
        "subscription_not_entreprise": "Pour votre offre personnalisée, veuillez nous contacter au numéro suivant: (+237) 6 96 42 77 03, ou par email a l'adresse email suivante: contact@ova-consulting.fr",
        "obtain_estimation": "Obtenir un devis",
        "obtain_estimation_success": "Vos informations ont bien été soumises, nous vous recontacterons au plus vite.",
        "Network expert and Azure Specialist": "Expert réseau et spécialiste azure.",
        "see_more": "Voir plus",
        "see_less": "Voir moins",
        "starting_date": "Date de début souhaitée",
        "ending_date": "Date de fin souhaitée",
        "products_text_descr": "En plus de développer des solutions su-mesure pour nos clients et partenaires, nous mettons également sur pied nos propres solutions dans le but de solutionner certains problèmes rencontrés par la population.",
        "home_product_ovacom_descr": "OVACOM, est une solution de gestion commerciale, pensée pour les grandes surfaces commerciales, dans le but de faciliter la gestion des stocks, la facturation, la fidélisation des clients, etc... Cette solution facilite de facon considérable le quotidien des gestionnaires.",
        "home_product_ovaged_descr": "OVAGED, la solution d'archivage intélligente. En effet, cette solution permet aux entreprises de pourvoir dématerialiser et traiter leurs document dee facon intélligente. Elle est intelligente dans la mesure où elle traite tous les documents grâce à son module d'intélligence artificielle intégré.",
        "success_story_text_2": "Développement d'une solution de Dématérialisation.",
        "success_story_text_3": "Implémentation d'une solution API management.",
        "success_story_text_4": "Fourniture d'équipement et virtualisation pour le Compte de LBS.",
        "creative_company": `<p class="title-1 h3">
        Entreprise créative 
    </p>
    <p class="title-2 h3">
        et technologique
    </p>`,
    "what_tech_we_use": "Quelles technologies utilisons nous?",
    "how_receip": "Notre recette pour un produit réussi",
     "understand_concept": "Comprendre le contexte",
     "understand_concept_text": "Tout d'abord, nous évaluons votre processus de développement actuel et définissons les objectifs commerciaux que vous souhaitez atteindre avec certaines technologies, puis proposons une solution et une stratégie de mise en œuvre.",
     "prepare_data": "Préparer les données et Prototypage",
     "prepare_data_text": "Ensuite, nous créons un modèle expérimental simple de votre produit proposé afin que vous puissiez vérifier dans quelle mesure il correspond aux attentes des utilisateurs grâce aux commentaires qu'ils donnent.",
     "scale_iterate": "Mettre à l'échelle, itérer et tester",
     "scale_iterate_text": "Dès qu'un ensemble minimum de fonctionnalités est atteint, un MVP est publié. Ensuite, chaque nouvelle fonctionnalité est publiée dans un déploiement entièrement automatisé.",
     "Storage": "Stockage",
     "Mining": "Minage",
     "Analytics": "Analytiques",
     "Visualization": "Visualisation",
    "Define the problem": "Définition du problème",
    "Collect data": "Collection de données",
    "Analyze data": "Analyse de données",
    "Incorporate in business": "Intégraton",
    "Validate the results": "Validation des résultats",
    "Prepare data": "Préparation des données",
    "infrastructure_cloud_text": "L'infrastructure cloud est la couche de logiciels et de matériel entre vos systèmes internes et le cloud public. Cette couche d'infrastructure dans le cloud s'est développée au fur et à mesure que le cloud public a modifié la structure du centre de données et de son matériel.",
    "infra_infor_support": "Support informatique",
    "infra_infor_support_text": "Le support informatique est aujourd’hui devenu indispensable à toutes les entreprises, et notamment pour les PME, qui souhaitent disposer d’un environnement de travail optimisé, sécurisé, au service de leurs collaborateurs mais également de leurs clients. OVA Consulting se positionne comme un prestataire externe de choix pour tous vos soucis informatiques.",
    "infra_w_servers_text": "Tirez le meilleur parti de votre environnement Windows Server grâce à l’expertise et l’expérience de nos spécialistes. Faites évoluer l’infrastructure de votre datacenter pour bénéficier d’une efficacité et d’une sécurité accrues.    ",
    "infra_vmware_text": "OVA Consulting s'est spécialisé dans la virtualisation avec VMware et propose pour ses clients la solution vSphere, elle permet aux entreprises petites ou grandes de créer des serveurs virtuels multicœurs puissants et garantie une couche de virtualisation extrêmement fiable.",
    "infra_fortinet_text": "Protégez votre infrastructure de bout en bout, sans compromis de performance, contrôlez et sécurisez votre accès internet avec les firewall nouvelle génération FORTINET",
    "infra_material_fournit": "Fourniture des équipements informatiques",
    "infra_material_fournit_text": "Nous vous accompagnons depuis l'achat de votre matériel, à son installation, tout en vous garantissant la qualité.",
    "Initiations / Fundamentals": "Initiations / Fundamentaux",
    "Perfectionnement / Advanced": "Perfectionnement / Advancé",
    "Days": "Jours",
    "our_trainings": "Nos formations",
    "our_experties": "Nos expertises",
    "korione_project_description": "Le développement de l’internet est en train de créer une nouvelle économie appelée : Économie Numérique. Cette expression renvoie aux technologies de l’information et de lacommunication, à la production et à la vente des produits et services numériques via le web et/ou les réseaux sociaux. Dans cet environnement, nous avons constaté que la vente des",
    "ess_project_description": `Notre rôle de banquier d’affaires est de vous accompagner tout au long de la réalisation de vos projets. <br /> Cela se traduit par l’accueil, l’écoute, le conseil et l’expertise qui sont des piliers de notre savoir-faire.Nous nous sommes donnés pour missions principales de :`,
    "solar_project_description": "Solar Hut a été fondé par Emmanuel Monthe Siewe Lors d'une tournée dans les régions rurales du Cameroun, il a vu des habitants lutter contre la pénurie d'énergie, de nombreux enfants étudiant à la lumière d'un téléphone portable ou avec des bougies. Conscient de la technologie de l'énergie solaire, il a d'abord fait don de lampes solaires pour aider les différentes communautés qu'il avait visitées. Cependant, une fois qu'il a vu à quel point le problème énergétique était endémique dans d'autres régions, il a formé une petite équipe et lancé un projet pilote. En 2019, Solar Hut a été officiellement formé. Aujourd'hui Solar Hut emploie plus de 50 personnes et a eu un impact sur la vie de plus de 5000 personnes.",
    "our_methodology": "Notre méthodologie",
    "expertise_methodology_poc": "POC",
    "expertise_methodology_poc_text": `Le POC (Proof Of Concept)
    est défini à travers 2 à 4 workshops.
    Nous cadrons les spécifications, le planning et le budget par soucis de rigueur et de sécurité.`,
    "expertise_methodology_team": "L'équipe",
    "expertise_methodology_team_text": "L’équipe projet est constituée d’un chef de projet et d’experts. Côté client, un spécialiste métier est indispensable et un responsable data est recommandé. La disponibilité du spécialiste métier est un des facteurs de réussite d’un projet data science.",
    "expertise_methodology_deliverables": "Livrables",
    "expertise_methodology_deliverables_text": "Les livrables sont échelonnés sur des jalons clés et validés par des SOW (Statement Of Work).",
    "expertise_methodology_industrialisation": "Industrialisation",
    "expertise_methodology_industrialisation_text": "L’industrialisation du modèle est réalisée si le client souhaite mettre à l’échelle. Un monitoring des résultats du modèle et une maintenance permet de faireévoluer le modèle face à la dérive des données.",
    "expertise_software_planing": "Cadrage bu besoin",
    "expertise_software_text_planing": "Nous planifions une rencontre avec le client et les parties prenantes pour comprendre, suivre et contrôler le développement du projet logiciel.",
    "expertise_software_analysis": "Analyse",
    "expertise_software_analysis_text": "Nous définissons quelles décisions de conception et de pile technologique conduiront à une architecture logicielle qui répond avec succès aux qualités système souhaitées.",
    "expertise_software_design": "Conception",
    "expertise_software_design_text": "Le concepteur d'UI est principalement responsable de l'apparence visuelle de l'interface, tandis que le concepteur d'UI s'assure que la navigation et les actions effectuées par l'utilisateur sont cohérentes.",
    "expertise_software_implementation": "Implémentation",
    "expertise_software_implementation_text": "Nous fournissons une implémentation de solution de bout en bout capable de résoudre même les problèmes les plus complexes.",
    "expertise_software_testing_integration": "Tests & intégration",
    "expertise_software_testing_integration_text": "Nous examinons les artefacts et le comportement du logiciel testé par validation et vérification.",
    "expertise_software_maintenance": "Maintenance",
    "expertise_software_maintenance_text": "Nous pouvons être responsables de l'évolution, de la modification et de la mise à jour des logiciels pour répondre aux besoins des clients.",
    "expertise_design_understand": "Comprendre les parties prenantes",
    "expertise_design_understand_text": "L'objectif est d'anticiper et de prédire leurs réactions à votre projet au fur et à mesure de son évolution. Il est donc important de connaître en profondeur le contexte dès le départ.",
    "expertise_design_prototype": "Prototyper le produit",
    "expertise_design_prototype_text": "Créez un modèle expérimental simple de votre produit proposé afin de pouvoir vérifier dans quelle mesure il correspond aux attentes des utilisateurs grâce aux commentaires qu'ils donnent.",
    "expertise_design_design_solution": "Concevoir la meilleure solution",
    "expertise_design_design_solution_text": "Concevoir la meilleure solution possible pour un problème donné ne consiste pas à rechercher la perfection dans notre travail mais à créer en petites étapes itératives qui permettront de découvrir plus d'informations pour améliorer la conception au fil du temps.",
    "expertise_method_text": "En tant que client de OVA consulting, vous beneficiez à ce titre d'un statut particulier au sein de notre organisation.",
    "expertise_software_method_farming": "Identification du besoin",
    "expertise_software_method_farming_text": "ENsemble, prenons un moment pour echanger sur les sprécificités, le contexte, et les besoins de votre projet. Nous parlerons technique, biensur, mais aussi des enjeux qui vous importent. ",
    "expertise_software_method_planing": "Planification et budgétisation",
    "expertise_software_method_planing_text": "Dans un second temps, nous planifierons les différentes étapes à réaliser, ce qui offrira une plus grande visibilité",
    "expertise_software_lot_realisation": "Réalisation par lot",
    "expertise_software_lot_realisation_text": "Suivant la taille de votre projet, il est possible de le découper en un nombre de lots distincs, Ceci vous permettra de suivre et d'évaluer le travail à faire.",
    "expertise_software_method_countinous_delivery": "Livraison continue",
    "expertise_software_method_countinous_delivery_text": "Nous éffectuons des livraisons en continu sur un environnement de préproduction hébergé par nos serveurs et sécurisé. Si vous avez déjà cet environnement, nous ferons le déploiement.",
    "expertise_software_preprod_delivery": "Livraison en préproduction",
    "expertise_software_preprod_delivery_text": "Une fois les développements initiaux terminés, nous livrons votre projet et vous accompagnons dans la gestion back office de celui-ci dans les cas nécessaires.",
    "expertise_software_modifications": "Retour et ajustements",
    "expertise_software_modifications_text": "Nous traitons l'ensemble de vos retours les déployons dans l'environnement de préproduction afin que vous puissiez valider la bonne complétion du projet et de la prestation de services.",
    "expertise_software_final_delivery": "Livraison finale",
    "expertise_software_final_delivery_text": "Apres validation des retours, nous déploierons votre application dans l'environnement de production de votre choix. Nous pouvons également mettre en place votre serveur d'hébergement.",
    "expertise_software_support_maintainance": "Support et maintenance",
    "expertise_software_support_maintainance_text": "Lors de la mise en production, OVA consulting assure un support technique en cas de problématiques liées à la prestation",
    // cisco ccna
    "training_desc_12": `Cette formation vous prépare à la certification Cisco 200-301 qui est la référence dans le
    domaine des réseaux informatiques.
    Cette formation vous permettra d’acquérir les connaissances et les compétences nécessaires pour
    installer, configurer et exploiter un réseau de petite et moyenne taille.`,
    "training_duration_12": "15 jours",
    "training_cisco_ccna_level": `Bonnes connaissances en informatique
    Compétences en matière d'utilisation Internet
    Bonne connaissance de l'adressage IP et des protocoles réseaux`,
    "training_cisco_ccna_objectives": `Maîtriser l'installation, la configuration et la maintenance d'un réseau de petite et moyenne taille
    Maîtriser les connaissances théoriques fondamentales des réseaux et de la mise en place de
    réseaux locaux simples
    Comprendre les fondamentaux de la sécurité, de la mise en réseau et de l'automatisation
    Savoir sécuriser un réseau informatique et de ces périphériques
    Préparer la certification CCNA 200-301`,
    "training_cisco_ccna_concerned_public": `Le cours CCNP est idéal pour les professionnels du réseau tels que les ingénieurs réseau, le personnel de support technique du centre d'exploitation réseau (NOC) et les techniciens du service d'assistance. De plus, ce cours convient aux personnes impliquées dans l'exécution et la justification des protocoles de routage dans les réseaux d'entreprise.`,
    "training_cisco_ccna_title_1": "Module 1: Approche fondamentale du réseau",
    "training_cisco_ccna_program_content_title_1": "Approche fondamentale du réseau",
    "training_cisco_ccna_program_content_text_1": `
    <ul>
    <li>Exploration des fonctions réseaux</li>
    <li>Modèle de communication d'hôte à hôte</li>
    <li>Fonctionnalités du logiciel Cisco IOS</li>
    <li>Présentation des réseaux locaux LAN</li>
    <li>Explication de la couche de liaison TCP/IP</li>
    <li>Fonctionnement d'un commutateur</li>
    <li>Explication de la couche Internet TCP/IP, de l'adressage IPv4 et des sous-réseaux</li>
    <li>La couche de transport TCP/IP et les applications</li>
    <li>Présentation des fonctions de routage</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_2": "Module 2: Les accès au réseau",
    "training_cisco_ccna_program_2_content_title_1": "Les accès au réseau",
    "training_cisco_ccna_program_2_content_text_1": `
    <ul>
    <li>Configuration d'un routeur Cisco</li>
    <li>Fonctionnalités du processus de livraison de paquets</li>
    <li>Dépannage d'un réseau</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_3": `Module 3: La connectivité IP`,
    "training_cisco_ccna_program_3_content_title_1": "La connectivité IP",
    "training_cisco_ccna_program_3_content_text_1": `
    <ul>
    <li>Explication de l'IPv6</li>
    <li>Configuration d’un routage statique</li>
    <li>Mise en place des VLANs et des trunks</li>
    <li>Explication du routage entre VLANs</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_4": "Module 4: Les services IP",
    "training_cisco_ccna_program_4_content_title_1": "Les services IP",
    "training_cisco_ccna_program_4_content_text_1": `
    <ul>
    <li>Présentation du protocole de routage dynamique d’OSPF</li>
    <li>Création d'un réseau commuté avec des liens redondants</li>
    <li>Améliorer la structure commutée redondantes avec EtherChannel</li>
    <li>Configuration de la redondance de la couche 3 avec le protocole HSRP</li>
    <li>Exploration des technologies WAN</li>
    <li>Explication des accès ACL</li>
    <li>Activation de la connectivité Internet</li>
    <li>Présentation de la qualité de service informatique (QoS)</li>
    <li>Principes de base des réseaux sans fil</li>
    <li>Présentation des architectures informatiques et la virtualisation</li>
    <li>Explication de l'évolution des réseaux intelligents</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_5": "Module 5: Les fondamentaux de la sécurité",
    "training_cisco_ccna_program_5_content_title_1": "Les fondamentaux de la sécurité",
    "training_cisco_ccna_program_5_content_text_1": `
    <ul>
    <li>Explication sur le monitoring du système</li>
    <li>Gestion des périphériques Cisco</li>
    <li>Identifier les menaces pour la sécurité du réseau</li>
    <li>Présentation des technologies de défense contre les menaces</li>
    <li>Sécurisation de l'accès administratif</li>
    </ul>
    `,
    "training_cisco_ccna_program_title_6": "Module 6: Les automatisations et la programmabilité",
    "training_cisco_ccna_program_6_content_title_1": "Les automatisations et la programmabilité",
    "training_cisco_ccna_program_6_content_text_1": `
    <ul>
    <li>Mise en place des dispositifs de renforcement</li>
    </ul>`,
    "OVACOM": "OVACOM",
    "TCHOKO": "TCHOKO",
    "OVASSURE": "OVASSURE",
    "OVAGED": "OVAGED",
    "Success stories": "Success stories",
    "view all": "view all",
    "EHS": "EHS",
    "SNCF": "SNCF",
    "LBS": "LBS",
    "footer_slogan": "La créativité et l'innovation restent notre motivation",
    // Privacy policy
    "privacy_policy_date": "Date d'effet : août 2022",
    "privacy_policy_text_general_statement_title": "1. Déclaration générale",
    "privacy_policy_text_general_statement_text": `Nous prenons votre vie privée très au sérieux et nous nous engageons à la protéger. Nous considérons que vous devez pouvoir facilement savoir quelles sont les données à caractère personnel que nous recueillons et utilisons, et comprendre vos droits en lien avec celles-ci.
    La présente politique de confidentialité (la « Politique de confidentialité ») explique nos politiques et pratiques quant à la manière dont nous recueillons, utilisons et divulguons les données à caractère personnel que nous recueillons sur nos plateformes numériques ou pendant nos événements. Nous vous recommandons de lire attentivement la présente Politique de confidentialité car elle contient des informations importantes sur vos données à caractère personnel. Vous pouvez imprimer le texte intégral de notre Politique de confidentialité en cliquant ici. N’hésitez pas à nous contacter si vous avez des questions ou des remarques sur notre
    Politique de confidentialité (voir la rubrique « Comment nous contacter » ci-dessous).
    `,
    "privacy_policy_text_who_we_are_title": "2. Qui sommes-nous ?",
    "privacy_policy_text_who_we_are_text": `
        Les termes « OVA CONSULTING SARL », « nous », « notre » et « nos » désignent OVA CONSULTING SARL en tant que responsable du traitement de vos données à caractère personnel, sauf indication contraire dans notre Politique de confidentialité.
        OVA CONSULTING SARL est une entreprise créée par OVA CORPORATE et régie par la loi camerounais mécénat, et ses modifications successives Notre siège social se situe à Bonamoussadi carrefour Masson face express union.`,
    "privacy_policy_text_which_data_title": "3. Quelles sont les données à caractère personnel que nous recueillons et comment sont-elles recueillies ?",
    "privacy_policy_text_which_data_text": `
        Les données à caractère personnel sont des informations relatives à une personne physique identifiée ou identifiable. Elles incluent par exemple le nom, l’adresse et le genre d’une personne. Nous pouvons recueillir des données à caractère personnel directement auprès de vous (par exemple lorsque vous candidatez à l’un de nos programmes) ou indirectement (par exemple à partir de vos appareils électroniques qui interagissent avec notre site Internet (voir notre « Politique des Cookies » ci-dessous).
        <h3 class="ml-2">3.1 Données que vous nous fournissez directement</h3>
        <p class="ml-4">
            Vous pouvez nous fournir des données :
            <ul>
            <li>Lorsque vous candidatez à l’un de nos programmes ;</li>
            <li>Lorsque vous vous abonnez à notre lettre d’information via notre site internet ;</li>
            <li>Lorsque vous participez à l’un de nos événements ;</li>
            <li>Lorsque vous nous contactez via notre module de contact mis en ligne sur notre site internet;</li>
            <li>Lorsque vous accédez à l’espace annonce de notre site internet;</li>
            </ul>
            Selon ce que vous nous fournissez, lesdites informations peuvent inclure :
            <ul>
            <li>Votre identité (y compris votre prénom, nom de famille, genre, image) ;</li>
            <li>Vos coordonnées (y compris votre (vos) adresse(s) postale(s), e-mail(s), numéro(s) de téléphone) ;</li>
            <li>Votre statut personnel (y compris votre titre de civilité) ;</li>
            <li>D’autres informations que vous pouvez fournir en remplissant des formulaires ou en nous contactant (y compris vos commentaires ou d’autres communications avec nous).</li>
            </ul>
            Lorsque vous proposez votre candidature à l’un de nos programmes, lesdites informations peuvent inclure :
            <ul>
            <li>Votre statut professionnel et académique (profession, titre, …) ;</li>
            <li>Votre situation financière (dans le cas de l’attribution de bourses) ;</li>
            <li>Vertaines données de paiement (tel que des coordonnées bancaires aux fins de règlement de prestations, versements de bourse ou de soutien) ;</li>
            </ul>
            Nous vous informerons lorsque vos informations sont nécessaires pour traiter votre demande, pour répondre à vos demandes ou pour vous permettre de participer à l’un de nos programmes. Si vous ne donnez pas ces informations, cela peut retarder ou rendre impossible le traitement de votre demande.
            Nous nous efforçons de veiller à ce que les données à caractère personnel que nous détenons soient exactes à tout moment. Nous vous encourageons donc à mettre à jour vos données au cas où des changements se produiraient. Nous pouvons également vous demander de mettre à jour vos données de temps à autre. Nous vous recommandons de ne fournir que les données demandées ou nécessaires à votre requête, et de ne pas nous communiquer de données sensibles relatives à la race, à l’origine ethnique, aux opinions politiques, aux croyances religieuses ou philosophiques, et des données concernant la santé, la vie sexuelle ou l’orientation sexuelle.
        </p>
        <h3 class="ml-2">3.2 Données recueillies indirectement.</h3>
        <p class="ml-4">
        Nous pouvons recueillir des données lorsque vous utilisez notre site, notamment votre adresse IP ou d’autres données de navigation (y compris le navigateur, le système d’exploitation, le modèle de l’appareil), par le biais de cookies ou de technologies similaires placés sur votre appareil. Certains cookies sont nécessaires au bon fonctionnement de notre site et d’autres sont utilisés à des fins d’analyse qui nous aident à vous fournir des services plus personnalisés et une meilleure expérience numérique. Pour plus d’informations sur les cookies et pour savoir comment modifier vos préférences, veuillez lire notre Politique en matière de cookies. Nous pouvons également recueillir des données vous concernant auprès de tiers, notamment d’un conjoint qui nous contacte en votre nom ou celui de vos amis qui nous fournissent vos coordonnées afin de vous inviter à des événements susceptibles de vous intéresser. Si vous nous fournissez des données à caractère personnel concernant quelqu’un d’autre, vous devez vous assurer que vous avez le droit de nous divulguer ces données et que, sans que nous prenions d’autres mesures imposées par la législation en matière de protection des données, nous pouvons recueillir, utiliser et divulguer lesdites données pour les finalités décrites dans notre Politique de confidentialité. Vous devez par exemple vous assurer que la personne concernée a connaissance des divers sujets exposés dans notre Politique de confidentialité. La personne doit également donner les consentements énoncés dans notre Politique de confidentialité en ce qui concerne la manière dont nous traitons ses données à caractère personnel.
        </p>
        <h3 class="ml-2">3.3 Age minimum</h3>
        <p class="ml-4">Nous vous rappelons que nous ne recueillons pas, directement ou indirectement, de données à caractère personnel de personnes âgées de moins de 16 ans, sans préjudice de toute loi locale fixant un âge minimum différent. Nous vous demandons donc de ne pas nous fournir de données à caractère personnel de personnes ne remplissant pas ce critère.</p>
    `,
    "privacy_policy_text_why_do_we_title": "4. Pourquoi recueillons-nous vos données à caractère personnel et comment les utilisons nous ?",
    "privacy_policy_text_text_why_do_we_text": `
    Nous recueillons et utilisons vos données à caractère personnel en nous fondant sur une ou plusieurs des bases juridiques suivantes :
    <ul>
    <li>Nous avons obtenu votre consentement préalable (par exemple lorsque vous vous abonnez à notre lettre d’information). Veuillez noter qu’avec cette base juridique particulière, vous avez le droit de révoquer votre consentement à tout moment (voir ci-dessous « Quels sont vos droits sur vos données à caractère personnel ? ») ;</li>
    <li>Ne traitement est nécessaire dans le cadre d’un contrat entre OVA CONSULTING SARL et vous ;</li>
    <li>Nous avons un intérêt légitime pour effectuer le traitement et cet intérêt légitime n’est pas éclipsé par vos intérêts, droits fondamentaux ou libertés ;</li>
    <li>
    ous devons traiter vos données à caractère personnel afin de respecter les lois et la règlementation en vigueur.
        Selon le contexte, nous pouvons utiliser vos données à caractère personnel pour :\
        <ul>
        <li>Traiter votre candidature à l’un de nos programmes ;</li>
        <li>Effectuer des vérifications pour vous identifier et vérifier votre identité ;</li>
        <li>Eous envoyer une lettre d’information (avec votre consentement préalable) ou des invitations à l’un de nos événements ;</li>
        <li>Répondre à vos questions, demandes et suggestions ;</li>
        <li>Gérer les événements auxquels vous vous êtes inscrit et/ou auxquels vous avez participé ;</li>
        <li>Surveiller et améliorer nos Plateformes numériques ;</li>
        <li>Effectuer des analyses statistiques ;</li>
        <li>Fournir des informations aux organismes de réglementation lorsque la loi l’exige.</li>
        </ul>
    </li>

    </ul>
    `,
    "privacy_policy_text_how_long_title": "5. Combien de temps conservons-nous vos données à caractère personnel ?",
    "privacy_policy_text_how_long_text": `Vos données à caractère personnel sont traitées pendant la période nécessaire pour les finalités pour lesquelles elles ont été recueillies, pour se conformer aux obligations légales et réglementaires et pour la durée de toute période nécessaire à l’établissement, l’exercice ou la défense de droits légaux. <br/>
    Afin de déterminer les durées de conservation les plus appropriées pour vos données à caractère personnel, nous avons spécifiquement pris en compte la quantité, la nature et la sensibilité de vos données à caractère personnel, les raisons pour lesquelles nous avons recueilli vos données à caractère personnel, ainsi que les exigences légales applicables. `,
    "privacy_policy_text_how_we_transfert_title": "6. Comment divulguons-nous et transférons-nous vos données à caractère personnel ?",
    "privacy_policy_text_how_we_transfert_text": `Nous ne pouvons divulguer vos données à caractère personnel qu’aux parties désignées ci-dessous et pour les raisons suivantes :
    <ul>
    <li>Nous divulguons vos données à caractère personnel aux employés de OVA CONSULTING SARL qui ont besoin d’avoir accès à vos données à caractère personnel et qui sont autorisés à les traiter pour les finalités susmentionnées et qui s’engagent à en respecter la confidentialité.</li>
    <li>Nous pouvons être amenés à divulguer vos données à caractère personnel aux sociétés OVA, de la communication, de l’audit interne et de la gestion informatique pour les finalités énoncées dans notre Politique de confidentialité avec votre consentement, de vous adresser des communications promotionnelles concernant des événements (à cette fin, vous pouvez révoquer votre consentement à tout moment. Consultez la rubrique « Quels sont vos droits sur vos données à caractère personnel ? » ci-dessous).</li>
    <li>Nous pouvons également être amenés à communiquer des données à caractère personnel à des tiers agissant pour le compte de OVA CONSULTING SARL.</li>
    </ul>`,
    "privacy_policy_text_how_we_protect_title": "7. Comment protégeons-nous vos données à caractère personnel ?",
    "privacy_policy_text_how_we_protect_text": `Toutes vos données à caractère personnel sont strictement confidentielles et ne seront accessibles qu’en cas de nécessité, uniquement par le personnel de d’entreprise OVA CONSULTING SARL.
    OVA CONSULTING SARL a mis en place des mesures de sécurité pour protéger vos données à caractère personnel contre tout accès et utilisation non autorisés. Nous suivons des procédures de sécurité appropriées dans la conservation et la divulgation de vos données à caractère personnel afin d’empêcher l’accès non autorisé par des tiers et d’éviter la perte accidentelle de vos données. Nous limitons l’accès à vos données à caractère personnel aux personnes qui ont réellement besoin d’y accéder pour des raisons professionnelles. Les personnes qui accèdent à vos données sont soumises à un devoir de confidentialité à l’égard de OVA CONSULTING SARL.
    Nous avons également mis en place des procédures pour traiter toute atteinte présumée à la sécurité des données. Nous vous aviserons, ainsi que toute autorité de contrôle compétente, en cas d’atteinte présumée à la sécurité des données lorsque la loi nous impose de le faire. Nous exigeons également des personnes à qui nous transmettons vos données à caractère personnel qu’elles respectent ce qui précède. Malheureusement, la transmission d’informations via Internet n’est pas totalement sécurisée. Nous ne pouvons donc pas garantir la sécurité de vos données à caractère personnel que vous nous avez transmises via Internet. Une telle transmission se fait à vos propres risques et vous reconnaissez et acceptez que nous déclinons toute responsabilité en cas d’utilisation, de distribution, de dommage ou de destruction non autorisés de vos données, sauf dans la mesure où la loi nous oblige à endosser une telle responsabilité. Une fois que nous aurons reçu vos données à caractère personnel, nous appliquerons les mesures de sécurité susmentionnées.`,
    "privacy_policy_text_your_right_title": `8. Quels sont vos droits sur vos données à caractère personnel ?`,
    "privacy_policy_text_your_right_text": `Conformément au droit applicable à la protection des données, vous pouvez à tout moment demander l’accès, la rectification, l’effacement et la portabilité de vos données à caractère personnel, ou en restreindre le traitement ou vous y opposer. Un résumé de ces droits est énoncé ci-dessous : Votre droit d’accès : le droit de recevoir une copie de vos données à caractère personnel. Votre droit de rectification : le droit de nous demander de rectifier toute erreur dans vos données ou de les compléter.
    Votre droit à l’oubli : le droit de nous demander de supprimer vos données à caractère personnel, dans certaines situations.
    Votre droit à la limitation du traitement : le droit de nous demander de limiter le traitement de vos données à caractère personnel, dans certaines circonstances, par exemple si vous contestez l’exactitude des données. Votre droit à la portabilité des données : le droit de recevoir les données à caractère personnel que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par machine et/ou de transmettre ces données à un tiers, dans certaines situations.
    Votre droit d’opposition au traitement : le droit de s’opposer :
    <ul>
    <li>à tout moment pendant le traitement de vos données à caractère personnel ;</li>
    <li>Dans certaines autres situations, à la poursuite de notre traitement de vos données à caractère personnel, par exemple le traitement effectué sur la base de nos intérêts légitimes.</li>
    </ul>
    Lorsque le traitement de vos données personnelles est basé sur votre consentement, vous pouvez à tout moment décider de retirer votre consentement au traitement de vos données à caractère personnel notamment lorsque vous ne souhaitez plus recevoir la lettre d’information à laquelle vous vous êtes inscrit. Si votre consentement est retiré, cela ne nous empêche pas de traiter vos données à caractère personnel en nous fondant sur d’autres bases juridiques, le cas échéant, l’intérêt légitime de OVA CONSULTING SARL.
    Si vous ne souhaitez plus recevoir nos informations par mail, nous vous rappelons que vous pouvez révoquer votre consentement à tout moment directement à partir du lien de désabonnement inclus dans chaque message électronique que nous vous envoyons. Si vous le faites, nous mettrons rapidement à jour nos bases de données et prendrons toutes les mesures raisonnables pour répondre à votre demande le plus rapidement possible. Vous avez également le droit de déposer une plainte auprès de votre autorité locale de protection des données en cas de violation présumée des règles de protection des données qui vous sont applicables.
    Pour exercer l’un de ces droits, veuillez nous contacter en utilisant les coordonnées ci-dessous (voir « Comment nous contacter »).
    Veuillez noter que si vous exercez l’un des droits mentionnés ci-dessus, il vous sera demandé de nous faire savoir quel droit vous souhaitez exercer et de nous fournir certaines informations (copie d’une carte d’identité, passeport ou autre pièce d’identité légalement reconnue) à des fins d’identification pour traiter votre demande et vous protéger contre les demandes frauduleuses émanant de tiers.`,
    "privacy_policy_text_how_to_contact_us_title": `9. Comment nous contacter ?`,
    "privacy_policy_text_how_to_contact_us_text": `Pour toute question relative à vos données, pour révoquer votre consentement, pour poser des questions d’ordre général ou pour porter réclamation, veuillez communiquer avec nos équipes :
    <ul>
    <li>Par courrier : BP 3579 </li>
    <li>Sur le site internet de OVA CONSULTING SARL : via le formulaire de contact prévu à cet effet
    (Https://ova-consulting.fr/)</li>
    </ul>
    Pour les questions ayant précisément trait aux e-mails, nous vous rappelons que vous pouvez à tout moment vous désinscrire directement par le lien de « désabonnement » contenu dans tous les e-mails que nous vous envoyons. Si vous avez des questions ou des préoccupations concernant notre Politique de confidentialité ou le traitement des données, vous pouvez contacter le délégué à la protection des données du groupe à l’adresse suivante : privacy@ova-consulting.fr.`,
    "privacy_policy_text_update_title": `10. Modifications de notre Politique de confidentialité`,
    "privacy_policy_text_update_text": `Notre politique de confidentialité reflète nos pratiques actuelles et peut être modifiée et mise à jour de temps à autre. Lorsque nous publions des changements à notre Politique de confidentialité, nous modifions la « 31 aout » précisée en haut de ce document pour indiquer quand de tels changements sont entrés en vigueur. Si nous modifions notre Politique de confidentialité d’une manière substantielle, nous vous en informerons par le biais d’un avis de modification au début de celle-ci et sur la page d’accueil du site Web « https://ova-consulting.fr/ ».`,
    "expertise_software_user_training": "Formation des utilisateurs",
    "expertise_software_user_training_text": "Après la livraison finale de votre produit, nous nous chargeons de la formation de tous les utilisateurs, afin de permettre une utilisation fluide et autonome."
    }
}

export default fr