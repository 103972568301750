import { render } from "react-dom";
import {
  Routes,
  Route,
} from "react-router-dom";
import AboutUs from "../../pages/about/AboutUs";
import CartPage from "../../pages/cart/CartPage";
import Checkout from "../../pages/checkout/Checkout";
import Contact from "../../pages/contact/Contact";
import Home from "../../pages/home/Home";
import Homepage from "../../pages/homepage";
import Hosting from "../../pages/hosting/Hosting";
import StoreArticleDetails from "../../pages/licenses/components/details/StoreArticleDetails";
import LicensesEquipements from "../../pages/licenses/LicensesEquipements";
// import your route components too



function AppRoutes () {


    return (
        <Routes>
            <Route path="/" element={<Home />}>
                <Route index element={<Home />} />
            </Route>
            <Route path="/ova-store/hosting" element={<Hosting />} />
            <Route path="/homepage" element={<Homepage />} />
            <Route path="/ova-store/contact" element={<Contact />} />
            <Route path="/ova-store/about" element={<AboutUs />} />
            <Route path="/ova-store/cart" element={<CartPage />} />
            <Route path="/ova-store/store/:store" element={<LicensesEquipements />} />
            <Route path="/ova-store/store/:store/checkout" element={<Checkout />} />
            <Route path="/ova-store/store/:store/cart" element={<CartPage />} />
            <Route path="/ova-store/store/:store/:articleId" element={<StoreArticleDetails />} />
        </Routes>
    )

} 

export default AppRoutes;