

export function redirect(url: string, navigate: Function, redirect?: boolean) {

    let a = document.createElement('a');
    a.setAttribute('href', url);
    if(redirect){
        a.setAttribute('target', '_blank');
        a.click();
    } else {
        navigate(url);
    }
    
}