import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function Faqs () {


    return(
        <>
        {/* Frequently asked questions */}
        <section className="hosting-faq">
                    <div className="container">
                        <div className="page-title">
                            <h3 className="title">Frequently asked questions</h3>
                        </div>
                        <div>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Accordion 1</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                <Typography>Accordion 2</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </section>
                {/* 30 Days Money-Back Guarantee */}
                <section className="hosting-money-back">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className='block-image'>
                                    <img src="/assets/img/hosting/guaranty.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 d-flex">
                                <div className='block-text'>
                                    <h3>30 Days Money-Back Guarantee</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum fugit illum id vero dicta, tempore necessitatibus velit recusandae fuga neque asperiores corrupti quam consequatur natus rerum molestiae quod consectetur quo!</p>
                                    <br />
                                    <br />
                                    <br />
                                    <button className="btn btn-primary">BUY NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
}