import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import SuccessRate from "../homepage/components/successRate/SuccessRate";

// import "./styles.css";


export default function AboutUs () {


    return(
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header text-left">
                <div className="page-header-bg" style={{backgroundImage: "url(/assets/images/backgrounds/page-header-bg.jpg)"}}>
                </div>
                <div className="page-header-shape-1"><img src="/assets/images/shapes/page-header-shape-1.png" alt=""/></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><a href="index.html">Home</a></li>
                            <li><span>/</span></li>
                            <li>About</li>
                        </ul>
                        <h2>About us</h2>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}
            <section className="about-four">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-four__left">
                                <div className="about-four__img-box wow slideInLeft" data-wow-delay="100ms"
                                    data-wow-duration="2500ms">
                                    <div className="about-four__img">
                                        <img src="/assets/images/resources/about-four-img-1.jpg" alt=""/>
                                    </div>
                                    <div className="about-four__img-two">
                                        <img src="/assets/images/resources/about-four-img-2.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-four__right">
                                <div className="section-title text-left">
                                    <div className="section-sub-title-box">
                                        <p className="section-sub-title">About company</p>
                                        <div className="section-title-shape-1">
                                            <img src="/assets/images/shapes/section-title-shape-1.png" alt=""/>
                                        </div>
                                        <div className="section-title-shape-2">
                                            <img src="/assets/images/shapes/section-title-shape-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <h2 className="section-title__title">Creating a better future for your loved once</h2>
                                </div>
                                <p className="about-four__text-1">Nullam eu nibh vitae est tempor molest sed ex.</p>
                                <p className="about-four__text-2">Quisque dignissim maximus ipsum, sed rutrum tincidunt et. Sed
                                    eget tincidunt ipsum. There are many passages of psum available, but the majority have
                                    alteration in some by injected humour, or randomised words look believable.</p>
                                <div className="about-four__founder">
                                    <div className="about-four__founder-img">
                                        <img src="/assets/images/resources/about-four-founder-img.jpg" alt=""/>
                                    </div>
                                    <div className="about-four__founder-content">
                                        <h3 className="about-four__founder-name">David Hardson</h3>
                                        <p className="about-four__founder-sub-title">Founder of insur</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="brand-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="brand-one__title">
                                <h2>Trusted and funded by more then 800 companies</h2>
                            </div>
                        </div>
                        <div className="col-xl-9">
                            <div className="brand-one__main-content">
                                {/* <div c */}
                                <Swiper 
                                    className="thm-swiper__slider"
                                    spaceBetween= {100}
                                    slidesPerView={5} 
                                    autoplay={
                                        { "delay": 5000 }
                                    }
                                    breakpoints = {
                                        {
                                        0: {
                                            "spaceBetween": 30,
                                            "slidesPerView": 2
                                        },
                                        375: {
                                            "spaceBetween": 30,
                                            "slidesPerView": 2
                                        },
                                        575: {
                                            "spaceBetween": 30,
                                            "slidesPerView": 3
                                        },
                                        767: {
                                            "spaceBetween": 50,
                                            "slidesPerView": 4
                                        },
                                        991: {
                                            "spaceBetween": 50,
                                            "slidesPerView": 5
                                        },
                                        1199: {
                                            "spaceBetween": 100,
                                            "slidesPerView": 5
                                        }
                                    }}
                                    >
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-1.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-2.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-3.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-4.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-5.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/assets/images/brand/brand-1-1.png" alt=""/>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Success rate */}
            <SuccessRate />
        </>
    )
}